import { Injectable } from '@angular/core';
import { FleetCompCommunicationModel, FleetCompRequestModel } from '../model/fleet-comparison-communication.model';
import { Router } from '@angular/router';
import { QcLJGraphMetaData } from '../model/QcLJGraphMetaData.model';
import { MiscLJChartDataModel } from '../model/misc-lj-chart-data.model';
import { NetworkService } from './communication/network.service';
import { SseService } from './communication/sse.service';
import { DataType } from '../enums/data-type.enum';
import { AppConfigService } from './app-config.service';
import { filter, firstValueFrom, timeout } from 'rxjs';
import { Constants } from '../constants/constants';
import { QCDateFilter } from '../model/qc-dashboard.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FleetComparisonService {

  fleetComparisonMetaData: FleetCompRequestModel | undefined;
  ljGraphMetaData: QcLJGraphMetaData | undefined;
  miscData: MiscLJChartDataModel | undefined;
  defaultFleetCompData: FleetCompCommunicationModel = {
    dataType: 4,
    data: {
      dataType: 4,
      requestId: '1',
      qcFleetDetails: {
        fleetMean: 0,
        sdOfModuleMeans: 0,
        sdWithinModuleMean: 0,
        instrumentCount: 0,
        fleetMedian: 0,
        qcResultCount: 0,
        fleetDataUnit: {
          type: 0,
          name: ''
        },
        requestedUnit: {
          type: 0,
          name: ''
        },
        sd2Low: 0,
        sd2Up: 0
      }
    }
  }

  constructor(private router: Router, private networkService: NetworkService,
    private sseService: SseService, private appConfigService: AppConfigService) { }

  navigateToFleetComp(FleetCompRequestModel: FleetCompRequestModel, ljGraphMetaData: QcLJGraphMetaData, miscData: MiscLJChartDataModel) {
    if (!FleetCompRequestModel || !ljGraphMetaData || !miscData) {
      console.log('Incomplete fleet data');
      return;
    }
    this.fleetComparisonMetaData = FleetCompRequestModel;
    this.ljGraphMetaData = ljGraphMetaData;
    this.miscData = miscData;
    this.router.navigate(['troubleshooting', 'QC', 'fleet-comparison'], {
      queryParamsHandling: 'merge'
    })
  }


  async getFleetCompData(fleetCompReqModel: FleetCompRequestModel): Promise<any> {
    if (fleetCompReqModel) {
      let requestData =
      {
        "DataType": 4,
        "ClientId": this.appConfigService.appConfig.clientId,
        "Data": fleetCompReqModel
      }
      let response: any = await this.networkService.post(requestData);
      if (response?.requestId) {
        let requestId = response.requestId;
        let data = await firstValueFrom(this.sseService.sseEvent$.pipe(
          filter(data => {
            let bool = data != undefined && data.dataType == DataType.QCFleetData && data.data.requestId == requestId;
            return bool;
          }),
          timeout(Constants.API_TIMEOUT)  // Unsubscribe if no value is emitted within the timeout
        ));
        if (data) return data;
      }
    }
    console.log('fleet request data is empty');
    return this.defaultFleetCompData;
  }

  getDefaultDateRange(): QCDateFilter {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const DAYS_IN_MONTH = 180;

    const today = new Date();
    const latestDate = new Date(today.setUTCDate(today.getUTCDate() + 1));
    const earliestDate = new Date(latestDate.getTime() - (DAYS_IN_MONTH * MS_PER_DAY));

    const formatDateString = (date: Date) => date.toISOString().split('T')[0];

    const latestDateString = formatDateString(latestDate);
    const earliestDateString = formatDateString(earliestDate);
    const defaultDateRangeString = formatDate(earliestDate,'MM/dd/yyyy', 'en-US') + '-'+ formatDate(latestDate,'MM/dd/yyyy', 'en-US');

    return {
      latestDateString,
      earliestDateString,
      defaultDateRangeString
    };
  }
}
