import { Injectable } from '@angular/core';
import { TroubleshootingInfoModule } from '../model/access-bar.model';
import { Subject } from 'rxjs';
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class AccessBarService {

  private troubleshootingInfo: Subject<TroubleshootingInfoModule> = new Subject<TroubleshootingInfoModule>();
  private troubleshootingInfo$ = this.troubleshootingInfo.asObservable();

  constructor(private notificationService: NotificationService) {
  }
  /**
   *  Set the tab details to display in the access bar
   *
   * @param {troubleshootingInfoModule} tabInfo
   * @memberof AccessBarService
   */
  setTabDetails(tabInfo: TroubleshootingInfoModule) {
    this.troubleshootingInfo.next(tabInfo);
  }

  /**
   * Get the tab details to display in the access bar
   *
   * @return {*}
   * @memberof AccessBarService
   */
  getTabDetails() {
    return this.troubleshootingInfo$;
  }

  /**
   *  Set the tab details to display in the access bar
   *  By Using route query parameter
   *
   * @param queryParams
   * @memberof AccessBarService
   */

  async setAccessBarNotification(queryParams:{[key: string]: string }){
    const routeParameter = JSON.parse(decodeURIComponent(queryParams['notificationNo']));
    const tabInfo: TroubleshootingInfoModule = {
      moduleType: queryParams['moduleType'],
      deviceId: queryParams['serialNo'],
      troubleshootingType: parseInt(queryParams['troubleshootingType']),
      notificationNo: routeParameter.notificationNo,
      assay: await this.notificationService.getAssayNameDetails(routeParameter.assayName),
    }
    this.setTabDetails(tabInfo)
  }
}
