import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { LoginService } from './services/login.service';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subscription } from 'rxjs';
import { EnvProviderService } from './services/env-provider.service';
import { NetworkService } from './services/communication/network.service';
import { ErrorNotificationService } from './modules/error-notification/service/error-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'assay-troubleshooting-assistant-app';
  msalBroadcastSubscription: Subscription = new Subscription();

  constructor(
    private msalBroadCastService: MsalBroadcastService,
    private msalService: MsalService,
    private loginService: LoginService,
    private environmentProvider: EnvProviderService,public errorModal : ErrorNotificationService,
  ) {
    if(this.environmentProvider.getEnvironment()?.local)
    {
      this.loginService.name = 'Customer Service Engineer';
    }
  }

  ngOnInit(): void {
    // When a new tab\browser is opened with an active session, user details has to be persisted.
    // Below code is to get the active user and set the user detail.
    let account = this.msalService.instance.getActiveAccount();
    if (account) {
      this.loginService.name = account.name;
      this.loginService.email = account.username;
    }
    else {
      // Below code is to subscribe to the login event and set the user detail.
      this.msalBroadcastSubscription = this.msalBroadCastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS ||
                                        msg.eventType === EventType.SSO_SILENT_SUCCESS ||
                                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          // Casting payload as AuthenticationResult to access account
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
          this.loginService.name = payload.account.name;
          this.loginService.email = payload.account.username;
          NetworkService.accessToken = payload.accessToken;
        });
    }
  }

  ngOnDestroy(): void {
    this.msalBroadcastSubscription?.unsubscribe();
  }
}
