<ng-container style="display: inline-table;">
    <sh-grid spacing="s" fit-content="true">
        <sh-card columns="6">
            <sh-image-segment>
                <img src="../../../assets/images/login/Home_img.svg" class="image-container" alt="Siemens-Healthineers_Login" >
            </sh-image-segment>
            <sh-image-segment>
                <img src="../../../assets/icons/SiemensHealthineers_Logo_white.svg" class="overlay-image" alt="Siemens-Healthineers_Logo">
            </sh-image-segment>
            <div class="overlay-textContent">
                <sh-text size='super-header' class="overlay-text">Welcome to</sh-text>
                <sh-text size='super-header' class="overlay-text">Assay Troubleshooting</sh-text>
                <sh-text size='super-header' class="overlay-text">Assistant</sh-text>
            </div>
        </sh-card>

        <sh-card columns="6">
            <div class="loginControls">
                <sh-text size='super-header' class="primaryColor">Welcome to Assay Troubleshooting Assistant</sh-text>
                <sh-list-item class="clickableListItem" vertical-align="top" clickable (click)="login()" label="Login with HealthineersID"> 
                    <sh-icon slot="right" icon="arrow-right-s"></sh-icon> 
                </sh-list-item> 
            </div>
        </sh-card>
    </sh-grid>
</ng-container>