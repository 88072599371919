import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/model/questionnaire.model';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent {
  @Input() data!: Question;
  @Input() mandatory: boolean = false;
  @Input() multiLine: boolean = false;
  @Input() label: string = "";
  @Input() inputType: string = "text"
  @Output() selectionChange = new EventEmitter<string>();
  @Input() value: string = '';
  @Input() disabled: boolean = false

  onSelect(event: any) {
    if(this.value === event.target.value) return;
    this.value = event.target.value;
    this.selectionChange.emit(event.target.value);
  }
}
