import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderActionButtonModel } from 'src/app/model/header-action-button.model';
@Component({
  selector: 'header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss']
})
export class HeaderActionsComponent {
  @Input() title: string | undefined;
  @Output() actionHandler: EventEmitter<string> = new EventEmitter<string>();
  @Input() actionButtons: HeaderActionButtonModel[] | undefined;
  @Input() assayName: string | undefined;
  @Input() deviceId: string | undefined;
  handleAction(actionMessage: string) {
    this.actionHandler.emit(actionMessage);
  }
}
