import { Injectable, WritableSignal } from '@angular/core';
import { DataType } from '../enums/data-type.enum';
import { NotificationRequestModel, NotificationResponseModel } from '../model/notification.model';
import { NetworkService } from './communication/network.service';
import { DeviceListService } from './device-list.service';
import { ModuleType } from '../model/module-list.model';
import { DeviceAssaysRequestModel, TestInfo } from '../model/test-info.model';
import { NotificationStatus, TroubleshootingType } from '../enums/troubleshooting-type.enum';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DateTimeFormatterService } from './date-time-formatter.service';
import { QuestionnaireStatus } from '../model/questionnaireStatus.model';
import { TRANSFER_ARMS_MAPPER, TransferArms } from '../modules/transfer-arms/models/transfer-arms.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  selectedModuleType: WritableSignal<ModuleType | null> = this.deviceListService.selectedModuleType;
  selectedNotificationNo!: string;
  notifications: BehaviorSubject<NotificationResponseModel[]> = new BehaviorSubject<NotificationResponseModel[]>([]); //remove this line , create a ngrx store
  notifications$ = this.notifications.asObservable();

  displayNotifications: BehaviorSubject<NotificationResponseModel[]> = new BehaviorSubject<NotificationResponseModel[]>([]); //remove this line , create a ngrx store
  displayNotifications$ = this.displayNotifications.asObservable();

  loading: boolean = true;
  loading$ = new BehaviorSubject<boolean>(true);

  showAssayChangeConfirmationModal: boolean = false;
  showAssayChangeConfirmationModal$ = new BehaviorSubject<boolean>(false);

  assayList: TestInfo[] = [];

  constructor(private networkService: NetworkService, private deviceListService: DeviceListService, private router: Router,
    private dateTimeFormatterService: DateTimeFormatterService,) { }

  /**
   * @description Get all notifications data for the given serial number
   *
   * @param {string} serialNo
   * @return {*}
   * @memberof NotificationService
   */
  async getNotificationData(serialNo: string): Promise<NotificationResponseModel[]> {
    try {
      const requestData = {
        "dataType": DataType.NotificationData,
        "data": {
          "troubleshootingType": this.deviceListService.selectedTroubleshootingType,
          "serialNo": serialNo
        }
      };

      const response: any = await this.networkService.postWithoutSSE<ResponseType>(requestData); // Assume ResponseType is the correct type
      const notifications = response.data.notifications.map((item: NotificationResponseModel) => ({
        ...item,
        createdUTCDateTime: this.dateTimeFormatterService.formatDate(new Date(item.createdUTCDateTime))
      }));

      const sortedNotifications = notifications.sort((a: any, b: any) => new Date(b.createdUTCDateTime).getTime() - new Date(a.createdUTCDateTime).getTime());
      this.notifications.next(sortedNotifications);
      this.setDisplayNotifications(sortedNotifications);
      return sortedNotifications;
    } catch (error) {
      console.error("Failed to get notification data:", error);
      throw error; // Rethrow or handle as needed
    }
  }

  /**
   * @description Create a new notification
   *
   * @param {NotificationRequestModel} notification
   * @return {*}  {Promise<any>}
   * @memberof NotificationService
   */
  async createNotification(notification: NotificationRequestModel): Promise<any> {
    const valid = this.isNotificationValid(notification);
    this.selectedNotificationNo = '';
    if (valid) {
      let requestData = {
        "dataType": DataType.NotificationData,
        "data": {
          "troubleshootingType": notification.troubleshootingType,
          "notificationNo": notification.notificationNo,
          "serialNo": notification.serialNo,
          "assay": notification.assayName,
          "status": notification.notificationStatus,
          "comment": notification.comment
        }
      };
      let response: any = await this.networkService.send(requestData);
      await this.getNotificationData(notification.serialNo);
      this.selectedNotificationNo = notification.notificationNo;
      return response;
    }
    else {
      console.error('Notification attempted to update/create is not valid');
    }
  }

  updateNotificationForTA(serialNo: string, notificationNo: string, status: NotificationStatus) {
    const requestData = {
      "dataType": DataType.NotificationData,
      "data": {
        "troubleshootingType": this.deviceListService.selectedTroubleshootingType,
        "notificationNo": notificationNo,
        "serialNo": serialNo,
        "status": status,
      }
    };
    return this.networkService.postDataWithoutSSE(requestData, true);
  }

  isNotificationValid(notification: NotificationRequestModel): boolean {
    const { notificationNo, notificationStatus, assayName, troubleshootingType } = notification;
    return notificationNo !== '' && notificationStatus !== 0 && (troubleshootingType !== TroubleshootingType.AssayTroubleshooting || assayName !== '');
  }

  /**
   * @description Get the assays data for the selected device
   *
   * @return {*}
   * @memberof NotificationService
   */
  getDeviceAssays() {
    const module = this.selectedModuleType()?.moduleType;
    if (!module) return undefined;

    const toDate = new Date();
    const fromDate = new Date(toDate.getTime() - (30 * 24 * 60 * 60 * 1000));
    const toDateString = toDate.toISOString().split('T')[0];
    const fromDateString = fromDate.toISOString().split('T')[0];

    const requestData: DeviceAssaysRequestModel = {
      moduleType: module,
      serialNo: this.deviceListService.selectedDeviceId,
      fromDate: fromDateString,
      toDate: toDateString
    };
    return this.deviceListService.getDeviceAssays(requestData);
  }

  getNavigationPath(): string | null {
    const navigationMap: { [key: number]: string } = {
      [TroubleshootingType.AssayTroubleshooting]: 'troubleshooting',
      [TroubleshootingType.TransferArm]: `transfer-arms/dashboard/${TRANSFER_ARMS_MAPPER[TransferArms.REAGENT_ARM_1].routeId}`,
      [3]: `transfer-arms/steps-instruction`,
    };
    if (this.shouldNavigateToTAFileUpload()) {
      return navigationMap[3];
    }
    return navigationMap[this.deviceListService.selectedTroubleshootingType] || null;
  }

  shouldNavigateToTAFileUpload() {
    const selectedNotification = this.findNotification(this.selectedNotificationNo);
    return selectedNotification?.notificationStatus && selectedNotification.notificationStatus === NotificationStatus.New &&
      this.deviceListService.selectedTroubleshootingType === TroubleshootingType.TransferArm;
  }

  async navigateTo(path: string) {
    this.router.navigate([path], {
      queryParams: this.getNavigationQueryParams(),
      queryParamsHandling: 'merge'
    });
  }

  getNavigationQueryParams() {
    const selectedNotificationData = JSON.stringify(this.findNotification(this.selectedNotificationNo));
    return {
      moduleType: this.selectedModuleType()?.moduleType,
      notificationNo: encodeURIComponent(selectedNotificationData),
      serialNo: this.deviceListService.selectedDeviceId,
      systemEvent: false,
    };
  }

  /**
   * @description Get the selected assay
   *
   * @return {*}
   * @memberof NotificationService
   */
  async getSelectedAssay() {
    const response = await this.getDeviceAssays();
    const assays = response?.data?.assays ?? [];
    const notification = this.findNotification(this.selectedNotificationNo);
    return assays.find(x => x.longName === notification?.assayName);
  }

  async getAssayNameDetails(assay: string) {
    const response = await this.getDeviceAssays();
    const assays = response?.data?.assays ?? [];
    return assays.find(x => x.longName === assay || x.name === assay);
  }


  /**
   * @description Set the notifications data in store
   *
   * @param {NotificationResponseModel[]} data
   * @memberof NotificationService
   */
  setDisplayNotifications(data: NotificationResponseModel[]) {
    this.displayNotifications.next(data);
  }

  /**
 * @description Get the notification data
 * for given notification number
 *
 * @param {string} notificationNo
 * @return {*}
 * @memberof NotificationService
 */
  findNotification(notificationNo: string): NotificationResponseModel | undefined {
    const notification = this.getNotification(notificationNo);
    if (notification) this.selectedNotificationNo = notification.notificationNo;
    const notifications = this.notifications.value.filter(x => x.notificationNo.toLowerCase().includes(notificationNo.toLowerCase()));
    this.setDisplayNotifications(notifications);
    return notification;
  }

  /**
 * @description Get the current notification data
 * without setting DisplayNotifications
 * @param {string} notificationNo
 * @return {*}  {(NotificationResponseModel | undefined)}
 * @memberof NotificationService
 */
  findSelectedNotification(notificationNo: string): NotificationResponseModel | undefined {
    return this.getNotification(notificationNo);
  }


  /**
 * @description Helper function to get notification by number
 * @param {string} notificationNo
 * @return {*}  {(NotificationResponseModel | undefined)}
 * @memberof NotificationService
 */
  private getNotification(notificationNo: string): NotificationResponseModel | undefined {
    if (!this.notifications) return undefined;

    const lowerCaseNotificationNo = notificationNo.toLowerCase();
    const notification = this.notifications.value.find(x => x.notificationNo.toLowerCase() === lowerCaseNotificationNo);

    if (notification && notificationNo != '') this.selectedNotificationNo = notification.notificationNo;
    return notification;
  }

  validateNotification(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete']; // Allow special keys
    // Check if the pressed key is a valid character
    if (!/[a-zA-Z0-9-]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onNotificationRowSelection(value: string) {
    this.selectedNotificationNo = value;
  }

  showSpinner() {
    this.loading$.next(true);
  }

  hideSpinner() {
    this.loading$.next(false);
  }

  getSelectedNotification() {
    return this.findSelectedNotification(this.selectedNotificationNo);
  }

  showAssayChangeConfirmationDialog(flag: boolean) {
    this.showAssayChangeConfirmationModal$.next(flag);
  }

  navigateToNotificationManager() {
    this.router.navigate(['/notification-manager'], {
      queryParams: {
        troubleshootingType: this.deviceListService.selectedTroubleshootingType,
        serialNo: this.deviceListService.selectedDeviceId
      }
    });
  }

  async getTroubleshootingModuleStatus(notification: NotificationResponseModel): Promise<QuestionnaireStatus[]> {
    try {
      const requestData = {
        "dataType": DataType.QuestionnaireStatus,
        "data": {
          "ModuleType": "Atellica CH 930 Analyzer",
          "notificationNo": notification.notificationNo,
          "serialNo": notification.serialNo,
        }
      };
      const response: any = await this.networkService.postWithoutSSE<ResponseType>(requestData); // Assume ResponseType is the correct type
      return response.data.questionnaireStatuses;
    } catch (error) {
      console.error("Failed to get notification data:", error);
      throw error; // Rethrow or handle as needed
    }
  }


}

