<sh-card>
    <header-actions id="QCComparison_HeaderAction" title="QC Comparison" ></header-actions>
    <ng-container *ngIf="this.graphData && this.annotationConfig && this.miscData && !this.reload">
        <div class="graph-title-text">
            <sh-text size="body-1" id="QCComp_Graph_AnalyzerText" color="secondary">&nbsp;&nbsp;Assay Name
                -&nbsp;</sh-text>
            <sh-text size="body-1" id="QCComp_Graph_AssayText">{{this.graphData.assay}},</sh-text>
            <sh-text size="body-1" id="QCComp_Graph_AnalyzerText" color="secondary">&nbsp;&nbsp;Analyzer
                -&nbsp;</sh-text>
            <sh-text size="body-1" id="QCComp_Graph_AnalyzerValue">{{this.graphData.deviceId}},
            </sh-text>
            <sh-text size="body-1" id="QCComp_Graph_QCLotLevelText" color="secondary">&nbsp;&nbsp;QC Lots/Level
                -&nbsp;</sh-text>
            <sh-text size="body-1" id="QCComp_Graph_QCLotLevelValue">{{this.graphData.qcLotLevel}},
            </sh-text>
        </div>
        <div class="ranges">
            <sh-toggle id="QCComp_CustomerRange_Toggle" (click)="onCustomerRangeToggle()" label="Customer defined" class="toggle"></sh-toggle>
            <sh-input-number *ngIf="this.showCustomerRanges" label="Mean" id="QCComp_CustomerRanges_MeanInput" no-clear
                [decimals]="customerRangeMean | decimalForInput"
                error-message="Mean should be between low and high" [error]="customerMeanError"
                no-arrows (value-changed)="onCustomerRangeMeanValueChanged($event)" [value]="this.customerRangeMean"></sh-input-number>
            <div *ngIf="this.showCustomerRanges" class="low-high-inputs">
                <sh-input-number label="Low" mandatory id="QCComp_CustomerRanges_LowInput" no-clear
                    [decimals]="customerRangeLow | decimalForInput"
                    no-arrows (value-changed)="onCustomerRangeLowValueChanged($event)"
                    error-message="Low should be lesser than high" [error]="customerRangeError" [value]="this.customerRangeLow"></sh-input-number>
                <sh-input-number label="High" mandatory id="QCComp_CustomerRanges_HighInput" no-clear
                    [decimals]="customerRangeHigh | decimalForInput"
                    no-arrows (value-changed)="onCustomerRangeHighValueChanged($event)"
                    error-message="High should be greater than low" [error]="customerRangeError" [value]="this.customerRangeHigh"></sh-input-number>
            </div>
        </div>
        <div class="ranges">
            <sh-toggle id="QCComp_BiorRadRange_Toggle"(click)="onBioradRangeToggle()" label="Biorad" class="toggle"></sh-toggle>
            <sh-input-number *ngIf="this.showBioRadRanges" label="Mean" id="QCComp_Biorad_MeanInput" no-clear
                [decimals]="bioradMean | decimalForInput"
                error-message="Mean should be between low and high" [error]="bioradMeanError"
                no-arrows (value-changed)="onBioradMeanValueChanged($event)" [value]="this.bioradMean"></sh-input-number>
            <div *ngIf="this.showBioRadRanges" class="low-high-inputs">
                <sh-input-number label="Low" mandatory id="QCComp_Biorad_LowInput" no-clear
                    [decimals]="bioradLow | decimalForInput"
                    no-arrows (value-changed)="onBioradLowValueChanged($event)"
                    error-message="Low should be lesser than high" [error]="bioradError" [value]="this.bioradLow"></sh-input-number>
                <sh-input-number label="High" mandatory id="QCComp_Biorad_HighInput" no-clear
                    [decimals]="bioradHigh | decimalForInput"
                    no-arrows (value-changed)="onBioradHighValueChanged($event)"
                    error-message="High should be greater than low" [error]="bioradError" [value]="this.bioradHigh"></sh-input-number>
            </div>
        </div>
        <div class="button-container">
            <sh-button id="QCComp_Ranges_ViewButton" label='View' (click)="addRangeLines()"
                [disabled]="(this.bioradError || this.customerRangeError || customerMeanError || bioradMeanError) || ((bioradLow == undefined || bioradHigh == undefined) && (customerRangeLow == undefined || customerRangeHigh == undefined))">
            </sh-button>
            <sh-button id="QCComp_Ranges_ResetButton" color='secondary' label='Reset'
                [disabled]="!( bioradLow != undefined || bioradHigh != undefined || bioradMean != undefined || customerRangeLow != undefined || customerRangeHigh != undefined || customerRangeMean != undefined)"
                (click)="reset()"></sh-button>
        </div>

        <div id="comparisonOutOfBounds" class="out-of-bounds" style="display: inline-flex; gap: 3px;"
                *ngIf="this.showRangeErrorText">
                <sh-icon icon="error" size="s"></sh-icon>
                <sh-text size="title-1" style="color:#E7001D;">{{this.rangeErrorText}}</sh-text>
            </div>



        <app-l-j-chart id="QCComp_LJChart"class="chart-container" [data]="graphData" [qcRef]="qcRef" [miscData]="miscData" #ljChart
            [annotationConfigs]="annotationConfig"></app-l-j-chart>
    </ng-container>
</sh-card>
