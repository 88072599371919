import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorNotificationService } from '../service/error-notification.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AccountService } from '../../shared/services/account.service';

@Component({
  selector: 'app-error-modal',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './error-notification.component.html'
})
export class ErrorNotificationComponent {

  message: string = '';
  showModal: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(public modalService: ErrorNotificationService, private router: Router,private accountService:AccountService) { }

  ngOnInit() {
    // Subscribe to message updates from the service
    this.subscription = this.modalService.message$.subscribe((err: any) => {
      console.log('Modal Error:', err);
      this.message = err.status+ ' ' + err.message;
      this.showModal = true; // Show modal when message is received
    });
  }

  closeModal() {
    this.showModal = false;
    this.modalService.close();
  }

  ngOnDestroy() {
    // Clean up the subscription when component is destroyed
    this.subscription.unsubscribe();
  }

  Ok() {
    this.accountService.logout();
    this.closeModal();
  }

}
