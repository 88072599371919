<div *ngIf="!multiLine" style="display: flex;">
    <sh-input-text *ngIf="inputType != 'number'" [disabled]="disabled" [label]="label" maxlength=1000 [attr.mandatory]="mandatory ? '' : null"
        [ngClass]="inputType === 'number' ? 'numeric' : 'text'" [value]="value" (value-changed)="onSelect($event)" mandatory>
    </sh-input-text>

    <sh-input-number *ngIf="inputType == 'number'" min="0" no-arrows [label]="label" [attr.mandatory]="mandatory ? '' : null"
    [ngClass]="inputType === 'number' ? 'numeric' : 'text'" [value]="value" [disabled]="disabled" (value-changed)="onSelect($event)" mandatory></sh-input-number>
</div>



<sh-input-text *ngIf="multiLine" [label]="label" [disabled]="disabled" maxlength=1000 [attr.mandatory]="mandatory ? '' : null" textarea class="multiLine" [value]="value"
    (value-changed)="onSelect($event)" mandatory></sh-input-text>
