export interface HeaderActionButtonModel {
    buttonName: string;
    disabled: boolean;
    label: string;
}
let exportButtonConfig = {
    buttonName: 'export',
    disabled: false,
    label: 'Download Pdf'
};
export const headerActionMap : Map<string, HeaderActionButtonModel> = new Map([
    [ 'export-pdf', exportButtonConfig ]

  ]);
  