import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalForInput'
})
export class DecimalForInputPipe implements PipeTransform {

  transform(value: string | number | undefined | null): number {

    if (value && value.toString().split(".").length > 1) {
      const decimalNumber = value.toString().split(".").reverse()[0];
      return Math.min(4, decimalNumber.length);
    }
    return 0;
  }

}
