import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs";
import { ModulesDataModel } from 'src/app/model/device-list.model';
import { ModuleType } from 'src/app/model/module-list.model';
import { DeviceListService } from 'src/app/services/device-list.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnChanges {

  @Input('deviceId') inputDeviceId: string = '';
  modules: ModulesDataModel | undefined;

  searchByLocation = false;
  customerList: Set<string> = new Set<string>();
  cityList: string[] = [];
  deviceIdList: string[] = [];
  allDeviceIdsList: string[] = [];
  countryList: string[] = [];
  selectedCountryIndex = -1;
  selectedCityIndices = new Set<number>();
  selectedCustomer: string = '';
  selectedDeviceId: string = '';
  selectedCityIndex: number=-1;
  searchedCityList:string[]=[];
  selectedCityList:string[]=[];
  cityControl:FormControl<string[]> = new FormControl();
  citySearchControl: FormControl<string>= new FormControl();
  @Input() selectedModuleType: ModuleType | null = null;
  @Output() setDeviceId: EventEmitter<string> = new EventEmitter<string>();
  @Output() initializationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public loginService: LoginService,
    public deviceListService: DeviceListService,
    private eRef: ElementRef
  ) {
    this.citySearchControl.valueChanges.subscribe(() => this.onSearchChange());
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    try{
      if (changes['selectedModuleType']?.currentValue != null) {
        this.modules = await this.deviceListService.getData();
        await this.initializeData();
        this.initializationComplete.emit(true);
      }
    }
    catch(error){
      console.log('error in location selector initialization:', error);
    }
  }

  async initializeData() {
    //serial no. list
    this.allDeviceIdsList = this.modules?.data.moduleInformation?.countries.flatMap(country => country.cities).flatMap(city => city.customers).flatMap(customer => customer.serialNos) ?? [];
    //country list
    this.countryList = this.modules?.data.moduleInformation?.countries.map(c => c.name) ?? [];
    this.resetToDefault();
  }

  filterCities(searchText: string): void {
    let filteredCities: string[];
    if (!searchText) {
      filteredCities = [...this.cityList];
    } else {
      filteredCities = this.cityList.filter(city =>city.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    this.searchedCityList = [
      ...filteredCities.filter(city => !this.selectedCityList.includes(city)),
      ...this.selectedCityList
    ].sort((a, b) => a.localeCompare(b));
  }

  onSearchChange() {
    const searchTerm = this.citySearchControl.value.toLowerCase();
    if(searchTerm){
    this.citySearchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchText => {
      this.filterCities(searchText);
    });
    }
    else{
      this.searchedCityList = [...this.cityList];
    }
  }


  onCountryChanged(index: number) {
    if (index === this.selectedCountryIndex) return;
    this.cityList = this.modules?.data.moduleInformation?.countries[index].cities.map(c => c.name) ?? [];
    this.selectedCountryIndex = index;
    this.selectedCityIndices.clear();
    this.customerList.clear();
    this.deviceIdList = [];
    //need to reset city, customer and serialNo
    this.resetSearchByLocFields('onCountryChanged');
    this.searchedCityList=this.cityList;
    this.selectedCityList = this.cityControl.value || [];
    this.cityControl.valueChanges.subscribe(selectedCities => {
      this.selectedCityList = selectedCities;
    });
  }

  onCityChanged(event: any, index: number, city:string) {
    let selected: boolean = event.target.active;
    if (selected && (!this.selectedCityIndices.has(this.cityList.indexOf(city)))) {
      this.selectedCityList.includes(city) || this.selectedCityList.push(city);
      this.selectedCityIndex=this.cityList.indexOf(city);
      this.selectedCityIndices.add(this.selectedCityIndex);
    }
    else {
      this.selectedCityList = this.selectedCityList.filter(s=> s!==city);
      this.selectedCityIndex=this.cityList.indexOf(city);
      this.selectedCityIndices.delete(this.selectedCityIndex);
    }
    //update customer list. can contain duplicates.
    let countryData = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex];
    if (countryData) {
      this.customerList.clear();
      this.deviceIdList = [];
      for (let i of this.selectedCityIndices) {
        let customerData = countryData.cities[i].customers.map(c => c.name) ?? [];
        for (let customer of customerData) {
          this.customerList.add(customer);
        }
      }
      this.resetSearchByLocFields('onCityChanged');
    }
  }

  onCustomerChanged(customer: any) {
    if (this.selectedCustomer === customer) return;
    this.selectedCustomer = customer;
    this.deviceIdList = this.modules?.data.moduleInformation.countries[this.selectedCountryIndex]?.cities?.filter((val, i) => this.selectedCityIndices.has(i))
      .flatMap(c => c.customers)
      .filter((c) => c.name === this.selectedCustomer)
      .flatMap(c => c.serialNos) ?? [];
    //need to reset serial no.
    this.resetSearchByLocFields('onCustomerChanged');
  }

  resetSearchByLocFields(resetType: string) {
    switch (resetType) {
      case 'onCountryChanged':
        this.resetDeviceIdSelection();
        this.resetCustomerSelection();
        this.resetCitySelection();
        break;
      case 'onCityChanged':
        this.resetDeviceIdSelection();
        this.resetCustomerSelection();
        break;
      case 'onCustomerChanged':
        this.resetDeviceIdSelection();
        break;
    }
  }

  resetCountrySelection() {
    let customerEl = document.getElementById(this.searchByLocation ? 'Selector_Country_DropDown' : 'Selector_Country_Input');
    if ((customerEl as any).value !== '') {
      (customerEl as any).value = '';
    }
    this.selectedCountryIndex = -1;
    this.resetSearchByLocFields('onCountryChanged');
  }

  resetCitySelection() {
    let cityEl = document.getElementById(this.searchByLocation ? 'Selector_City_DropDown' : 'Selector_City_Input');
    (cityEl as any).value = '';
    (cityEl as any)._multiValue = [];
    this.selectedCityIndices.clear();
    this.selectedCityIndex = -1;
  }

  resetCustomerSelection() {
    let customerEl = document.getElementById(this.searchByLocation ? 'Selector_Customer_DropDown' : 'Selector_Customer_Input');
    if ((customerEl as any).value !== '') {
      (customerEl as any).value = '';
    }
    this.selectedCustomer = '';
  }

  resetDeviceIdSelection() {
    let el = document.getElementById(this.searchByLocation ? 'Selector_SerialNo_DropDown' : 'Selector_SerialNo_Search');
    if (el) {
      (el as any).value = '';
    }
    this.selectedDeviceId = '';

    this.setDeviceId.emit(this.selectedDeviceId);
  }

  onSerialNoChanged(deviceId: any) {
    this.selectedDeviceId = deviceId.toUpperCase();
    let validDeviceId = this.deviceIdList.includes(this.selectedDeviceId)
    this.setDeviceId.emit(validDeviceId ? this.selectedDeviceId : '');
  }

  setLocationDetails(event: any) {
    this.selectedDeviceId = event.target.value.toUpperCase();
    event.target.value = this.selectedDeviceId;
    let validDeviceId = this.allDeviceIdsList.includes(this.selectedDeviceId)
    let location = this.getLocationDetails(this.selectedDeviceId);
    let countryEl = document.getElementById('Selector_Country_Input');
    let cityEl = document.getElementById('Selector_City_Input');
    let customerEl = document.getElementById('Selector_Customer_Input');
    (countryEl as any).value = location?.country ?? '';
    (cityEl as any).value = location?.city ?? '';
    if (location?.city) {
      this.selectedCityList = [location.city];
    }
    (customerEl as any).value = location?.customer ?? '';
    this.setDeviceId.emit(validDeviceId ? this.selectedDeviceId : '');
  }

  getLocationDetails(deviceId: string): { country: string, countryIndex: number, city: string, cityIndex: number, customer: string, customerIndex: number, deviceId: string } | undefined {
    let countries = this.modules?.data.moduleInformation.countries ?? [];
    for (let [countryIndex, country] of countries.entries()) {
      for (let [cityIndex, city] of country.cities.entries()) {
        for (let [customerIndex, customer] of city.customers.entries()) {
          if (customer.serialNos.find((sNo) => sNo === deviceId)) {
            return {
              country: country.name,
              city: city.name,
              customer: customer.name,
              deviceId,
              countryIndex,
              cityIndex,
              customerIndex
            };
          }
        }
      }
    }
    return undefined;
  }

  onSearchByLocation(searchByLocation: boolean) {
    this.searchByLocation = searchByLocation;
    let details = this.getLocationDetails(this.selectedDeviceId);
    this.setDetails(details);

  }

  setDetails(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    if (this.searchByLocation) {
      this.handleSearchByLocation(details);
    }
    else if (!this.searchByLocation) {
      this.handleSearchByDeviceId(details);
    }
  }

  handleSearchByDeviceId(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    setTimeout(() => {
      let countryEl = document.getElementById('Selector_Country_Input');
      let cityEl = document.getElementById('Selector_City_Input');
      let customerEl = document.getElementById('Selector_Customer_Input');
      let deviceIdSearchEl = document.getElementById('Selector_SerialNo_Search');
      (countryEl as any).value = details?.country ?? '';
      (cityEl as any).value = details?.city ?? '';
      (customerEl as any).value = details?.customer ?? '';
      this.selectedDeviceId = details?.deviceId ?? '';
      (deviceIdSearchEl as any).value = this.selectedDeviceId;
      deviceIdSearchEl?.setAttribute('search-array', JSON.stringify(this.allDeviceIdsList));
      this.selectedCountryIndex = -1;
      this.selectedCityIndices.clear();
      this.selectedCustomer = '';
      this.customerList.clear();
      this.cityList = [];
    }, 0);
  }

  handleSearchByLocation(details: { country: string; countryIndex: number; city: string; cityIndex: number; customer: string; customerIndex: number; deviceId: string; } | undefined) {
    if (details) {
      this.selectedCountryIndex = details.countryIndex;
      this.selectedCityIndices.add(details.cityIndex);
      this.selectedCustomer = details.customer;
      this.selectedDeviceId = details.deviceId;
      this.cityList = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex].cities.map(c => c.name) ?? [];
      this.searchedCityList = this.cityList;
      this.customerList = new Set<string>(this.modules?.data.moduleInformation.countries[this.selectedCountryIndex]?.cities?.filter((c, i) => this.selectedCityIndices.has(i))?.flatMap(c => c.customers)?.map(c => c.name));
      this.deviceIdList = this.modules?.data.moduleInformation?.countries[this.selectedCountryIndex]?.cities?.filter((val, i) => this.selectedCityIndices.has(i))
        .flatMap(c => c.customers)
        .filter((c) => c.name === this.selectedCustomer)
        .flatMap(c => c.serialNos) ?? [];
    }
    else {
      this.selectedCountryIndex = -1;
      this.selectedCityIndices.clear();
      this.selectedCustomer = '';
      this.selectedDeviceId = '';
      this.cityList = [];
      this.customerList.clear();
      this.deviceIdList = [];
    }
    setTimeout(() => {
      let countryEl = document.getElementById('Selector_Country_DropDown');
      let cityEl = document.getElementById('Selector_City_DropDown');
      let customerEl = document.getElementById('Selector_Customer_DropDown');
      let deviceIdSearchEl = document.getElementById('Selector_SerialNo_DropDown');
      (countryEl as any).value = details?.country ?? '';
      (cityEl as any).value = details?.city ?? '';
      (customerEl as any).value = details?.customer ?? '';
      (deviceIdSearchEl as any).value = details?.deviceId ?? '';
    }, 0);
  }


  resetToDefault() {
    this.selectedDeviceId = this.inputDeviceId;
    let details = this.getLocationDetails(this.selectedDeviceId);
    this.setDetails(details);
  }

  onCountryFocusOut(event: any) {
    let country = event.target.value;
    if (event.relatedTarget ==null && this.selectedCountryIndex !== -1 && !this.countryList.includes(country)) {
      event.target.value = this.countryList[this.selectedCountryIndex];
    }
  }
  onCustomerFocusOut(event: any) {
    let customer = event.target.value;
    if (event.relatedTarget ==null && this.selectedCustomer !== customer) {
      event.target.value = this.selectedCustomer;
    }
  }
  onDeviceIdFocusOut(event: any) {
    let deviceId = event.target.value;
    if (event.relatedTarget ==null && this.selectedDeviceId !== deviceId) {
      event.target.value = this.selectedDeviceId;
    }
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.citySearchControl.setValue('');
    }
  }
}
