

export enum TroubleshootingType {
	AssayTroubleshooting = 1,
	TransferArm = 2,
}

export enum NotificationStatus {
	New = 1,
	Inprogress = 2,
	Resolved = 3,
	UnderMonitoring = 4,
	Escalated = 5
}

export interface NotificationStatusTextItem {
	id: number;
	name: string;
	displayText: string;
	display?: boolean
}

// General enum for status
export enum Status {
	New = 1,
	Inprogress = 2,
	Resolved = 3,
	UnderMonitoring = 4,
	Escalated = 5
}

export enum QuestionnaireStatus {
	None = 0,
	Inprogress = 1,
	Completed = 2,
}

export const NotificationStatuses: NotificationStatusTextItem[] = [
	{ id: Status.New, name: 'New', displayText: 'New', display: false },
	{ id: Status.Inprogress, name: 'Inprogress', displayText: 'In Progress', display: true },
	{ id: Status.UnderMonitoring, name: 'UnderMonitoring', displayText: 'Under Monitoring', display: true },
	{ id: Status.Escalated, name: 'Escalated', displayText: 'Escalated', display: true },
	{ id: Status.Resolved, name: 'Resolved', displayText: 'Resolved', display: true },
];