import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AssayListDataModel } from './model/test-info.model';

@Injectable({
  providedIn: 'root'
})
export class CommonStoreService {

  private _assayList$: BehaviorSubject<Map<string, AssayListDataModel> | undefined> = new BehaviorSubject<Map<string, AssayListDataModel> | undefined>(undefined);
  public assayList$: Observable<Map<string, AssayListDataModel> | undefined> = this._assayList$.asObservable();

  updateAssayList(moduleType: string, assayList: AssayListDataModel) {
    if (assayList && assayList.moduleType != '') {
      let map = this._assayList$.getValue() ?? new Map<string, AssayListDataModel>();
      map.set(moduleType, assayList);
      this._assayList$.next(map);
    }
  }

  GetAssayList(moduleType: string): Observable<AssayListDataModel | undefined> {
    return this._assayList$.pipe(
      map((dataMap: Map<string, AssayListDataModel> | undefined) => {
        if (dataMap) {
          return dataMap.get(moduleType);
        }
        return undefined;
      })
    );
  }

}
