import { Component, EventEmitter, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { TestInfo } from 'src/app/model/test-info.model';
import { DeviceListService } from 'src/app/services/device-list.service';
import { LoginService } from 'src/app/services/login.service';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { QcFilter } from 'src/app/model/qc-dashboard.model';
import { QcDataStoreService } from 'src/app/services/qc-data-store.service';
import { ModuleType } from 'src/app/model/module-list.model';

/**
 * enables user to select Device and Assay.
 *
 * @export
 * @class TestSelectorComponent
 */
@Component({
  selector: 'app-test-selector',
  templateUrl: './test-selector.component.html',
  styleUrls: ['./test-selector.component.scss'],
})

export class TestSelectorComponent implements OnInit {

  moduleDataLoading = true;
  initialDataLoading = true;
  defaultDeviceId: string = '';
  clearFieldsEvent = new EventEmitter<void>();
  selectedTest: TestInfo = {
    name: '',
    longName: ''
  }
  moduleTypes: ModuleType[] = [];
  selectedModuleType: WritableSignal<ModuleType | null> = this.deviceListService.selectedModuleType;
  assayList: TestInfo[] = [];
  assaySearchValues!: string;

  selectedFilters: QcFilter = new QcFilter();

  @ViewChild('locationSelector', { static: false }) loc!: LocationSelectorComponent;
  constructor(private router: Router, public loginService: LoginService, public deviceListService: DeviceListService, public appConfigService: AppConfigService, private qcStoreService: QcDataStoreService) {
    this.defaultDeviceId = deviceListService.selectedDeviceId;
  }

  async ngOnInit() {
    await this.getInitialData();
  }

  async getInitialData() {
    try {
      this.moduleTypes = await this.deviceListService.getModuleTypes() ?? [];
      this.selectedModuleType.set(this.getDefaultModule());
      this.deviceListService.selectedModuleType.set(this.selectedModuleType());
      await this.getAllAssays();
    } catch (error) {
      console.error(error);
    }
  }

  async getAllAssays() {
    this.assayList = await this.deviceListService.getAllAssays(this.selectedModuleType()?.moduleType) ?? [];
    this.initialDataLoading = false;
    this.setDefaultTest();
  }

  getDefaultModule() {
    return this.moduleTypes.find(module => module.moduleType === 'Atellica CH 930 Analyzer') ?? null;
  }

  onLocationSelectorInitializationComplete(isComplete: boolean) {
    if (isComplete) {
      this.getTests();
      this.moduleDataLoading = false;
    }
  }
  /**
   * triggered on Submit click
   *
   * @memberof TestSelectorComponent
   */
  submit() {
    this.deviceListService.selectedDeviceId = this.loc.selectedDeviceId;
    
    this.deviceListService.selectedTest = {
      name: this.selectedTest.name,
      longName: this.selectedTest.longName
    };

    /* Update the QC Filter*/
    this.selectedFilters.assay = this.deviceListService.selectedTest.longName;
    this.selectedFilters.deviceId = this.deviceListService.selectedDeviceId;
    this.qcStoreService.updateFilter(this.selectedFilters);
    this.router.navigate(['notification-manager'], {
      queryParams: {
        moduleType: this.selectedModuleType()?.moduleType
      },
      queryParamsHandling: 'merge'
    });
  }


  /**
   * Search assay based on user input
   *
   * @param {*} event
   * @memberof TestSelectorComponent
   */
  searchAssay(event: any) {
    let testArr = event.target.searchArray;
    let testName: string = event.target.value;
    let testIndex = testArr.findIndex((t: any) => t.longName === testName);
    if (testIndex !== -1) {
      this.selectedTest.longName = testArr[testIndex].longName;
      this.selectedTest.name = testArr[testIndex].name;
    }
    else {
      this.selectedTest.name = '';
      this.selectedTest.longName = '';
    }
  }

  /**
   * Assign search Array to assay Search dropdown, based on User input
   *
   * @memberof TestSelectorComponent
   */
  getTests() {
    let el = document.getElementById('TestSelector_AssaySearch');

    el?.setAttribute('search-array', JSON.stringify(this.assayList.map((x) => {
      return {
        longName: x.longName,
        name: x.name
      }
    }))
    );
    if (this.deviceListService?.selectedTest?.name !== '') {
      (el as any).value = this.deviceListService.selectedTest.longName;
    }
  }

  /**
  * Sets Default Assay
  *
  * @memberof TestSelectorComponent
  */
  setDefaultTest() {
    this.selectedTest.name = this.deviceListService.selectedTest.name;
    this.selectedTest.longName = this.deviceListService.selectedTest.longName;
  }

  /**
   * onSetDeviceId event is triggered on device selection
   * utilized in its parent components
   *
   * @param {string} deviceId
   * @memberof TestSelectorComponent
   */
  onSetDeviceId(deviceId: string) {
    this.deviceListService.selectedDeviceId = deviceId;
  }

  /**
   * triggered on clear button click
   *
   * @memberof TestSelectorComponent
   */
  clearFields() {
    this.deviceListService.selectedDeviceId = '';
    this.loc.resetToDefault();
    this.deviceListService.selectedTest = {
      name: "",
      longName: "",
    };
    let el = document.getElementById('TestSelector_AssaySearch');
    (el as any).value = '';
  }

  handleModuleTypeSelection(moduleType: ModuleType) {
    this.selectedModuleType.set(moduleType);
    this.deviceListService.selectedModuleType = this.selectedModuleType;
    this.getAllAssays();
  }

}

