import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from 'src/app/model/questionnaire.model';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent {

  @Input() controlOptions!: Option[];
  @Input() mandatory:boolean=false;
  @Input() multiselect:boolean=false;
  @Input() label:string="";
  @Input() value: string| undefined = '';
  @Input() disabled: boolean = false;

  @Output() selectionChange = new EventEmitter<string>();

  onSelect(event: any) {
    const value :string= event.target.value;
    if(this.value === value) return;
    this.value = value;
    const delimitatedValue :string =  value.replace(/,/g, '|');
    this.selectionChange.emit(delimitatedValue);
  }

  isSelected(value: string) {
    const values = this.value?.split(',') ?? [];
    return values.includes(value);
  }

}
