import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { QcLJGraphMetaData } from 'src/app/model/QcLJGraphMetaData.model';
import { FleetCompCommunicationModel, FleetCompRequestModel } from 'src/app/model/fleet-comparison-communication.model';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DeviceListService } from 'src/app/services/device-list.service';
import { FleetComparisonService } from 'src/app/services/fleet-comparison.service';
import { LoginService } from 'src/app/services/login.service';
import { QcReference, QcResult } from 'src/app/model/qc.communication.model';
import { QcReviewService } from 'src/app/services/qc-review.service';
import { Chart } from "chart.js/auto";
import { AnnotationConfig } from 'src/app/model/annotation.model';
import { AnnotationService } from 'src/app/annotation.service';
import { FleetPDFComponent } from '../fleet-pdf/fleet-pdf.component';
import { MiscLJChartDataModel } from 'src/app/model/misc-lj-chart-data.model';
import { HeaderActionButtonModel, headerActionMap } from 'src/app/model/header-action-button.model';
import { ModuleType } from 'src/app/model/module-list.model';
import { QCDateFilter } from 'src/app/model/qc-dashboard.model';
import moment from 'moment';

@Component({
  selector: 'app-fleet-comparison',
  templateUrl: './fleet-comparison.component.html',
  styleUrls: ['./fleet-comparison.component.scss']
})
export class FleetComparisonComponent implements OnInit {
  @ViewChild(FleetPDFComponent) pdfContentComponent!: FleetPDFComponent;
  headerActionConfigs: HeaderActionButtonModel[] = [];
  fleetCompData: FleetCompCommunicationModel | undefined;
  defaultFleetCompReqData: FleetCompRequestModel | undefined;
  defaultGraphData: QcLJGraphMetaData | undefined;
  miscData: MiscLJChartDataModel = {
    minRange: Number.MIN_SAFE_INTEGER,
    maxRange: Number.MAX_SAFE_INTEGER
  }
  loadingData: boolean = false;
  dateRangeString: string | undefined = '';
  annotationConfig: AnnotationConfig[] = [];
  qcRef: QcReference = {
    mean: 0,
    sd: 0,
    sd1Up: 0,
    sd1Low: 0,
    sd2Low: 0,
    sd2Up: 0,
    sd3Low: 0,
    sd3Up: 0,
    cv: 0,
    count: 0
  };
  localMedian: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  reagentLots: string[] = [];
  calLots: string[] = [];
  selectedReagentLot = "None";
  selectedCalLot = "None";
  selectedFromDate = '';
  selectedToDate = '';
  dataPointColors = ['#8F1AFF', '#009B00', '#006ADD', '#FFD200', '#EC6602', '#000000', '#707070'];
  charts: Chart[] = [];
  lastFleetRequest: FleetCompRequestModel | undefined;
  filteredGraphData: QcLJGraphMetaData | undefined;
  fleetRangeLinesError = true;
  selectedModuleType: WritableSignal<ModuleType | null>;
  defaultDateFilters: QCDateFilter;

  constructor(private fleetCompService: FleetComparisonService, public loginService: LoginService, public deviceListService: DeviceListService,
    private router: Router, public appConfigService: AppConfigService, public qcReviewService: QcReviewService, private annotationService: AnnotationService,
  ) {
    this.defaultFleetCompReqData = fleetCompService.fleetComparisonMetaData;
    this.defaultGraphData = fleetCompService.ljGraphMetaData;
    this.selectedModuleType = this.deviceListService.selectedModuleType;
    this.miscData = fleetCompService.miscData ?? this.miscData;

    this.defaultDateFilters = this.fleetCompService.getDefaultDateRange();
    this.lastFleetRequest = this.defaultFleetCompReqData;
    this.resetFilter();
  }

  async ngOnInit() {
    this.getReagentLots();
    this.getCalLots();
    await this.getFleetCompareData();
  }
  setFleetRangeError() {
    this.fleetRangeLinesError = this.qcRef.mean + (6 * this.qcRef.sd) < this.fleetCompData!.data.qcFleetDetails.sd2Up || this.qcRef.mean - (6 * this.qcRef.sd) > this.fleetCompData!.data.qcFleetDetails.sd2Low;
  }

  setGraphConfig() {
    if (!this.defaultGraphData) return;
    const item = this.defaultGraphData;
    this.setAnnotations(this.qcRef, item, item.ymax, item.ymin);
  }

  setAnnotations(qcRef: QcReference, item: QcLJGraphMetaData, upperVal: number, lowerVal: number) {
    let ymin = qcRef.sd === 0 ? qcRef.mean - 1 : Math.min(qcRef.sd3Low - (qcRef.sd * 3));
    let ymax = qcRef.sd === 0 ? qcRef.mean + 1 : Math.max(qcRef.sd3Up + (qcRef.sd * 3));
    let xMaxDate = moment(item.filter.latestDate, 'YYYY-MM-DD', true).toDate();
    xMaxDate.setDate(xMaxDate.getDate() + 1);
    let config: AnnotationConfig[] =
      qcRef.sd === 0 ?
        [
          {
            name: 'sD3Line',
            type: 'line',
            value: qcRef.mean,
            borderColor: '#009B00',
          },
          {
            name: 'mean',
            type: 'box',
            yMin: (qcRef.mean - (this.fleetCompData?.data?.qcFleetDetails?.sd2Low ?? 1)) * 0.9,
            yMax: (qcRef.mean + (this.fleetCompData?.data?.qcFleetDetails?.sd2Up ?? 1)) * 1.1,
          },
          {
            name: 'fleet-mean',
            type: 'line',
            value: this.fleetCompData?.data?.qcFleetDetails?.fleetMean,
            borderDash: [6, 5],
            borderWidth: 1.5,
            borderColor: '#006200',
            label: {
              content: 'Fleet Mean = ' + this.fleetCompData?.data?.qcFleetDetails?.fleetMean,
              display: false,
              backgroundColor: 'black',
              color: 'white',
              position: 'center'
            },
          },
        ]
        :
        [
          {
            name: 'sD3Low',
            type: 'box',
            yMin: ymin,
            yMax: qcRef.sd3Low
          },
          {
            name: 'sD3Up',
            type: 'box',
            yMin: qcRef.sd3Up,
            yMax: ymax,
          },
          {
            name: 'sD2Low',
            type: 'box',
            yMin: qcRef.sd2Up,
            yMax: qcRef.sd3Up
          },
          {
            name: 'sD2Low',
            type: 'box',
            yMin: qcRef.sd3Low,
            yMax: qcRef.sd2Low
          },
          {
            name: 'mean',
            type: 'box',
            yMin: qcRef.sd2Low,
            yMax: qcRef.sd2Up
          },
          {
            name: 'fleet-mean',
            type: 'line',
            value: this.fleetCompData?.data?.qcFleetDetails?.fleetMean,
            borderDash: [6, 5],
            borderWidth: 1.5,
            borderColor: '#006200',
            label: {
              content: 'Fleet Mean = ' + this.fleetCompData?.data?.qcFleetDetails?.fleetMean,
              display: false,
              backgroundColor: 'black',
              color: 'white',
              position: 'center'
            },
            drawTime: 'afterDraw'
          },
          {
            name: 'sD1Low',
            type: 'line',
            value: qcRef.sd1Low,
            borderColor: '#009B00',
            borderWidth: 0.5,
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd1Up,
            borderColor: '#009B00',
            borderWidth: 0.5
          },

          {
            name: 'sD3Line',
            type: 'line',
            value: qcRef.mean,
            borderColor: '#009B00',
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd2Up,
            borderColor: '#009B00',
            borderWidth: 0.5
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd2Low,
            borderColor: '#009B00',
            borderWidth: 0.5,
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd3Up,
            borderColor: '#FCF500',
            borderWidth: 0.5
          },
          {
            name: 'sD1_Low',
            type: 'line',
            value: qcRef.sd3Low,
            borderColor: '#FCF500'
          },
          {//+4SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Up + qcRef.sd,
            borderColor: '#E7001D',
          },
          { //+5SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Up + qcRef.sd + qcRef.sd,
            borderColor: '#E7001D'
          },
          {//3SD
            name: 'sD3Line',
            type: 'line',
            value: ymax,
            borderColor: '#E7001D',
          },
          {//-4SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Low - qcRef.sd,
            borderColor: '#E7001D',
          },
          { //-5SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Low - qcRef.sd - qcRef.sd,
            borderColor: '#E7001D',
          },
          { //-6SD
            name: 'sD3Line',
            type: 'line',
            value: ymin,
            borderColor: '#E7001D',
          }]

    if (this.fleetCompData?.data?.qcFleetDetails?.sdOfModuleMeans !== 0) {
      config.push({
        name: 'fleet-minus-two-sd',
        type: 'line',
        value: this.fleetCompData?.data?.qcFleetDetails?.sd2Low,
        borderDash: [8, 5],
        borderColor: '#8A5942',
        borderWidth: 1.5,
        // backgroundColor: '#8A5942',
        label: {
          content: 'SD Of Module Means -2SD  = ' + this.fleetCompData?.data?.qcFleetDetails?.sd2Low,
          display: false,
          backgroundColor: 'black',
          color: 'white',
          position: 'center'
        },
        drawTime: 'afterDraw'
      },
        {
          name: 'fleet-plus-two-sd',
          type: 'line',
          borderDash: [8, 5],
          value: this.fleetCompData?.data?.qcFleetDetails?.sd2Up,
          borderColor: '#8A5942',
          // backgroundColor: '#8A5942',
          borderWidth: 1.5,
          label: {
            content: 'SD Of Module Means +2SD  = ' + this.fleetCompData?.data?.qcFleetDetails?.sd2Up,
            display: false,
            backgroundColor: 'black',
            color: 'white',
            position: 'center'
          },
          drawTime: 'afterDraw'
        })
    }
    this.annotationConfig = this.annotationService.generateAnnotations(qcRef, config);
  }

  async getFleetCompareData() {
    this.loadingData = true;
    this.headerActionConfigs = [];
    try {
      if (this.defaultFleetCompReqData) {
        this.filteredGraphData = this.getFilteredGraphData();
        this.calculateMedian();
        let request: FleetCompRequestModel = {
          AssayCode: this.defaultFleetCompReqData.AssayCode,
          QcLotId: this.defaultFleetCompReqData.QcLotId,
          ResultUnits: this.defaultFleetCompReqData.ResultUnits,
          ReagentLotId: this.selectedReagentLot == 'None' ? '' : this.selectedReagentLot,
          CalibrationLotId: this.selectedCalLot == 'None' ? '' : this.selectedCalLot,
          FromDate: this.selectedFromDate,
          ToDate: this.selectedToDate,
          ModuleType: this.selectedModuleType()?.moduleType ?? ''
        }
        this.lastFleetRequest = request;
        this.fleetCompData = (await this.fleetCompService.getFleetCompData(request));
        this.setGraphConfig();
        this.setFleetRangeError();
      }
    }
    catch (err) {
      console.log('FleetComparisonComponent : ngOnInit : ', err);
    }
    finally {
      this.loadingData = false;
      this.headerActionConfigs = [headerActionMap.get('export-pdf') as HeaderActionButtonModel];
    }
  }

  calculateMedian() {
    let values = this.filteredGraphData?.qcResults.map(res => res.value).filter(res => this.miscData.minRange <= res && res <= this.miscData.maxRange) ?? [];
    if (values.length === 0) {
      this.localMedian.next(0);
      return;
    }
    values.sort((a, b) => a - b);
    let median;
    if (values.length % 2 === 0) {
      let middleIndex = values.length / 2;
      median = (values[middleIndex - 1] + values[middleIndex]) / 2;
    }
    else {
      median = values[Math.floor(values.length / 2)];
    }
    this.localMedian.next(parseFloat(median.toFixed(4)));
  }

  getReagentLots() {
    let fromDate = this.defaultGraphData?.filter.earliestDate ?? new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    let toDate = this.defaultGraphData?.filter.latestDate ?? new Date();
    this.reagentLots = Array.from(new Set<string>(this.defaultGraphData?.qcResults.filter(x => new Date(x.time) >= fromDate && new Date(x.time) <= toDate && x.rgtLot != "Lot Info Unavailable").flatMap(x => x.rgtLot)));
  }

  getCalLots() {
    let fromDate = this.defaultGraphData?.filter.earliestDate ?? new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    let toDate = this.defaultGraphData?.filter.latestDate ?? new Date();
    this.calLots = Array.from(new Set<string>(this.defaultGraphData?.qcResults.filter(x => new Date(x.time) >= fromDate && new Date(x.time) <= toDate && x.calLot != "Lot Info Unavailable").flatMap(x => x.calLot)));
  }

  onCalLotChanged(calLot: string) {
    this.selectedCalLot = calLot;
  }

  onReagentLotChanged(reagentLot: string) {
    this.selectedReagentLot = reagentLot;
  }

  resetFilter() {
    this.selectedFromDate = this.defaultDateFilters.earliestDateString;
    this.selectedToDate = this.defaultDateFilters.latestDateString;
    this.dateRangeString = this.defaultDateFilters.defaultDateRangeString;
    this.selectedCalLot = this.defaultFleetCompReqData?.CalibrationLotId === '' ? 'None' : this.defaultFleetCompReqData?.CalibrationLotId ?? '';
    this.selectedReagentLot = this.defaultFleetCompReqData?.ReagentLotId === '' ? 'None' : this.defaultFleetCompReqData?.ReagentLotId ?? '';
    this.filteredGraphData = this.defaultGraphData;
  }

  onDateChanged(event: any) {
    if (event.target.value) {
      let dates = event.target.value.split('-');
      this.selectedToDate = new Date(dates[1]).toDateString();
      this.selectedFromDate = new Date(dates[0]).toDateString();
    }
    this.dateRangeString = event?.target?.value;
  }

  getFilteredGraphData() {
    let filteredQcResults = this.filterQcResults();
    this.qcRef = this.getQcRef(filteredQcResults);
    //date filter
    filteredQcResults = filteredQcResults.filter((res) => {
      const resultDate = moment(res.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
      return (resultDate >= this.defaultGraphData!.filter.earliestDate && resultDate <= this.defaultGraphData!.filter.latestDate);
    })
    let filteredData: QcLJGraphMetaData = {
      assay: this.defaultGraphData!.assay,
      assayName: this.defaultGraphData!.assayName,
      cv: this.qcRef.cv,
      mean: this.qcRef.mean,
      sd: this.qcRef.sd,
      sdCalcCount: this.qcRef.count,
      sD1_Up: this.qcRef.sd1Up,
      sD1Low: this.qcRef.sd1Low,
      sD2Low: this.qcRef.sd2Low,
      sD2Up: this.qcRef.sd2Up,
      sD3Low: this.qcRef.sd3Low,
      sD3Up: this.qcRef.sd3Up,
      ymin: Math.min(this.qcRef.mean - (this.qcRef.sd * 6), filteredQcResults.reduce((min, val) => min > val.value ? val.value : min, Number.MAX_VALUE)),
      ymax: Math.min(this.qcRef.mean + (this.qcRef.sd * 6), filteredQcResults.reduce((max, val) => max < val.value ? val.value : max, Number.MIN_VALUE)),
      qcResultMax: filteredQcResults.reduce((max, val) => max < val.value ? val.value : max, Number.MIN_VALUE),
      qcResultMin: filteredQcResults.reduce((min, val) => min > val.value ? val.value : min, Number.MAX_VALUE),
      deviceId: this.defaultGraphData!.deviceId,
      filter: this.defaultGraphData!.filter,
      qcResults: filteredQcResults,
      specType: this.defaultGraphData!.specType,
      qcLevel: this.defaultGraphData!.qcLevel,
      qcLotLevel: this.defaultGraphData!.qcLotLevel,
      unit: this.defaultGraphData!.unit,
    }
    return filteredData;
  }

  filterQcResults(): QcResult[] {
    return this.defaultGraphData!.qcResults.filter((res) => {
      return (this.selectedCalLot === 'None' || res.calLot === this.selectedCalLot) && (this.selectedReagentLot === 'None' || this.selectedReagentLot === res.rgtLot);
    });
  }

  getQcRef(qcResults: QcResult[]): QcReference {
    let data = qcResults.filter(x => this.miscData.minRange <= x.value && x.value <= this.miscData.maxRange);
    let count = data.length;
    let sum = data.reduce((sum, val) => sum + val.value, 0);
    let summ = 0;
    for (let val of data) {
      summ += val.value;
    }
    let qcRef;
    if (count === 0) { // no data points are valid acc. to filters
      qcRef = {
        mean: 0,
        sd: 0,
        sd1Up: 0,
        sd1Low: 0,
        sd2Low: 0,
        sd2Up: 0,
        sd3Low: 0,
        sd3Up: 0,
        cv: 0,
        count: 0
      }
    }
    else {
      let mean = sum / count;
      let sumOfSquaredDifferences = data.reduce((sum, val) => sum + ((val.value - mean) ** 2), 0);
      let variance = sumOfSquaredDifferences / count;
      let sd = parseFloat(Math.sqrt(variance).toFixed(4));
      mean = parseFloat((sum / count).toFixed(4))
      qcRef = {
        mean,
        sd,
        sd1Up: parseFloat((mean + sd).toFixed(4)),
        sd1Low: parseFloat((mean - sd).toFixed(4)),
        sd2Low: parseFloat((mean - (2 * sd)).toFixed(4)),
        sd2Up: parseFloat((mean + (2 * sd)).toFixed(4)),
        sd3Low: parseFloat((mean - (3 * sd)).toFixed(4)),
        sd3Up: parseFloat((mean + (3 * sd)).toFixed(4)),
        cv: parseFloat(((sd / mean) * 100).toFixed(4)),
        count: count
      }
    }
    return qcRef;
  }

  headerActionHandler(actionMessage: string) {
    if (actionMessage == 'export')
      this.generatePDF();
  }

  /**
   * Get the specific HTML content to include in the PDF
   *
   * @memberof FleetComparisonComponent
   */
  generatePDF() {
    this.pdfContentComponent.generatePDF();
  }
}
