import { Component, EventEmitter, ViewChild, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { TestInfo } from 'src/app/model/test-info.model';
import { DeviceListService } from 'src/app/services/device-list.service';
import { LoginService } from 'src/app/services/login.service';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ModuleType } from 'src/app/model/module-list.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'search-serial-number',
  templateUrl: './search-serial-number.component.html',
  styleUrl: './search-serial-number.component.scss'
})
export class SearchSerialNumberComponent {

  moduleDataLoading = true;
  defaultDeviceId: string = '';
  clearFieldsEvent = new EventEmitter<void>();
  selectedTest: TestInfo = {
    name: '',
    longName: ''
  }
  moduleTypes: ModuleType[] = [];
  selectedModuleType: WritableSignal<ModuleType | null> = this.deviceListService.selectedModuleType;

  @ViewChild('locationSelector', { static: false }) loc!: LocationSelectorComponent;
  constructor(private router: Router, public loginService: LoginService, public deviceListService: DeviceListService, public appConfigService: AppConfigService, 
     private notificationService: NotificationService) {
    this.defaultDeviceId = deviceListService.selectedDeviceId
  }

  async ngOnInit() {
    await this.getInitialData();
  }

  async getInitialData() {
    try {
      this.moduleTypes = await this.deviceListService.getModuleTypes() ?? [];
      this.selectedModuleType.set(this.getDefaultModule());
      this.deviceListService.selectedModuleType.set(this.selectedModuleType());

    } catch (error) {
      console.error(error);
    }
  }

  getDefaultModule() {
    return this.moduleTypes.find(module => module.moduleType === 'Atellica CH 930 Analyzer') ?? null;
  }

  onLocationSelectorInitializationComplete(isComplete: boolean) {
    if (isComplete) {
      this.moduleDataLoading = false;
    }
  }

  submit() {
    const path = '/notification-manager';
    this.deviceListService.selectedDeviceId = this.loc.selectedDeviceId;
   this.notificationService.selectedNotificationNo = ''
    this.router.navigate([path], {
      queryParams: {
        serialNo: this.deviceListService.selectedDeviceId,
      },
      queryParamsHandling: 'merge'
    });    
  }


  onSetDeviceId(deviceId: string) {
    this.deviceListService.selectedDeviceId = deviceId;
  }

  clearFields() {
    this.deviceListService.resetValues();
    this.loc.inputDeviceId = ''; // This is set to empty on Reset to clear all fields.
    this.loc.resetToDefault();
  }

  handleModuleTypeSelection(moduleType: ModuleType) {
    this.selectedModuleType.set(moduleType);
    this.deviceListService.selectedModuleType = this.selectedModuleType;
  }

}
