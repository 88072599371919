<sh-access-bar id="AccessBar" label='Assay Troubleshooting Assistant' slot='access'>
    <ng-container slot="info" *ngIf="!ifCurrentRoute('home')">
        <sh-icon id="AccessBar_Home_Icon" *ngIf="loginService.name != '' && !ifCurrentRoute('welcome')" class="access-wrapper" slot="info" icon="home"
            (click)="onHome()">
        </sh-icon>
        <sh-tabs id="AccessBar_AssayName_Tabs" slot='info'
            *ngIf="ifCurrentRoute('/troubleshooting') || ifCurrentRoute('/transfer-arms')"
            (closed)="closeNotification()">
            <sh-tab-item id="AccessBar_AssayName_TabItem_{{tabInfo.assay?.name}}" patient collapsed="true" icon="search"
                [label]="tabInfo.notificationNo" class="tab-item" [info]="tabText"></sh-tab-item>
        </sh-tabs>
    </ng-container>
    <sh-split-button id="AccessBar_Version_Dropdown" slot="functions" condensed="" role="listbox" no-clear=""
        size="s" [label]="appConfigService.appConfig.version" color="secondary">
        <sh-menu-item icon="download" [label]='fileName' (click)="downloadReleaseNote()" condensed=""></sh-menu-item>
    </sh-split-button>
    <sh-icon id="AccessBar_Version_Help"
      *ngIf="loginService.name != '' && loginService.name != undefined && !ifCurrentRoute('welcome')"
      class="help-icon"
      button
      icon='help'
      slot='functions'
      (click)="openVideoModal()">
    </sh-icon>
    <sh-icon id="AccessBar_Feedback"
      *ngIf="loginService.name != '' && loginService.name != undefined && !ifCurrentRoute('welcome')"
      class="help-icon"
      button
      icon='send-rating-feedback'
      slot='functions'
      (click)="onFeedbackClick()">
    </sh-icon>
    <sh-tooltip
      *ngIf="loginService.name != '' && loginService.name != undefined && !ifCurrentRoute('welcome')"
      class="feedback-tooltip" label="Share Feedback" target="AccessBar_Feedback" placement="bottom" slot="functions" variation="short">
    </sh-tooltip>
    <sh-user-identifier *ngIf="loginService.name != '' && !ifCurrentRoute('welcome')" id="AccessBar_User_Identifier" slot='functions'
        [name]="loginService.name" info='Siemens-Healthineers'></sh-user-identifier>
    <sh-icon  *ngIf="!ifCurrentRoute('welcome')" button="" icon="sign-out" slot="functions" (click)="logOut()" tabindex="0" size="m"></sh-icon>
</sh-access-bar>
<app-video-tutorial [tutorialVisible]="helpConfirmationPopupVisible"></app-video-tutorial>
