import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QcFilter} from '../model/qc-dashboard.model';
import { QcCommunicationModel } from '../model/qc.communication.model';
import { QcLJSelectedFilter } from '../model/qc-l-j-filter.model';

@Injectable({
  providedIn: 'root',
})
export class QcDataStoreService {

  private _qcData$: BehaviorSubject<Map<string,QcCommunicationModel> | undefined> = new BehaviorSubject<Map<string,QcCommunicationModel> | undefined>(undefined);
  public qcData$: Observable<Map<string,QcCommunicationModel> | undefined> = this._qcData$.asObservable();

  private _qcFilter$: BehaviorSubject<QcFilter | undefined> = new BehaviorSubject<QcFilter | undefined>(undefined);
  public qcFilter$: Observable<QcFilter | undefined> = this._qcFilter$.asObservable();

  private _qcReviewFilter$: BehaviorSubject<QcLJSelectedFilter | undefined> = new BehaviorSubject<QcLJSelectedFilter | undefined>(undefined);
  public qcReviewFilter$: Observable<QcLJSelectedFilter | undefined> = this._qcReviewFilter$.asObservable();

  updateData(newData: QcCommunicationModel){
    if(newData && newData.data.qcDetails.serialNo != ''){
      let map = this._qcData$.getValue() ?? new Map<string,QcCommunicationModel>();
      map.set(newData.data.qcDetails.serialNo, newData);
      this._qcData$.next(map);
    }
  }

  updateFilter(newData: QcFilter){
    if(newData){
      this._qcFilter$.next(newData);
    }
  }

  updateQcReviewFilter(filters: QcLJSelectedFilter | undefined) {
    this._qcReviewFilter$.next(filters);
  }

}
