import { Injectable } from '@angular/core';
import { SseService } from './sse.service';
import { ErrorNotificationService } from 'src/app/modules/error-notification/service/error-notification.service';
import { catchError, firstValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AccountService } from 'src/app/modules/shared/services/account.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionHandlerService {
  private static readonly MAX_RETRY_ATTEMPTS = 3;

  constructor(
    private sseService: SseService,
    private errorModel: ErrorNotificationService,
    private appConfigService: AppConfigService,
    private accountService: AccountService
  ) { }

  async HandleSSEException<T>(request: () => Promise<T>, error: any, retryCount: number): Promise<T | undefined> {

    while (retryCount < ExceptionHandlerService.MAX_RETRY_ATTEMPTS) {
      if (error.status == 552 || error.status == 503) {
        console.log(`${error.status}  ${error.message}: Registering client and retrying request...`);
        await Promise.all([
          this.appConfigService.startService(), // Create a new client ID
          this.sseService.getServerSentEvent()  // Register client again
        ]);
        try {
          console.log(`Retrying the request (Attempt ${retryCount + 1})...`);
          const response = await request(); // Retry the request (post, get, etc.)
          return response;
        } catch (retryError: any) {
          if (retryError.status !== 552 && error.status !== 503) {
            await this.HandleException(retryError); // Handle other errors
            return undefined;
          }
          retryCount++;
        }
      } else {
        await this.HandleException(error); // Handle non-401 errors
        return undefined;
      }
    }
    await this.HandleException(error);
    console.log('Max retry attempts reached.');
    return undefined;
  }


  async handleSSEExceptionObservable<T>(request: () => Observable<T>, error: any, retryCount: number) {
    if (error.status === 552) {
      console.log('552 Unauthorized: Registering client and retrying request...');
      await Promise.all([
        this.appConfigService.startService(), // Create a new client ID
        this.sseService.getServerSentEvent()  // Register client again
      ]);
      for (let attempt = 0; attempt <= ExceptionHandlerService.MAX_RETRY_ATTEMPTS; attempt++) {
        try {
          const response = await firstValueFrom(request());
          console.log(`Request succeeded on attempt ${attempt + 1}`);
          return response;
        } catch (retryError: any) {
          console.log(`Retrying the request (Attempt ${attempt + 1})...`);
          if (attempt === ExceptionHandlerService.MAX_RETRY_ATTEMPTS) {
            await this.HandleException(retryError);
            return undefined;
          }
        }
      }
      // Add a return statement here
      return undefined;

    } else {
      this.HandleException(error); // Handle non-401 errors
      return undefined;
    }
  }

  async HandleException(error: any) {
    this.errorModel.open(error);
  }

  handleUnAuthorizedError(request: Observable<any>) {
    return this.accountService.getAccessToken().pipe(
      tap((response) => {
        NetworkService.accessToken = response.accessToken;
      }),
      switchMap(() => request),
      catchError((error) => {
        this.HandleException({ status: 401, message: 'Unauthorized' });
        return of(error)
      }),
    );
  }

}
