import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { SseService } from './communication/sse.service';
import { NetworkService } from './communication/network.service';
import { filter, firstValueFrom, timeout } from 'rxjs';
import { DataType } from '../enums/data-type.enum';
import { QcCommunicationModel } from '../model/qc.communication.model';
import { QcQuestionnaireCommunicationModel } from '../model/questionnaire.model';
import { Constants } from '../constants/constants';
import { QcDataStoreService } from './qc-data-store.service';
import { NotificationResponseModel } from '../model/notification.model';
import { QCDateFilter, QcFilter } from '../model/qc-dashboard.model';
import { AssayTroubleshootingModule } from '../modules/troubleshooting/consts/assay-troubleshooting-module.enum';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QcService {

  defaultQcCommunicationModel: QcCommunicationModel = {
    dataType: 3,
    data: {
      dataType: '3',
      requestId: "",
      qcDetails: {
        qcDataList: [],
        serialNo: ""
      }
    }
  };


  constructor(protected appConfigService: AppConfigService, protected sseService: SseService, protected networkService: NetworkService, protected qcDataStore: QcDataStoreService) { }

  async getData(deviceId: string): Promise<QcCommunicationModel> {
    try {
      let storeData = await this.getStoreData(deviceId);
      if (storeData) return storeData;

      const toDate = new Date();
      toDate.setUTCDate(toDate.getUTCDate() + 1); // Add 1 day to current date
      const fromDate = new Date(toDate.getTime() - 30 * 24 * 60 * 60 * 1000); // Subtract 30 days from current date
      // Current UTC Date Time in ISO Format
      const toDateString = toDate.toISOString();
      const fromDateString = fromDate.toISOString().split('T')[0]; // From Date in ISO Format without Time , Time is set to 00:00:00
      let requestData =
      {
        "dataType": 3,
        "ClientId": this.appConfigService?.appConfig?.clientId,
        "data": {
          "ModuleType": "Atellica CH 930 Analyzer",
          "SerialNo": deviceId,
          "FromDate": fromDateString,
          "ToDate": toDateString
        }
      }
      let response: any = await this.networkService.post(requestData);
      if (response?.requestId) {
        let requestId = response.requestId;
        let data = await firstValueFrom(
          this.sseService.sseEvent$.pipe(
            filter(data => data != undefined && data.dataType === DataType.QCData && data.data.requestId == requestId),
            timeout(Constants.API_TIMEOUT)  // Unsubscribe if no value is emitted within 60 seconds
          ));
        if (data) {
          this.addQcLevelToLot(data);
          this.qcDataStore.updateData(data);
          return data;
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    return this.defaultQcCommunicationModel;
  }

  async getQCQuestionnaire(selectedNotification: NotificationResponseModel | undefined, selectAssayTroubleShootingModuleType: AssayTroubleshootingModule): Promise<QcQuestionnaireCommunicationModel> {

    const defaultQuestionnaireCommunicationModel: QcQuestionnaireCommunicationModel = {
      dataType: DataType.QuestionnaireData,
      data: {
        notificationNo: selectedNotification?.notificationNo ?? "",
        moduleType: 'Atellica CH 930 Analyzer',
        questionnaireType: selectAssayTroubleShootingModuleType.keyId,
        questionnaireCategory: {
          questionnaireSections: [],
          type: '1'
        }
      }
    };

    let requestData =
    {
      "dataType": DataType.QuestionnaireData,
      "data": {
        "ModuleType": "Atellica CH 930 Analyzer",
        "QuestionnaireType": selectAssayTroubleShootingModuleType.keyId, //move to Enum,
        "NotificationNo": selectedNotification?.notificationNo ?? "",
        "SerialNo": selectedNotification?.serialNo ?? ""
      }
    }

    let response: any = await this.networkService.postWithoutSSE(requestData);
    if (response) return response;
    return defaultQuestionnaireCommunicationModel
  }

  async getStoreData(deviceId: string): Promise<QcCommunicationModel | undefined> {
    let map = await firstValueFrom(this.qcDataStore.qcData$);
    if (map?.has(deviceId)) {
      let data = map.get(deviceId);
      let qcResultsCount = data?.data.qcDetails?.qcDataList?.length ?? 0;
      if (qcResultsCount > 0) {
        return data;
      }
    }
    return undefined;
  }

  addQcLevelToLot(data: QcCommunicationModel) {
    if (!data || data?.data?.qcDetails.qcDataList.length === 0)
      return;
    for (let item of data.data.qcDetails.qcDataList) {
      item.qcLotLevel = item.qcLotLevel + '-' + item.qcLevel;
    }
  }

  getDefaultDateRange(): QCDateFilter {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const DAYS_IN_MONTH = 30;
    const DAYS_IN_WEEK = 7;

    const today = new Date();
    const latestDate = new Date(today.setUTCDate(today.getUTCDate() + 1));
    const earliestDate = new Date(latestDate.getTime() - (DAYS_IN_MONTH * MS_PER_DAY));
    const earliestWeekDate = new Date(latestDate.getTime() - (DAYS_IN_WEEK * MS_PER_DAY));

    const formatDateString = (date: Date) => date.toISOString().split('T')[0];

    const latestDateString = formatDateString(latestDate);
    const earliestDateString = formatDateString(earliestDate);
    const earliestWeekDateString = formatDateString(earliestWeekDate);

    return {
      latestDateString,
      earliestDateString,
      defaultDateRangeString: `${this.convertToMMDDYYYY(earliestWeekDateString)}-${this.convertToMMDDYYYY(latestDateString)}`
    };
  }

  convertToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }


  getQCRouteParam(route: ActivatedRoute) {
    route.queryParams.subscribe((queryParams) => {
      if (!queryParams['notificationNo'] || !queryParams['serialNo']) {
        return;
      };
      const decoded = decodeURIComponent(queryParams['notificationNo']);
      const notification = JSON.parse(decoded);

      let selectedFilters: QcFilter = new QcFilter()

      selectedFilters.assay = notification.assayName ?? null;
      selectedFilters.deviceId = queryParams['serialNo'];

      this.qcDataStore.updateFilter(selectedFilters);
    })
  }

}
