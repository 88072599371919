import { LoginService } from './../../services/login.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TroubleshootingInfoModule } from 'src/app/model/access-bar.model';
import { AccessBarService } from 'src/app/services/access-bar.service';
import { DeviceListService } from 'src/app/services/device-list.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  cancellationConfirmationPopupVisible: boolean = false;
  constructor(private deviceListService: DeviceListService,private accessBarService:AccessBarService,private router :Router,
    public loginService: LoginService, private msalServivce: MsalService) { }

  onSelect(troubleshootingType: number) {
    const path = '/search-serial-number';
    if(troubleshootingType != this.deviceListService.selectedTroubleshootingType)
    {
      this.deviceListService.resetValues();
    }
    
    this.deviceListService.selectedTroubleshootingType = troubleshootingType;      

    this.router.navigate([path], {
      queryParams: {
        troubleshootingType: troubleshootingType,
      },
      queryParamsHandling: 'merge'
    });
    
  }

  ngOnInit() {
    this.resetTabInfo();
  }

  async resetTabInfo() {
    const tabInfo: TroubleshootingInfoModule = {
      moduleType: this.deviceListService.selectedModuleType()?.moduleType ?? "",
      deviceId: this.deviceListService.selectedDeviceId,
      troubleshootingType: this.deviceListService.selectedTroubleshootingType,
      notificationNo: "",
      assay: undefined
    }
    this.accessBarService.setTabDetails(tabInfo);
  }

  termsOfUsageNotAgreed()
  {
    this.loginService.termsAndConditionsAgreed = false;
    this.msalServivce.instance.logout();
  }

  agreed()
  {
    this.loginService.termsAndConditionsAgreed = true;
  }

  cancel()
  {
    this.cancellationConfirmationPopupVisible = true;
  }

  closeCancelConfirmtaionDialog()
  {
    this.cancellationConfirmationPopupVisible = false;
  }
}
