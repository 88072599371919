import { Injectable, signal } from '@angular/core';
import { filter, firstValueFrom, tap, timeout } from 'rxjs';
import { DataType } from '../enums/data-type.enum';
import { ModulesDataModel } from '../model/device-list.model';
import { AssayListDataModel, DeviceAssaysRequestModel, DeviceAssaysResponseModel, TestInfo } from '../model/test-info.model';
import { NetworkService } from './communication/network.service';
import { AppConfigService } from './app-config.service';
import { SseService } from './communication/sse.service';
import { Constants } from '../constants/constants';
import { DeviceListDataStoreService } from './device-list-data-store.service';
import { GetDataRequestModel } from '../model/get-data-request.model';
import { GetDataResponseModel } from '../model/get-data-response.model';
import { ModuleType, ModuleTypeDataModel } from '../model/module-list.model';
import { CommonStoreService } from '../common-store.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DeviceListService {
  defaultDeviceListCommunication: ModulesDataModel = {
    dataType: DataType.ModulesData,
    requestId: "",
    data: {
      moduleInformation: {
        moduleType: '',
        countries: []
      },
      dataType: DataType.ModulesData,
      requestId: ""
    }
  };
  selectedTest: TestInfo = {
    name: '',
    longName: '',
  }
  selectedDeviceId = '';
  selectedModuleType = signal<ModuleType | null>(null);
  selectedTroubleshootingType !:number;
  constructor(
    private networkService: NetworkService,
    private appConfigService: AppConfigService,
    private sseService: SseService,
    private deviceListDataStoreService: DeviceListDataStoreService,
    private commonStoreService: CommonStoreService,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParamMap.pipe(tap(params => {
      this.selectedDeviceId = params.get('serialNo') ?? this.selectedDeviceId;
      const troubleShoot = params.get('troubleshootingType')
      if(troubleShoot !== null)
        this.selectedTroubleshootingType = parseInt(troubleShoot)
    })).subscribe();
  }

  async getData(): Promise<ModulesDataModel> {
    try {
      let storeData = await this.getStoreData();
      if (storeData) {
        return storeData
      }
      let requestData =
      {
        "DataType": DataType.ModulesData,
        "ClientId": this.appConfigService.appConfig.clientId,
        "Data": this.selectedModuleType()?.moduleType
      }
      let response: any = await this.networkService.post(requestData);
      if (response?.requestId) {
        let requestId = response.requestId;
        let data = await firstValueFrom(this.sseService.sseEvent$.pipe(
          filter(data => {
            let bool = data !== undefined && data.data.requestId === requestId && data.dataType === DataType.ModulesData;
            return bool;
          }),
          timeout(Constants.API_TIMEOUT)  // Unsubscribe if no value is emitted within the timeout
        ));
        if (data) {
          this.deviceListDataStoreService.updateData(data);
          return data;
        }
      }

    }
    catch (error) {
      console.log('Failed to get data');
    }
    return this.defaultDeviceListCommunication;
  }

  async getStoreData() {
    let data = await firstValueFrom(this.deviceListDataStoreService.deviceList$);
    if (data?.data?.moduleInformation) {
      return data;
    }
    return undefined;
  }

  async getModuleTypes() {
    try {
      const requestData: GetDataRequestModel<string> = {
        dataType: DataType.ModuleTypes,
        data: ""
      }
      const response = await this.networkService.postWithoutSSE<GetDataResponseModel<ModuleTypeDataModel>>(requestData);
      return response?.data?.supportedATADeviceTypes;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getAllAssays(moduleId: string | undefined) {

    if (!moduleId) {
      return undefined;
    }
    try {
      const requestData: GetDataRequestModel<string> = {
        dataType: DataType.AssaysData,
        data: moduleId
      }

      const assayStore = await this.getStoreAssayList(moduleId);
      if (assayStore)
        return assayStore.assayInfoList;

      const response = await this.networkService.postWithoutSSE<GetDataResponseModel<AssayListDataModel>>(requestData);
      if (response) this.commonStoreService.updateAssayList(moduleId, response.data);
      return response?.data?.assayInfoList;

    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getDeviceAssays(data: DeviceAssaysRequestModel): Promise<GetDataResponseModel<DeviceAssaysResponseModel>> {
    const assays = await firstValueFrom(this.commonStoreService.GetAssayList(data.serialNo));
    if(assays){
      let response : GetDataResponseModel<DeviceAssaysResponseModel> = {
        dataType: DataType.AssaysData,
        requestId: "",
        data: {
          dataType: DataType.AssaysData,
          requestId: "",
          serialNo: data.serialNo,
          assays: assays.assayInfoList
        }
      }
      return response;
    }
    let result: GetDataResponseModel<DeviceAssaysResponseModel> = {
      dataType: DataType.AssaysData,
      requestId: "",
      data: {
        dataType: DataType.AssaysData,
        requestId: "",
        serialNo: data.serialNo,
        assays: []
      }
    }

    try {
      let requestData: GetDataRequestModel<DeviceAssaysRequestModel> =
      {
        dataType: DataType.AssaysData,
        clientId: this.appConfigService.appConfig.clientId,
        data: data
      }

      let response: any = await this.networkService.post(requestData);
      if (response?.requestId) {
        let requestId = response.requestId;
        let data: GetDataResponseModel<DeviceAssaysResponseModel> = await firstValueFrom(this.sseService.sseEvent$.pipe(
          filter(data => {
            let bool = data !== undefined && data.data.requestId === requestId && data.dataType === DataType.AssaysData;
            return bool;
          }),
          timeout(Constants.API_TIMEOUT)  // Unsubscribe if no value is emitted within the timeout
        ));
        if (data) {
          this.updateCommonStore(data);
          return data;
        }
      }
    }
    catch (error) {
      console.log('Failed to get data');
    }
    return result;

  }

  updateCommonStore(data: GetDataResponseModel<DeviceAssaysResponseModel>) {
    if (data.data?.serialNo && data.data.serialNo.trim() !== '' && data.data?.assays && data.data.assays.length > 0) {
      let assayDataModel: AssayListDataModel = {
        dataType: DataType.AssaysData,
        moduleType: data.data.serialNo,
        assayInfoList: data.data.assays
      }
      this.commonStoreService.updateAssayList(data.data.serialNo, assayDataModel);
    }
  }

  async getStoreAssayList(moduleId: string) {
    const assayList = await firstValueFrom(this.commonStoreService.GetAssayList(moduleId));
    return assayList;
  }

  resetValues()
  {
    this.selectedDeviceId = '';
    this.selectedTest = {
      name: "",
      longName: "",
    };
  }

  searchAssay(assaySearchEvent: any, selectedTest: any): TestInfo {
    let assayArray = assaySearchEvent.target.searchArray;
    let assayName: string = assaySearchEvent.target.value;
    let index = assayArray.findIndex((t: any) => t.longName === assayName);
    if (index !== -1) {
      selectedTest.longName = assayArray[index].longName;
      selectedTest.name = assayArray[index].name;
    } else {
      selectedTest.name = '';
      selectedTest.longName = '';
    }
    return selectedTest
  }
}
