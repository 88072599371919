import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-day-hours',
  templateUrl: './day-hours.component.html',
  styleUrl: './day-hours.component.scss'
})
export class DayHoursComponent {
  @Input() mandatory: boolean = false;
  @Input() label: string = "";
  @Output() selectionChange = new EventEmitter<string>();
  @Input() value: string = '';
  @Input() disabled: boolean = false;
  timeFrame: string = 'Days';
  number: string | undefined;

  async onNumberChange(event: any) {
    let value = event.target.value;
    if (this.disabled || isNaN(value)) return
    this.onSelect(value + ' ' + this.timeFrame);
    this.number = value;
  }

  ngOnInit() {
    let vals = this.value?.split(' ');
    if (vals?.length === 2 && !isNaN(parseInt(vals[0]))) {
      this.number = (vals[0]);
      this.timeFrame = vals[1];
    }
  }

  onTimeFrameChange(event: any) {
    let value = event.target.value;
    if (value !== '' && value !== this.timeFrame) {
      this.number = ('');
      this.timeFrame = value;
    }
  }

  onSelect(val: string) {
    this.value = val;
    this.selectionChange.emit(val);
  }
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevents the default paste behavior
  }
}
