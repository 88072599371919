import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfigService } from '../app-config.service';
import { ErrorNotificationService } from 'src/app/modules/error-notification/service/error-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SseService implements OnDestroy {

  /**
   * The API URL to establish the Server-Sent Events (SSE) connection.
   * This is retrieved from the environment configuration.
   */
  private apiUrl = environment.apiUrl;

  /**
   * A subject that emits data received from the server via the SSE connection.
   */
  sseEvent$ = new Subject<any>();

  /**
   * A BehaviorSubject that publishes the status of the SSE connection.
   * This is initially false and is updated to true when the connection is opened.
   * It is updated to false again when the connection is closed.
   */
  connectionStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  eventSource: EventSource | undefined;
  reconnectFrequencySeconds: number;

  private maxReconnectAttempts = 3; // Limit to a number of reconnection
  private reconnectAttempts = 0; // Track reconnection attempts
  connectionOpenPromise: Promise<void> | null = null; // Store connection promise

  constructor(private appConfigService: AppConfigService, private errorModel: ErrorNotificationService) {
    this.reconnectFrequencySeconds = 1;
  }

  ngOnDestroy(): void {
    this.eventSource?.close();
  }
  /**
   * Establishes a Server-Sent Events (SSE) connection to the specified API URL.
   * The connection status is published via the `connectionStatus$` BehaviorSubject.
   */
  getServerSentEvent(): Promise<void> {
    if (this.eventSource) {
      this.eventSource.close();
    }

    this.connectionOpenPromise = new Promise((resolve) => {
      this.eventSource = new EventSource(this.apiUrl + `/api/DataReady/getMessage/${this.appConfigService.appConfig.clientId}`);

      this.eventSource.onmessage = event => {
        if (event?.data) {
          const parsedData = JSON.parse(event.data);
          console.log('SSE eventData:', parsedData);
          if (parsedData.dataType === 0) this.connectionStatus$.next(true);
          this.sseEvent$.next(parsedData);
        }
      };

      this.eventSource.onopen = () => {
        console.log('The stream has been opened by the server.', this.appConfigService.appConfig.clientId);
        resolve(); // Resolve the promise when the stream opens
      };

      this.eventSource.onerror = (error: any) => {
        console.log('The stream has been closed by the server.');
        console.log('SSE error:', error);
        this.eventSource?.close();
        this.reconnect();
        this.connectionStatus$.next(false);
      };
    });
    return this.connectionOpenPromise;
  }

  private handleError(error: any) {
    this.errorModel.open(error)
  }

  private tryToSetupConnection() {
    if (this.eventSource?.readyState === EventSource.OPEN) return;
    console.log(`Trying to reconnect to the server after ${this.reconnectFrequencySeconds} seconds`);
    this.appConfigService.startService(); // Create a new client ID
    this.getServerSentEvent();  // Register client again
    this.reconnectFrequencySeconds = Math.min(this.reconnectFrequencySeconds * 2, 64); // Cap delay at 64 seconds
  }

  private reconnect() {
    this.reconnectAttempts++;
    setTimeout(this.tryToSetupConnection.bind(this), this.wait());
  }

  private wait() {
    return this.reconnectFrequencySeconds * 1000;
  }
}
