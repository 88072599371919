import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, ChartOptions, ChartConfiguration, LegendItem, CoreScaleOptions, Scale } from 'chart.js';
import { MiscLJChartDataModel } from 'src/app/model/misc-lj-chart-data.model';
import { AnnotationConfig } from 'src/app/model/annotation.model';
import { QcReference } from 'src/app/model/qc.communication.model';
import { QcLJGraphMetaData } from 'src/app/model/QcLJGraphMetaData.model';
import { QcReviewService } from 'src/app/services/qc-review.service';
import moment from 'moment';
import 'chartjs-adapter-luxon';

@Component({
  selector: 'app-l-j-chart',
  templateUrl: './l-j-chart.component.html',
  styleUrls: ['./l-j-chart.component.scss']
})
export class LJChartComponent {
  // qcref and data are overlapping , need optimization
  @Input() data!: QcLJGraphMetaData;
  @Input() qcRef?: QcReference;
  @Input() options: ChartOptions = {};
  @Input() annotationConfigs: AnnotationConfig[] = [];
  @Input() miscData: MiscLJChartDataModel | undefined;
  @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
  chart: any;

  constructor(
    private qcReviewService: QcReviewService
  ) {
  }

  fleetSDLineNames = ['fleet-minus-two-sd', 'fleet-plus-two-sd', 'fleet-mean'];
  dataPointColors = ['#8F1AFF', '#009B00', '#006ADD', '#FFD200', '#EC6602', '#000000', '#707070'];

  ngAfterViewInit(): void {
    this.getGraphData();
  }

  async getGraphData() {
    this.chart?.destroy();
    let id = 'QCReview_Graph_Chart_' + 0;
    this.drawGraph(this.data, id, 0);
  }

  drawGraph(item: QcLJGraphMetaData, id: string, i: number) {
    let upperRange = this.miscData?.maxRange ?? Number.MAX_SAFE_INTEGER;
    let lowerRange = this.miscData?.minRange ?? Number.MIN_SAFE_INTEGER;
    const qcResults = this.qcReviewService.filterOutOfBoundValues(item.qcResults, this.qcRef);
    let data = qcResults.filter(x => x.value <= upperRange && x.value >= lowerRange).map((x) => {
      const resultDate = moment(x.time.split('Z')[0], true).toDate()
      // \n is not working. need to explore
      return { 'x': resultDate.getTime(), 'y': x.value, tooltipLabel: resultDate.toLocaleString() + "\nValue = " + x.value + ' ' + item.unit, color: this.dataPointColors[0], reagentLot: x.rgtLot, calLot: x.calLot, selected: '' }
    }).sort((x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime());

    let excludedDataPoints = this.qcRef?.sd === 0 ? [] : qcResults.filter(x => !(x.value <= upperRange && x.value >= lowerRange)).map((x) => {
      // \n is not working. need to explore
      const resultDate = moment(x.time.split('Z')[0], true).toDate();
      return { 'x': resultDate.getTime(), 'y': x.value, tooltipLabel: resultDate.toLocaleString() + "\nValue = " + x.value + ' ' + item.unit, color: this.dataPointColors[0], reagentLot: x.rgtLot, calLot: x.calLot, selected: '' }
    }).sort((x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime());

    this.assignColorsToDataPoints(data);

    const chartData: ChartConfiguration['data'] = this.getChartData(data, excludedDataPoints);

    let options: any;
    if (this.qcRef) {
      options = this.getUpdatedOptions(this.qcRef);
    }

    let ctx = this.chartCanvas.nativeElement.getContext('2d')!;
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: options,
      });
    }

    // Working without this timeout, will add the timeout again, if we face the same issue again.
    // setTimeout(() => {
    //   //this needs to be in timeout for some reason as ctx is null without it
    // }, 0);
  }

  getChartData(
    data: {
      x: number;
      y: number;
      tooltipLabel: string;
      color: string;
      reagentLot: string;
      calLot: string;
      selected: string;
    }[],
    excludedDataPoints: {
      x: number;
      y: number;
      tooltipLabel: string;
      color: string;
      reagentLot: string;
      calLot: string;
      selected: string;
    }[]
  ) {
    const chartData: ChartConfiguration['data'] = {
      datasets: [
        {
          type: 'scatter',
          label: '',
          backgroundColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          data: data,
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: true,
          borderColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          borderWidth: 1
        },
        {
          type: 'scatter',
          label: '',
          backgroundColor: function (dataPoint, context) {
            return 'rgba(0,0,0,0)';
          },
          data: excludedDataPoints,
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: false,
          borderColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          borderWidth: 2
        }
      ]
    };
    return chartData;
  }

  //comment : some moved to comp
  // getUpdatedOptions(qcRef: QcReference, item: QcLJGraphMetaData, upperVal: number, lowerVal: number) {
  getUpdatedOptions(qcRef: QcReference) {
    let ymin = qcRef.sd === 0 ? this.getYMinFor0SD() : Math.min(qcRef.sd3Low - (qcRef.sd * 3));
    let ymax = qcRef.sd === 0 ? this.getYMaxFor0SD() : Math.max(qcRef.sd3Up + (qcRef.sd * 3));

    let xminDate = moment(this.data.filter.earliestDate, 'YYYY-MM-DD', true).toDate();
    let xMaxDate = moment(this.data.filter.latestDate, 'YYYY-MM-DD', true).toDate();
    xMaxDate.setDate(xMaxDate.getDate() + 1);
    let xmin = xminDate.getTime();
    let xmax = xMaxDate.getTime();

    const annotations = this.annotationConfigs;
    this.setAnnotationEvents(annotations);
    const scatterOptions: ChartConfiguration['options'] = {
      scales: {
        x: {
          title: {
            text: 'Completed Date',
            display: true,
            align: 'center',
            padding: 1,
            color: '000000E6',
            font: {
              size: 18,
              family: 'Siemens Sans',
              weight: 'bold',
            },
          },
          type: 'time',
          time: {
            unit: 'hour',
            displayFormats: {
              'hour': 'DD/MM/YYYY'
            }
          },
          max: xmax,
          min: xmin,
          afterBuildTicks: (scale) => this.generateXAxisTicks(scale, xminDate, xMaxDate),
          ticks: {
            callback: (value, index, values) => {
              //tried to convert time to date time string. needs work as data is not showing for some reason
              return new Date(value).toLocaleDateString();
            },
            autoSkipPadding: 20,
          },
          adapters: {
            date: {
              zone: 'utc', // Ensure the time zone used is UTC
            }
          },
          bounds: 'ticks'
        },
        y: {

          title: {
            text: 'QC Result Value',
            display: true,
            align: 'center',
            padding: 1,
            color: '000000E6',
            font: {
              size: 18,
              family: 'Siemens Sans',
              weight: 'bold',
            },
          },
          afterBuildTicks: (scale) => this.generateYAxisTicks(scale, qcRef, ymin, ymax),
          max: ymax,
          min: ymin,
          type: 'linear',
          position: 'left',
          ticks: {
            //do not show number if tick value is greater than or lesser than 6sd. didnt work when directly specifying above
            callback: (value, index, values) => this.generateYAxisTickLabels(value, qcRef, ymin, ymax),
          },
          grid: {
            display: false
          }
        },
      },
      plugins: {
        tooltip: {
          titleColor: 'black',
          backgroundColor: 'white',
          bodyColor: 'black',
          callbacks: {
            label: (tooltipItem) => {
              return (tooltipItem.raw as any)['tooltipLabel'].split('\n');
            },
            title: (tooltipItem) => {
              if (tooltipItem.length > 0) {
                let resVal = (tooltipItem[0].raw as any).y;
                if (resVal >= qcRef.sd2Low && resVal <= qcRef.sd2Up) return '1S';
                if (resVal >= qcRef.sd3Low && resVal <= qcRef.sd3Up) return '2S';
                return '3S';
              }
              return '';
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'end',

          // title: {
          //   text: 'Local QC Result values',
          //   display: true,
          // },
          labels: {
            generateLabels: (chart) => this.generateLabels(chart),
            usePointStyle: true,
            font: {
              size: 16,
              family: 'Siemens Sans',
              weight: 'bold',
            },
            pointStyleWidth: 40,
            padding: 28
          }
        },
        annotation: {
          annotations: this.annotationConfigs as any[]
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
    return scatterOptions;
  }
  getYMaxFor0SD(): number {
    let values = this.annotationConfigs.filter(annotation => this.fleetSDLineNames.includes((annotation as any).name)).map(annotation => (annotation as any).value);
    if (values.length == 0) return this.qcRef!.mean + 1;
    let min = Math.min(...values);
    let max = Math.max(...values);
    let maxDiff = Math.max(Math.abs(this.qcRef!.mean - min), Math.abs(max - (this.qcRef!.mean)));
    return this.qcRef!.mean + (maxDiff * 1.05);
  }
  getYMinFor0SD(): number {
    let values = this.annotationConfigs.filter(annotation => this.fleetSDLineNames.includes((annotation as any).name)).map(annotation => (annotation as any).value);
    if (values.length == 0) return this.qcRef!.mean - 1;
    let min = Math.min(...values);
    let max = Math.max(...values);
    let maxDiff = Math.max(Math.abs(this.qcRef!.mean - min), Math.abs(max - (this.qcRef!.mean)));
    return this.qcRef!.mean - (maxDiff * 1.05);
  }

  generateLabels(chart: Chart) {
    let legendItems: LegendItem[] = [];
    let annotationLegends: LegendItem[] = [];
    const sdOfModuleMeansAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'fleet-plus-two-sd' || annotation.name === 'fleet-minus-two-sd');
    if (sdOfModuleMeansAnnotation) {
      const sdOfModuleMeansLegend: LegendItem = {
        text: "SD of Module Means",
        strokeStyle: sdOfModuleMeansAnnotation.borderColor,
        lineWidth: 2,
        lineDash: [6, 5],
        pointStyle: "line",
      }
      annotationLegends.push(sdOfModuleMeansLegend);
    }
    const fleetMeanAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'fleet-mean');
    if (fleetMeanAnnotation) {
      const fleetMeanLegend: LegendItem = {
        text: "Fleet Mean",
        strokeStyle: fleetMeanAnnotation.borderColor,
        lineWidth: 2,
        lineDash: [6, 5],
        pointStyle: "line",
      }
      annotationLegends.push(fleetMeanLegend);
    }
    const customerRangeAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'customerRangeLow' || annotation.name === 'customerRangeHigh');
    if (customerRangeAnnotation) {
      const customerRangeLegend: LegendItem = {
        text: "Customer Defined Range",
        strokeStyle: customerRangeAnnotation.borderColor,
        lineWidth: 2,
        lineDash: [6, 5],
        pointStyle: "line",
      }
      annotationLegends.push(customerRangeLegend);
    }
    const customerMeanAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'customerRangeMean');
    if (customerMeanAnnotation) {
      const customerMeanLegend: LegendItem = {
        text: "Customer Defined Mean",
        strokeStyle: customerMeanAnnotation.borderColor,
        lineWidth: 2,
        pointStyle: "line",
      }
      annotationLegends.push(customerMeanLegend);
    }
    const bioradRangeAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'BioradLow' || annotation.name === 'BioradHigh');
    if (bioradRangeAnnotation) {
      const bioradRangeLegend: LegendItem = {
        text: "Biorad Range",
        strokeStyle: bioradRangeAnnotation.borderColor,
        lineWidth: 2,
        lineDash: [6, 5],
        pointStyle: "line",
      }
      annotationLegends.push(bioradRangeLegend);
    }
    const bioradMeanAnnotation = this.annotationConfigs.find(annotation => annotation.name === 'BioradMean');
    if (bioradMeanAnnotation) {
      const bioradMeanLegend: LegendItem = {
        text: "Biorad Mean",
        strokeStyle: bioradMeanAnnotation.borderColor,
        lineWidth: 2,
        pointStyle: "line",
      }
      annotationLegends.push(bioradMeanLegend);
    }
    legendItems.push(...annotationLegends);
    let data: any[] = chart.data.datasets[0].data;
    let map = new Map<string, string>();
    for (let dataPoint of data) {
      if (dataPoint?.selected !== '') {
        map.set(dataPoint.color, dataPoint.selected);
      }
    }
    for (let pair of map) {
      let item: LegendItem = {
        text: "Local QC Result values",
        fillStyle: pair[0],
        pointStyle: "rect"
      }
      legendItems.push(item);
    }
    return legendItems;
  }

  private generateYAxisTickLabels(value: any, qcRef: QcReference, ymin: number, ymax: number) {
    let str = parseFloat((+value).toFixed(4)).toString();
    if (qcRef.sd === 0) {
      str = value === qcRef.mean ? 'Mean ' + str : '';
    }
    else {
      switch (value) {
        case ymax:
          str = '+6 SD  ' + str;
          break;
        case qcRef.sd3Up + (qcRef.sd * 2):
          str = '+5 SD  ' + str;
          break;
        case qcRef.sd3Up + qcRef.sd:
          str = '+4 SD  ' + str;
          break;
        case qcRef.sd3Up:
          str = '+3 SD  ' + str;
          break;
        case qcRef.sd2Up:
          str = '+2 SD  ' + str;
          break;
        case qcRef.sd1Up:
          str = '+1 SD  ' + str;
          break;
        case qcRef.mean:
          str = 'Mean  ' + str;
          break;
        case qcRef.sd1Low:
          str = '-1 SD  ' + str;
          break;
        case qcRef.sd2Low:
          str = '-2 SD  ' + str;
          break;
        case qcRef.sd3Low:
          str = '-3 SD  ' + str;
          break;
        case qcRef.sd3Low - qcRef.sd:
          str = '-4 SD  ' + str;
          break;
        case qcRef.sd3Low - (qcRef.sd * 2):
          str = '-5 SD  ' + str;
          break;
        case ymin:
          str = '-6 SD  ' + str;
          break;
        default:
          str = '';
          break;
      }
    }
    return str;
  }

  private generateYAxisTicks(scale: Scale<CoreScaleOptions>, qcRef: QcReference, ymin: number, ymax: number) {
    //used to define ticks which in turn define grid lines
    // ranging from +- 6sd to mean
    let ticksValues;
    if (qcRef.sd === 0) {
      ticksValues = [
        {
          value: qcRef.mean,
          label: qcRef.mean.toString()
        },
        {
          value: ymin,
          label: ''
        },
        {
          value: ymax,
          label: ''
        },
      ];
    }
    else {
      ticksValues = [
        {
          value: ymax,
          label: '',
        },
        {
          value: qcRef.sd3Up + (qcRef.sd * 2),
          label: '',
        },
        {
          value: qcRef.sd3Up + qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Up,
          label: qcRef.sd3Up.toString(),
        },
        {
          value: qcRef.sd2Up,
          label: qcRef.sd2Up.toString(),
        },
        {
          value: qcRef.sd1Up,
          label: qcRef.sd1Up.toString(),
        },
        {
          value: qcRef.mean,
          label: qcRef.mean.toString(),
        },
        {
          value: qcRef.sd3Low,
          label: qcRef.sd3Low.toString(),
        },
        {
          value: qcRef.sd2Low,
          label: qcRef.sd2Low.toString(),
        },
        {
          value: qcRef.sd1Low,
          label: qcRef.sd1Low.toString(),
        },
        {
          value: qcRef.sd3Low - qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Low - (qcRef.sd * 2),
          label: '',
        },
        {
          value: ymin,
          label: '',
        },
      ];
    }
    scale.ticks = ticksValues;
  }

  private generateXAxisTicks(scale: Scale<CoreScaleOptions>, xminDate: Date, xMaxDate: Date) {
    let timeArray = [];
    const startDate = moment(xminDate, 'YYYY-MM-DD', true).toDate();
    while (startDate <= xMaxDate) {
      let tick = {
        value: startDate.getTime(),
        label: startDate.toLocaleDateString()
      };
      timeArray.push(tick);
      startDate.setDate(startDate.getDate() + 1);
    }
    scale.ticks = timeArray;
  }

  assignColorsToDataPoints(data: { x: number; y: number; tooltipLabel: string; color: string; reagentLot: string; calLot: string; selected: string }[]): Map<string, number> {
    let typeColorMap = new Map<string, number>();
    for (let point of data) {
      point.color = this.dataPointColors[0];
      point.selected = point.reagentLot
    }
    return typeColorMap;
  }

  updateChart() {
    this.chart.options.plugins.annotation.annotations = this.annotationConfigs;
    this.chart.update();
  }

  setAnnotationEvents(annotations: AnnotationConfig[]) {
    let annotationLineNames = [
      'customerRangeLow',
      'customerRangeHigh',
      'customerRangeMean',
      'BioradLow',
      'BioradHigh',
      'BioradMean',
      'fleet-minus-two-sd',
      'fleet-plus-two-sd',
      'fleet-mean'
    ];
    annotations.forEach((ann: any) => {
      if (ann.type === 'line' && annotationLineNames.includes(ann.name)) {
        ann.label.display = false;
        ann.enter = (ctx: any, event: any) => {
          ctx.element!.label!.options.display = true;
          return true;
        }
        ann.leave = (ctx: any, event: any) => {
          ctx.element!.label!.options.display = false;
          return true;
        }
      }
    })
  }
}
