<div class="container">
    <sh-input-number min="0" [max]="(timeFrame == 'Hours' ? 24 : 365)" [decimals]="(timeFrame == 'Hours' ? 2 : 0)" size="s" no-arrows
        [label]="label" [attr.mandatory]="mandatory ? '' : null" class='numeric' type="number" [value]="number"
        [disabled]="disabled" (focusout)="onNumberChange($event)" (clear-clicked)="onNumberChange($event)"
        (paste)="onPaste($event)" mandatory></sh-input-number>

    <sh-dropdown (value-changed)="onTimeFrameChange($event)" [disabled]="disabled" [value]="timeFrame" class="numeric"
        [label]="'Select days/Hours'">
        <sh-menu-item [active]="timeFrame === 'Hours'" label="Hours"></sh-menu-item>
        <sh-menu-item [active]="timeFrame === 'Days'" label="Days"></sh-menu-item>
    </sh-dropdown>
</div>