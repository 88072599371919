import { createAction, props } from "@ngrx/store";

import { NotificationStatusTextItem } from "src/app/enums/troubleshooting-type.enum";
import { NotificationResponseModel } from "src/app/model/notification.model";
import { AssayTroubleshootingModule } from "../consts/assay-troubleshooting-module.enum";
import { NavigateToHome } from "../consts/navigate-home-enum";

//write an action that sets the selected notification
export const selectNotification = createAction(
    '[Troubleshooting Module] Select Notification',
    props<{ notification: NotificationResponseModel }>() //selectNotification action takes/uses a object of notification as a parameter
);

export const notificationStatuses = createAction(
    '[Notification Module] Get Notification Status',
    props<{ statuses: NotificationStatusTextItem[] }>()
);

export const getQuestionnaire = createAction(
    '[QC Troubleshooting] Get Questionnaire',
);

export const saveQuestionnaire = createAction(
    '[Actions] Save Questionnaire',
);

export const saveQuestionnaireOnFinish = createAction(
    '[Actions] Save Questionnaire On Finish',
);

export const saveQuestionnaireOnFinishComplete = createAction(
    '[Troubleshooting Effects] Save Questionnaire On Finish Complete',
);


export const saveQuestionnaireOnExit = createAction(
    'Troubleshooting Module] Save Questionnaire On Exit',
);

export const exitQuestionnaire = createAction(
    '[Actions] Exit Questionnaire',
    props<{ navigateTo: NavigateToHome }>()
);

export const navigateToHome = createAction(
    '[Actions] Navigate To Home'
);

export const setSelectedAssayTroubleShootingModule = createAction(
    '[Troubleshooting Module] set selected assay troubleShooting module',
    props<{ assayModuleType: AssayTroubleshootingModule }>()
)

export const setNavigateFromModule = createAction(
    '[Actions] set navigate from questionnaire module',
    props<{ resetQuestionsOnNavigate: boolean }>()
);