<div class="tabViewWrapper">
    <sh-pane arrow-position="center" behaviour="collapse" size="expanded" slot="left" label="Filters"
        id="QCReview_Filter_Pane">
        <sh-datepicker label="Date Range" id="QCReview_Filter_DatePicker" months='2' [max]="latestDateString" no-clear
            [value]="this.selectedFilter?.dateRangeString" [min]="earliestDateString" range class="filter"
            (value-changed)="onDateChanged($event)"></sh-datepicker>
        <app-location-selector  id="QCReview_Filter_LocationSelector" [selectedModuleType]="this.selectedModuleType()" [deviceId]="this.deviceId" #locationSelector (initializationComplete)="onLocationSelectorInitializationComplete($event)"
            (setDeviceId)="onDeviceIdChange($event)"></app-location-selector>
        <sh-search label="Assay" (search)="onAssayChanged($event)" id="QCReview_Filter_AssaySearch"
            (clearsearch)="onAssayChanged($event)" fields='["longName","name"]' multiline [disabled]="gettingNewData"
            [value]="this.selectedFilter?.test?.longName" whole-string
            error-message="No data is available for the selected serial number">
            <sh-empty-state slot='empty' icon='laboratory-results-search' label='No Assay found.'
                id="QCReview_Filter_EmptyAssay"></sh-empty-state>
        </sh-search>
        <sh-dropdown label="Specimen Type" id="QCReview_Filter_SpecimenDropdown" class="filter"
            [disabled]="gettingNewData" [value]="this.selectedFilter.specimenType">
            <sh-menu-item *ngFor="let item of this.specimenList;index as index"
                [active]="item == this.selectedFilter.specimenType" id="Selector_{{index}}_SpecimenMenu" [label]="item"
                (click)="onSpecimenChanged(item)"></sh-menu-item>
        </sh-dropdown>
        <sh-dropdown label="Units" id="QCReview_Filter_UnitsDropdown" class="filter" [disabled]="gettingNewData"
            [value]="this.selectedFilter.units">
            <sh-menu-item *ngFor="let item of this.unitsList;index as index" (click)="onUnitsChanged(item)"
                id="Selector_{{index}}_UnitsMenu" [active]="item == this.selectedFilter.units"
                [label]="item"></sh-menu-item>
        </sh-dropdown>
        <sh-dropdown label="QC Lots/Level" id="QCReview_Filter_QCLotLevelDropdown" class="filter" multi-select
            (value-changed)="this.onQcLotLevelValueChanged()" [disabled]="gettingNewData">
            <sh-menu-item label="Select All" (click)="this.onSelectAllQcLotLevelsClicked($event)"
                id="Selector_SelectAll_SpecimenMenu"
                [hidden]="this.qcLotLevelList.size === 0 || this.qcLotLevelList.size === 1"
                [active]="this.selectedFilter.qcLotLevel.size === this.qcLotLevelList.size && this.qcLotLevelList.size > 1"></sh-menu-item>
            <sh-menu-item *ngFor="let item of this.qcLotLevelList;index as index"
                (click)="onQcLotLevelChanged($event, item)" id="Selector_{{index}}_QCLotLevelMenu"
                [active]="this.selectedFilter.qcLotLevel.has(item)" [label]="item"></sh-menu-item>
        </sh-dropdown>
        <sh-dropdown label="Reagent Lots" id="QCReview_Filter_ReagentLotDropdown" class="filter"
            [disabled]="gettingNewData" [value]="this.selectedFilter.reagentLot">
            <sh-menu-item (click)="onReagentLotChanged('View All')" id="Selector_ViewAll_ReagentMenu"
                [hidden]="this.reagentLotList.size === 0 || this.reagentLotList.size === 1"
                [active]="'View All' == this.selectedFilter.reagentLot" label="View All"></sh-menu-item>
            <sh-menu-item (click)="onReagentLotChanged('None')" [active]="'None' == this.selectedFilter.reagentLot"
                id="Selector_None_ReagentMenu" label="None"></sh-menu-item>
            <sh-menu-item *ngFor="let item of this.reagentLotList;index as index" (click)="onReagentLotChanged(item)"
                id="Selector_{{index}}_ReagentMenu" [active]="item == this.selectedFilter.reagentLot"
                [label]="item"></sh-menu-item>
        </sh-dropdown>
        <sh-dropdown label="Calibrator Lots" id="QCReview_Filter_CalLot" class="filter" [disabled]="gettingNewData"
            [value]="this.selectedFilter.calLot">
            <sh-menu-item (click)="onCalLotChanged('View All')" [active]="'View All' == this.selectedFilter.calLot"
                id="Selector_ViewAll_CalMenu" [hidden]="this.calLotList.size === 0 || this.calLotList.size === 1"
                label="View All"></sh-menu-item>
            <sh-menu-item (click)="onCalLotChanged('None')" [active]="'None' == this.selectedFilter.calLot"
                id="Selector_None_CalMenu" label="None"></sh-menu-item>
            <sh-menu-item *ngFor="let item of this.calLotList;index as index" (click)="onCalLotChanged(item)"
                id="Selector_{{index}}_CalMenu" [active]="item == this.selectedFilter.calLot"
                [label]="item"></sh-menu-item>
        </sh-dropdown>
        <div class="button-container">
            <sh-button id="QCReview_Filter_SearchBTN" label='View' (click)="getGraphs()"
                [disabled]="gettingNewData || (!calLotList.has(selectedFilter.calLot) && selectedFilter.calLot !== 'View All' && selectedFilter.calLot !== 'None') || (!reagentLotList.has(selectedFilter.reagentLot) && (selectedFilter.reagentLot !== 'View All' && selectedFilter.reagentLot !== 'None')) || selectedFilter.qcLotLevel.size === 0 || !unitsList.has(selectedFilter.units) || !assayList.has(selectedFilter.test.name) || !specimenList.has(selectedFilter.specimenType) || selectedFilter.serial === '' || selectedFilter.dateRangeString ===  ''">
            </sh-button>
            <sh-button id="QCReview_Filter_ResetBTN" color='secondary' label='Reset' (click)="resetFilter()"
                [disabled]="gettingNewData"></sh-button>
        </div>
        <div class="loading-spinner-filter" *ngIf="gettingNewData">
            <sh-spinner label="Loading" size="m" *ngIf="gettingNewData"
                id="QCReview_Filter_LoadingSpinner"></sh-spinner>
        </div>
    </sh-pane>
    <sh-card class="parent-card">

        <header-actions id="QCReview_HeaderAction" title="QC Review" [assayName]="filteredData.at(0)?.assay" [deviceId]="filteredData.at(0)?.deviceId"></header-actions>
        <div class="chart" *ngIf="filteredData">
            <ng-container *ngFor="let item of filteredData; let i = index">
                <div class="graph-title-text">
                    <sh-text size="body-1" style="font-size: 24px;font-weight: bold;"
                        id="QCReview_Graph_LJText_{{i}}">Levey - Jennings graph -
                    </sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_AssayText_{{i}}">{{item.assay}},</sh-text>
                    <sh-text size="body-1" id="QCReview_Graph_AnalyzerText_{{i}}">&nbsp;&nbsp;Analyzer -</sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_AnalyzerValue_{{i}}">{{item.deviceId}}</sh-text>
                    <sh-text size="body-1" id="QCReview_Graph_SpecimenTypeText_{{i}}">&nbsp;&nbsp;Specimen Type
                        -</sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_SpecimenTypeValue_{{i}}">{{item.specType}}</sh-text>
                    <sh-text size="body-1" id="QCReview_Graph_QCLotLevelText_{{i}}">&nbsp;&nbsp;QC Lots/Level
                        -</sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_QCLotLevelValue_{{i}}">{{item.qcLotLevel}}</sh-text>
                    <sh-text size="body-1" id="QCReview_Graph_ReagentLotText_{{i}}">&nbsp;&nbsp;Reagent Lots -</sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_ReagentLotValue_{{i}}">{{item.filter.reagentLot}}</sh-text>
                    <sh-text size="body-1" id="QCReview_Graph_CalLotText_{{i}}">&nbsp;&nbsp;Calibrator Lots -</sh-text>
                    <sh-text size="body-1" style="font-weight: bold;"
                        id="QCReview_Graph_CalLotValue_{{i}}">{{item.filter.calLot}}</sh-text>
                    <sh-icon class="more-style" icon="more" id="QCReview_Graph_MoreOptions_{{i}}" button></sh-icon>
                    <sh-popover id="QCReview_Graph_MoreTooltip_{{i}}" target="QCReview_Graph_MoreOptions_{{i}}" position="left">
                        <sh-menu-item id="QCReview_Graph_FleetComparisonOption_{{i}}" label="Fleet Comparison" (click)="navigateToFleetComp(i)"></sh-menu-item>
                        <sh-menu-item id="QCReview_Graph_QCComparisonOption_{{i}}" label="QC Comparison" (click)="navigateToQcComp(i)"></sh-menu-item>
                    </sh-popover>
                </div>
                <div class="meta-data">
                    <sh-card class="meta-data-cards" id="QCReview_Graph_MeanCard_{{i}}">
                        <div style="display: flex;">
                            <sh-text size="body-1" style="font-size: 28px;" id="QCReview_Graph_MeanValue_{{i}}">
                                {{item.mean}}&nbsp;&nbsp;</sh-text>
                            <sh-text size="body-1" id="QCReview_Graph_MeanUnitValue_{{i}}">{{item.unit}}</sh-text>
                        </div>
                        <sh-text size="body-1" id="QCReview_Graph_MeanText_{{i}}">Mean</sh-text>
                    </sh-card>
                    <sh-card class="meta-data-cards" id="QCReview_Graph_SDCard_{{i}}">
                        <div style="display: flex;">
                            <sh-text size="body-1" style="font-size: 28px;"
                                id="QCReview_Graph_SDValue_{{i}}">{{item.sd}}&nbsp;&nbsp;
                            </sh-text>
                            <sh-text size="body-1" id="QCReview_Graph_SDUnitValue_{{i}}">{{item.unit}}</sh-text>
                        </div>
                        <sh-text size="body-1" id="QCReview_Graph_SDText_{{i}}">SD</sh-text>
                    </sh-card>
                    <sh-card class="meta-data-cards" id="QCReview_Graph_CVCard_{{i}}">
                        <div style="display: flex;">
                            <sh-text size="body-1" style="font-size: 28px;"
                                id="QCReview_Graph_CVValue_{{i}}">{{item.cv}}&nbsp;&nbsp;
                            </sh-text>
                            <sh-text size="body-1" id="QCReview_Graph_CVPercentage_{{i}}">%</sh-text>
                        </div>
                        <sh-text size="body-1" id="QCReview_Graph_CVText_{{i}}">CV</sh-text>
                    </sh-card>
                    <sh-card class="meta-data-cards" id="QCReview_Graph_CountCard_{{i}}">
                        <div style="display: flex; flex-direction: column;">
                            <div style="display: flex;">
                                <sh-text size="body-1" style="font-size: 28px;" id="QCReview_Graph_CountValue_{{i}}">
                                    {{item.sdCalcCount}}&nbsp;&nbsp;</sh-text>
                                <sh-icon icon="information" size="s" active
                                    id="QCReview_Graph_CountInformationIcon_{{i}}"></sh-icon>
                            </div>
                            <sh-text size="body-1">N</sh-text>
                        </div>
                    </sh-card>
                    <sh-tooltip target='QCReview_Graph_CountInformationIcon_{{i}}' placement='top-left'
                        title='Number of QC result values' id="QCReview_Graph_CountInformationTooltip_{{i}}">
                        <div style="display: flex; flex-direction: column;">
                            <sh-text class="title-tooltip-text" style="text-decoration: none"
                                id="QCReview_Graph_CountInformationTooltipText_{{i}}">Number of QC result values
                                used to calculate mean, SD
                                and CV</sh-text>
                        </div>
                    </sh-tooltip>

                    <sh-icon icon="zoom-plus" (click)="onZoom($event, i)" button color="--ui-6" class="slider" style="align-self: center;"
                        id="QCReview_Graph_ZoomIcon_{{i}}"
                        [disabled]="Math.floor((item.filter.latestDate.getTime() + (1000 * 60 * 60 * 24) - item.filter.earliestDate.getTime()) / (1000 * 60 * 60 * 24)) <= 8"></sh-icon>
                    <sh-slider label="Set Limits to exclude outliers" show-range class="slider" style="margin-left: 0;"
                        id="QCReview_Graph_RangeSlider_{{i}}" show-value show-controls
                        *ngIf="item?.qcResultMin && item?.qcResultMax" [max]="item.qcResultMax" [min]="item.qcResultMin"
                        [upperValue]="item.qcResultMax" [lowerValue]="item.qcResultMin"
                        [step]="(Math.min(+('0.' + '0'.repeat(item.qcResultMax.toString().split('.').reverse()[0].length)+'1'),+('0.' + '0'.repeat(item.sd.toString().split('.').reverse()[0].length)+'1'),+('0.' + '0'.repeat(item.qcResultMin.toString().split('.').reverse()[0].length)+'1'),1)).toString()"
                        (range-value-change-end)="onSliderUpdate($event, i)"
                        (value-change-end)="onSliderUpdate($event, i)"></sh-slider>
                </div>
                <div class="chart-container">
                    <div class="chart-container-body" id="QCReview_Graph_ChartContainerBody_{{i}}">
                        <canvas id="QCReview_Graph_Chart_{{ i }}"></canvas>
                    </div>
                </div>
                <sh-divider style="margin-top: 1vw; margin-bottom: 1vw;"></sh-divider>
            </ng-container>
            <ng-container *ngIf="!filteredData.length">
                <div class="empty-state">
                    <sh-text  id="QCReview_Graph_EmptyState_Txt" size="header-2">No QC results are found for the selected filters. Try changing the filters.</sh-text>
                </div>
            </ng-container>
        </div>
    </sh-card>
    <div *ngIf="initializingData || initializingLocation"
        style="position: absolute; left:0; right: 0; top: 0; bottom:0; display: flex; justify-content: center; align-items: center; background-color:#F5F5F5; z-index: 1000;">
        <sh-spinner  id="QCReview_Intializing_Spinner" label="Loading" size="m"></sh-spinner>
    </div>
</div>
