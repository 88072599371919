import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestSelectorComponent } from './components/test-selector/test-selector.component';
import { HttpClientModule } from '@angular/common/http';
import { QcReviewComponent } from './components/qc-review/qc-review.component';
import { QcDashboardComponent } from './components/qc-dashboard/qc-dashboard.component';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { FleetComparisonComponent } from './components/fleet-comparison/fleet-comparison.component';
import { QcComparisonComponent } from './components/qc-comparison/qc-comparison.component';
import { HomeComponent } from './components/home/home.component';
import { SearchSerialNumberComponent } from './components/search-serial-number/search-serial-number.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

const canActivateGuards = environment.local ? [] : [MsalGuard];

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: canActivateGuards },
  { path: 'welcome', component: WelcomeComponent },
  {
    path: 'qc-dashboard',
    component: QcDashboardComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'test-selector',
    component: TestSelectorComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'search-serial-number',
    component: SearchSerialNumberComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'qc-review',
    component: QcReviewComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'fleet-comparison',
    component: FleetComparisonComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'qc-comparison',
    component: QcComparisonComponent,
    canActivate: canActivateGuards,
  },
  {
    path: 'notification-manager',
    loadChildren: () =>
      import('./modules/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
    canActivate: canActivateGuards,
  },
  {
    path: 'troubleshooting',
    loadChildren: () =>
      import('./modules/troubleshooting/troubleshooting.module').then(
        (m) => m.TroubleShootingModule
      ),
    canActivate: canActivateGuards,
  },
  {
    path: "transfer-arms",
    loadChildren: () => import("./modules/transfer-arms/transfer-arms.module").then(m => m.TransferArmsModule),
    canActivate: canActivateGuards
  },
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: '**', redirectTo: '/welcome', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
    HttpClientModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
