

<sh-modal id="termsAndConditionsModal" [visible]="!this.loginService.termsAndConditionsAgreed"
  label='Terms Of Use' modal-height='100%' (modal-closed)="termsOfUsageNotAgreed()" sticky modal-width='100%'>

  <ngx-doc-viewer [url]="'./assets/ToU/TridentATA_ToU_English.docx'" viewer="mammoth" style="width:100%; height:50vh;">
  </ngx-doc-viewer>

  <sh-button id="modal_cancel_btn" slot='footer' color='secondary' label='Cancel' (click)="cancel()"></sh-button>
  <sh-button id="modal_agree_btn" slot='footer' label='Agree' (click)="agreed()"></sh-button>

</sh-modal>

<sh-modal id="cancellationConfirmation" label = "Are you Sure?" [visible] ="cancellationConfirmationPopupVisible" sticky modal-height = '22%' modal-width="20%">
  <sh-text id="cancellationConfirmationText" size="body-1" class="search-by-text">You have chosen to cancel. You'll need to login again
    to review the terms. You must agree to the terms to access the application.
    Do you want to Proceed?
  </sh-text>
  <sh-button id="modal_cancelConfirmation_btn" slot='footer' color='secondary' label='Cancel' (click)="closeCancelConfirmtaionDialog()"></sh-button>
  <sh-button id="modal_agreeConfirmation_btn" slot='footer' label='Ok' (click)="termsOfUsageNotAgreed()"></sh-button>
</sh-modal>

<sh-home-screen class="home-screen">
  <sh-card label="Assay Troubleshooting" icon="sample-search" href="" class="home-screen-card" (click)="onSelect(1)">
  </sh-card>
  <sh-card label="Transfer Arms" icon="c-arm-table" href="" class="home-screen-card" (click)="onSelect(2)">
  </sh-card>
</sh-home-screen>
