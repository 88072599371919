import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { CommonStoreService } from 'src/app/common-store.service';
import { DeviceAssaysRequestModel, TestInfo } from 'src/app/model/test-info.model';
import { DeviceListService } from 'src/app/services/device-list.service';

@Component({
  selector: 'cb-doc-button',
  templateUrl: './cb-doc-button.component.html',
  styleUrl: './cb-doc-button.component.scss'
})
export class CbDocButtonComponent implements OnChanges {
  @Input() assay: string | undefined;
  private assay$: BehaviorSubject<string | undefined>;
  @Input() deviceId: string | undefined;
  private deviceId$: BehaviorSubject<string | undefined>;
  pageObservable: Observable<{
    test: TestInfo | undefined;
  }>;
  constructor(private commonStore: CommonStoreService, private deviceListService: DeviceListService, private route: ActivatedRoute) {
    this.assay$ = new BehaviorSubject<string | undefined>(undefined);
    this.deviceId$ = new BehaviorSubject<string | undefined>(undefined);
    this.pageObservable = combineLatest([this.commonStore.assayList$, this.assay$, this.deviceId$]).pipe(map(([dataMap, assay, deviceId]) => {
      if ((dataMap === undefined || !(deviceId !== undefined && dataMap.has(deviceId))) && deviceId != '') {
        if (deviceId) {
          const requestData = this.createDeviceAssaysRequest(deviceId);
          deviceListService.getDeviceAssays(requestData);
        }
      }
      let assayList;
      if (deviceId !== undefined && dataMap !== undefined) {
        assayList = dataMap.get(deviceId)?.assayInfoList;
      }
      let test = assayList?.find((test) => test.name === assay || test.longName === assay);
      let hasQcDashboard = this.route.snapshot.url.some(segment => segment.path === 'qc-dashboard');
      let result: TestInfo | undefined = test === undefined ? undefined : JSON.parse(JSON.stringify(test));
      if (result?.cbDockDetails && hasQcDashboard) {
        result.cbDockDetails.Urls = result.cbDockDetails?.Urls.filter(url => url.Name !== 'PrecisionAcceptanceCriteria');
      }
      return { test: result };
    }));
  }

  private createDeviceAssaysRequest(deviceId: string): DeviceAssaysRequestModel {
    const toDate = new Date();
    toDate.setUTCDate(toDate.getUTCDate() + 1); // Add 1 day to current date
    const fromDate = new Date(toDate.getTime() - 30 * 24 * 60 * 60 * 1000); // Subtract 30 days from current date

    return {
      moduleType: this.deviceListService.selectedModuleType()?.moduleType ?? '',
      serialNo: deviceId ?? this.deviceListService.selectedDeviceId,
      fromDate: fromDate.toISOString().split('T')[0], // From Date in ISO Format without Time
      toDate: toDate.toISOString().split('T')[0] // To Date in ISO Format without Time
    };
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['assay']) {
      this.assay$.next(changes['assay'].currentValue);
    }
    if (changes['deviceId']) {
      this.deviceId$.next(changes['deviceId'].currentValue);
    }
  }

  openCbDoc(url: string) {
    window.open(url, '_blank');
  }
}
