import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import { NavBreadcrumbsComponent } from './components/nav-breadcrumbs/nav-breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { MinMaxButtonComponent } from './components/min-max-button/min-max-button.component';
import { HeaderActionsComponent } from './components/header-actions/header-actions.component';
import { LocationSelectorComponent } from 'src/app/components/location-selector/location-selector.component';
import { CbDocButtonComponent } from './components/cb-doc-button/cb-doc-button.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';

@NgModule({
  declarations: [
    NavBreadcrumbsComponent,
    MinMaxButtonComponent,
    HeaderActionsComponent,
    LocationSelectorComponent,
    CbDocButtonComponent,
    ConfirmationPopupComponent
  ],
            imports: [
              CommonModule,
              RouterModule,
              ReactiveFormsModule
            ],
  exports: [
    NavBreadcrumbsComponent,
    MinMaxButtonComponent,
    HeaderActionsComponent,
    LocationSelectorComponent,
    CbDocButtonComponent,
    ConfirmationPopupComponent
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
