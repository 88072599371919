<div class="tabViewWrapper">
  <div *ngIf="showFullPageLoader"
    style="position: absolute; left:0; right: 0; top: 0; bottom:0; display: flex; justify-content: center; align-items: center; background-color: #F5F5F5;">
    <sh-spinner id="QCDashboard_Page_Spinner" label="Loading" size="m"></sh-spinner>
  </div>
  <sh-pane id="QCDashboard_Filter_Pane" slot='left' label='Filters' [hidden]="showFullPageLoader">
     <div>
      <app-location-selector id="QCDashboard_Filter_LocationSelector" [selectedModuleType]="this.selectedModuleType()" (setDeviceId)="serialNoChange($event)" (initializationComplete)="onLocationSelectorInitializationComplete($event)"
        [deviceId]="this.selectedFilters.deviceId"  #locationSelector></app-location-selector>

      <sh-datepicker id="QCDashboard_Filter_DatePicker" months='2' style="width: 100%" label='Date Range'
        [value]="this.selectedFilters.dateRangeString" [min]="defaultFilters.earliestDateString"
        [max]="defaultFilters.latestDateString" range (value-changed)="dateRangeChange($event)"></sh-datepicker>
    </div>

    <div class="button-container">
      <sh-button id="QCDashboard_Search_Btn"
        [disabled]="!(this.selectedFilters.deviceId!='' && this.selectedFilters.dateRangeString!='') || this.showDataLoader" label='Search'
        (click)="searchQCData()">
      </sh-button>
      <sh-button id="QCDashboard_Reset_Btn" [disabled]="this.showDataLoader" color='secondary' label='Reset' (click)="resetFilter()"></sh-button>
    </div>

    <sh-text id="QCDashboard_Note_Txt" class="filters-footer-text" size='title-1'>*Calculated based on past 30 days of data</sh-text>
  </sh-pane>
  <div style="width: 82vw;margin:0" [hidden]="showFullPageLoader">
    <sh-grid>
      <!-- Filter Section -->

      <!-- Right Side Loader -->
      <sh-card columns="12" style="margin-left:1.5em;" *ngIf="showDataLoader">
        <div style=" position: absolute; left:0; right: 0; top: 0; bottom:0;margin-top: 0%;margin-top: 1%;align-self: center; display:
      flex; align-items: center;">
          <sh-spinner id="QCDashboard_Search_Spinner" label="Loading" size="m"></sh-spinner>
        </div>
      </sh-card>


      <!-- Right Section -->
      <sh-grid columns="12" [hidden]="showDataLoader">
        <!-- Section 1 -->
        <sh-card columns='12' col-m='12' col-s='12' style="margin: 1em 0 0 1.5em;">
          <div columns="12" col-m='12' col-s='12' style="height: 43.6vh;">


            <header-actions id="QCDashboard_HeaderAction" title="QC Dashboard" [assayName]="this.selectedFilters.assay" [deviceId]="this.selectedFilters.deviceId"></header-actions>


            <!-- Assay Search -->
            <sh-search id="QCDashboard_Assay_Search" label="Select Assay" mandatory
              style="width: 20vw; margin-top: 0.5em; margin-bottom: 5.6em;" (value-changed)="assayChange($event)"
              whole-string fields='["name","code"]' multiline [value]="selectedFilters.assay">
              <sh-empty-state slot='empty' icon='laboratory-results-search' label='No Assay found.'
              id="QCDashboard_AssaySearch_EmptyAssay"></sh-empty-state>
            </sh-search>

            <div [hidden]="this.donutChart == undefined ||this.donutChart.data.datasets.length == 0" style="width: 38vw;
          height: 25vh;
          float: left;
          display: block;
          text-align: center;">

              <!-- Donut Chart Label -->
              <sh-text id="QCDashboard_DonutChart_Txt"
                style="font-family: Siemens Sans, sans-serif; width: 40vw;font-weight: bold;font-size: 1.3vw;padding-left: 25.5vw;text-align: start;"
                size='header'>
                {{selectedFilters.assay}}</sh-text>

              <!-- Donut Chart -->
              <canvas id="QCDashboard_DonutChart_Canvas" *ngIf="filteredData" (ngIfChange)="bindChart()" style="
            margin-top: -10%;margin-bottom: 5%;"></canvas>
            </div>

            <div>
              <sh-button id="QCDashboard_Review_Btn"
                *ngIf="this.donutChart != undefined && this.donutChart.data.datasets.length > 0" label='Review QC'
                (click)="navigateToQcReview()" size="m" style="float: right;
            margin-top: 21vh;">
              </sh-button>
            </div>

            <div id="nodata" *ngIf="this.donutChart == undefined || this.donutChart.data.datasets.length == 0">
              <sh-text id="QCDashboard_DonutChartNoData_Txt" size='title-1' style="
            text-align: center;margin-top:10%">No Data Available.
              </sh-text>
            </div>


          </div>
        </sh-card>
        <!-- Section 2 -->
        <sh-card columns='12' col-m='12' col-s='12' style=" position: relative; margin: 0.5em 0 0 1.5em;height: 43.6vh">
          <!-- container barChart-->
          <div class="container" id="QCDashboard_barChart_Div"
            [hidden]="this.barChart == undefined || filteredData.length == 0 " scale="s">
            <!-- container body barChart -->
            <div class="container-body">
              <canvas id="QCDashboard_BarChart_Canvas" *ngIf="filteredData" style="height: 100%;"></canvas>
            </div>
          </div>
          <div id="QCDashboard_BarChartNoData_Txt" style="width:100%"
            *ngIf="this.barChart == undefined || filteredData.length == 0">
            <sh-text size='title-1' style="
          text-align: center;margin-top:10%;">No Data Available.
            </sh-text>
          </div>
        </sh-card>
      </sh-grid>

    </sh-grid>
  </div>


</div>
