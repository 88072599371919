export interface QCStat {
  deviceID: string;
  pass: number;
  twoSD: number;
  threeSD: number;
  total: number;
  assayName: string;
  assayCode: string;

}

export interface QcDashboard {
  DeviceId: string;
  QCStats: QCStat[];
}

export interface AssayList {
  assayCode: string;
  assayName: string;
}

export class QcFilter {
  deviceId: string = "";
  earliestDate: Date = new Date();
  latestDate: Date = new Date();
  dateRangeString: string = "";
  assay: string = "";
}

export interface QCDateFilter{
  latestDateString : string
  earliestDateString :string
  defaultDateRangeString? : string
}


