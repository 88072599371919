import { Injectable } from '@angular/core';
import { DateComponent } from '../modules/questions/components/date/date.component';
import { DropdownSelectComponent } from '../modules/questions/components/dropdown-select/dropdown-select.component';
import { InputTextComponent } from '../modules/questions/components/input-text/input-text.component';
import { RadioSelectComponent } from '../modules/questions/components/radio-select/radio-select.component';
import { ControlComponentConfig } from '../model/controlsConfig.model'
import { AppConfigService } from './app-config.service';
import { SseService } from './communication/sse.service';
import { NetworkService } from './communication/network.service';
import { QcService } from './qc.service';
import { CurrentQuestion, Option, PrimaryAction, Question, QuestionnaireSection } from '../model/questionnaire.model';
import { TextComponent } from '../modules/questions/components/text/text.component';
import { QcDataStoreService } from './qc-data-store.service';
import { CommonStoreService } from '../common-store.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NotificationResponseModel } from '../model/notification.model';
import { NotificationStatus } from '../enums/troubleshooting-type.enum';
import { AssayTroubleshootingModule } from '../modules/troubleshooting/consts/assay-troubleshooting-module.enum';
import { QuestionnaireStatusModel } from '../model/questionnaireStatus.model';
import { DataType } from '../enums/data-type.enum';
import { DayHoursComponent } from '../modules/questions/components/day-hours/day-hours.component';
@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService extends QcService {

  private questionnaireSections: QuestionnaireSection[] = [];
  private _questionnaireSections$ = new BehaviorSubject<QuestionnaireSection[]>(this.questionnaireSections);
  public questionnaireSections$ = this._questionnaireSections$.asObservable();
  private answeredQuestions: Set<string> = new Set<string>();
  private _answeredQuestions$ = new BehaviorSubject<Set<string>>(this.answeredQuestions);
  public answeredQuestions$ = this._answeredQuestions$.asObservable();
  private currentQn = new BehaviorSubject<CurrentQuestion | undefined>(undefined);
  private _showConfirmationPopup$ = new Subject<boolean>();
  public showConfirmationPopup$: Observable<boolean> = this._showConfirmationPopup$.asObservable();
  private _setNavigateToHome$ = new Subject<boolean>();
  public setNavigateToHome$: Observable<boolean> = this._setNavigateToHome$.asObservable();
  currentQn$ = this.currentQn.asObservable();
  private currentQuestionIndex = 0;
  private currentSectionIndex = 0;
  public isDirty = false;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private _showFinishPopup$ = new BehaviorSubject<boolean>(false);
  public showFinishPopup$: Observable<boolean> = this._showFinishPopup$.asObservable();


  constructor(appConfigService: AppConfigService, sseService: SseService, networkService: NetworkService, qcDataStore: QcDataStoreService, commonStore: CommonStoreService) {
    super(appConfigService, sseService, networkService, qcDataStore);
  }

  initializeQuestionnaires() {
    this._questionnaireSections$.next([]);
    this._answeredQuestions$.next(new Set<string>());
    this.currentQn.next(undefined);
  }

  requestNextQn(display: boolean) {
    this.onNextQuestion(this.currentQn.value?.question.questionNo);
  }

  requestPrevQn(display: boolean) {
    this.onPreviousQuestion();
  }

  override async getQCQuestionnaire(selectedNotification: NotificationResponseModel | undefined, selectAssayTroubleShootingModuleType: AssayTroubleshootingModule): Promise<any> {
    let data = await super.getQCQuestionnaire(selectedNotification, selectAssayTroubleShootingModuleType);
    this.questionnaireSections = data?.data?.questionnaireCategory?.questionnaireSections;
    this._questionnaireSections$.next(this.questionnaireSections);
    this.setCurrentIndexes();
    return data;
  }

  setCurrentIndexes() {
    this.answeredQuestions.clear();
    let question = this.questionnaireSections[0].questions[0];
    let lastAnsweredQuestion = question.questionNo;
    let action: PrimaryAction = question.primaryAction;
    const updateQuestionAndAction = (nextQuestionNo: string) => { //function to update question and action
      question = this.getQuestionData(nextQuestionNo)!;
      action = question?.primaryAction ?? undefined;
    };
    while (action) {
      const hasResponses = action.responses.some(response => response !== undefined); //check if action has responses
      this.answeredQuestions.add(question.questionNo); //add question to answered questions

      if (hasResponses) {
        lastAnsweredQuestion = question.questionNo; //set last answered question
      }

      if (!action.isMandatory || hasResponses) { //check if action is not mandatory or has responses so that we can proceed to next question
        let nextQuestionNo = action.nextQuestionNo; //get next question number
        if (action.isMandatory && hasResponses) { //check if action is mandatory and has responses
          let selectedOption = action.options.find(option => option.text == action.responses[0]); //get selected option
          const nextAction = selectedOption?.nextAction; //get next action
          nextQuestionNo = selectedOption?.nextQuestionNo ?? nextQuestionNo; //get next question number
          if (nextAction) { //check if next action is not empty and update it for the same question
            action = nextAction;
            continue;
          }
        }

        if (nextQuestionNo !== "" && nextQuestionNo.toUpperCase() !== "[END]") { //check if next question number is not empty or end
          updateQuestionAndAction(nextQuestionNo); //update question and action
        } else { //if next question number is empty or end, break the loop
          break;
        }
      } else { //if action is mandatory and has no responses, break the loop
        break;
      }
    }
    this.answeredQuestions = new Set(Array.from(this.answeredQuestions).slice(0, Array.from(this.answeredQuestions).indexOf(lastAnsweredQuestion) + 1));
    this.currentQuestionIndex = this.answeredQuestions.size - 1;
    this.currentSectionIndex = this.questionnaireSections.findIndex(section => section.questions.some(q => q.questionNo === lastAnsweredQuestion));
    this.navigateToCurrentQn(this.currentQuestionIndex);
  }

  populateCurrentQuestion() {
    if (this.currentQn.value) {
      return;
    }

    let currentQuestion: CurrentQuestion = {
      currentQuestionIndex: this.currentQuestionIndex,
      currentSectionIndex: this.currentSectionIndex,
      question: this.questionnaireSections[this.currentSectionIndex].questions[this.currentQuestionIndex],
      sectionDescription: this.questionnaireSections[this.currentSectionIndex].description
    }
    this.setCurrentQuestion(currentQuestion);
  }

  setCurrentQuestion(currentQuestion: CurrentQuestion) {
    this.addAnsweredQuestion(currentQuestion.question.questionNo);
    this.currentQn.next(currentQuestion);
  }

  navigateToCurrentQn(index: number) {
    this.currentQuestionIndex = index;
    let q = this.getAnsweredQuestionAtIndex(index);
    let result = this.findQuestion(q);
    if (result) {
      this.currentSectionIndex = result.sectionIndex;
      const currentQn = result?.question;
      if (currentQn) {
        let currentQuestion: CurrentQuestion = {
          currentQuestionIndex: this.currentQuestionIndex,
          currentSectionIndex: this.currentSectionIndex,
          question: currentQn,
          sectionDescription: result.sectionDescription
        }
        this.setCurrentQuestion(currentQuestion);
      }
    }
  }

  private componentMap: { [key: number]: ControlComponentConfig } = {
    1: { component: TextComponent, color: 'Black' },
    2: { component: RadioSelectComponent }, //change to msg
    3: { component: InputTextComponent },
    4: { component: InputTextComponent, multiLine: true },
    5: { component: DropdownSelectComponent },
    6: { component: DropdownSelectComponent, multiselect: true },
    7: { component: DateComponent, mandatory: false },
    8: { component: InputTextComponent, inputType: "number" },
    9: { component: DayHoursComponent }
  };

  getComponent(type: number): ControlComponentConfig {
    return this.componentMap[type];
  }

  getQuestionPrimaryActionResponse(questionNo: string) {
    const question = this.getQuestionData(questionNo);
    if (question?.primaryAction.responses.length) {
      return question.primaryAction.responses[0];
    } else {
      return null
    }
  }

  getQuestionData(questionNo: string) {
    for (let section of this.questionnaireSections) {
      for (let question of section.questions) {
        if (question.questionNo === questionNo) {
          return question;
        }
      }
    }
    return undefined;
  }

  getNextQnNo(currentQuestionNo: string) {
    const value = this.getQuestionPrimaryActionResponse(currentQuestionNo);
    const questionData = this.getQuestionData(currentQuestionNo);
    let nextQnNo: string = "";
    if (questionData) {
      nextQnNo = questionData.primaryAction.nextQuestionNo;
      const options = questionData.primaryAction.options;
      for (const option of options) {
        if (option.text === value) {
          nextQnNo = option.nextQuestionNo;
          if (option.nextAction) {
            nextQnNo = option.nextAction.nextQuestionNo
          }
          break;
        }
      }
    }
    return nextQnNo;
  }

  isCurrentQuestionLast() {
    return this.currentQuestionIndex === this.answeredQuestions.size - 1;
  }

  resetNextQuestionResponses(currentQuestionNo: string) {
    this.removeResetQuestionFromAnswered(currentQuestionNo);
    this.questionnaireSections.forEach(section => {
      section.questions.forEach(question => {
        if (!this.answeredQuestions.has(question.questionNo)) {
          this.resetQuestionResponses(question);
        }
      });
    });
    this._questionnaireSections$.next(this.questionnaireSections);
  }

  resetQuestionResponses(question: Question) {
    question.primaryAction.responses = [];
    const options = question.primaryAction.options
    if (options?.length) {
      options.forEach(option => {
        this.resetOptionResponses(option);
      })
    }
  }

  resetOptionResponses(option: Option) {
    if (option.nextAction) {
      option.nextAction.responses = [];
      if (option.nextAction.options?.length) {
        option.nextAction.options.forEach(option => {
          this.resetOptionResponses(option);
        });
      } else {
        return;
      }
    }
  }

  removeResetQuestionFromAnswered(currentQuestionNo: string) {
    const answeredQns = Array.from(this.answeredQuestions);
    const index = answeredQns.indexOf(currentQuestionNo);
    answeredQns.splice(index + 1, answeredQns.length);
    this.answeredQuestions = new Set(answeredQns);
    this._answeredQuestions$.next(this.answeredQuestions);
}

  addAnsweredQuestion(questionNo: string) {
    this.answeredQuestions.add(questionNo);
    this._answeredQuestions$.next(this.answeredQuestions);
}

  findAnsweredQuestionIndex(questionNo: string): number {
    // Convert the Set to an array
    const answeredQuestionsArray = Array.from(this.answeredQuestions);
    // Find the index of the questionNo in the array
    return answeredQuestionsArray.findIndex((qnNo) => qnNo === questionNo);
  }

  findIndexOfAnsweredQuestion(questionNo: string,answeredQuestions : Set<string>): number {
    // Convert the Set to an array
    const answeredQuestionsArray = Array.from(answeredQuestions);
    // Find the index of the questionNo in the array
    return answeredQuestionsArray.findIndex((qnNo) => qnNo === questionNo);
  }

  getAnsweredQuestionAtIndex(index: number): string {
    // Convert the Set to an array
    const answeredQuestionsArray = Array.from(this.answeredQuestions);
    // Find the val of the questionNo in the array
    return answeredQuestionsArray[index] ?? "";
  }

  findQuestion(questionNo: string) {
    // Start iterating from the second section (index 1)
    for (const element of this.questionnaireSections) {
      const section = element;
      // Iterate through the questions in the current section
      for (let question of section.questions) {
        if (question.questionNo === questionNo) {
          return {
            sectionNo: section.sectionNo,
            question: question,
            sectionDescription: section.description,
            sectionIndex: this.questionnaireSections.indexOf(section)
          };
        }
      }
    }
    // Return null if the question is not found
    return null;
  }

  onNextQuestion(currentQn: string | undefined) {
    if (currentQn === undefined) return;
    let nextQnNo = this.getNextQnNo(currentQn);
    const result = this.findQuestion(nextQnNo);
    if (result) {
      this.currentQuestionIndex++;
      this.currentSectionIndex = result.sectionIndex;
      const nextQn = result?.question;
      if (nextQn) {
        this.addAnsweredQuestion(nextQn.questionNo);
        let currentQuestion: CurrentQuestion = {
          currentQuestionIndex: this.currentQuestionIndex,
          currentSectionIndex: this.currentSectionIndex,
          question: nextQn,
          sectionDescription: result.sectionDescription
        }
        this.setCurrentQuestion(currentQuestion);
      }
    }
  }



  onPreviousQuestion() {
    if (this.currentQuestionIndex === 0) {
      return;
    }
    const previousQnNo = this.getAnsweredQuestionAtIndex(this.currentQuestionIndex - 1);
    const result = this.findQuestion(previousQnNo);
    if (result) {
      this.currentQuestionIndex--;
      this.currentSectionIndex = result.sectionIndex;
      const prevQn = result?.question;
      if (prevQn) {
        let currentQuestion: CurrentQuestion = {
          currentQuestionIndex: this.currentQuestionIndex,
          currentSectionIndex: this.currentSectionIndex,
          question: prevQn,
          sectionDescription: result.sectionDescription
        }
        this.setCurrentQuestion(currentQuestion);
      }
    }
  }

  updateQuestionnaireStore() {
    this._questionnaireSections$.next(this.questionnaireSections);
  }

  resetQuestionnaire() {
    this.initializeQuestionnaires();
    this.currentQuestionIndex = 0;
    this.currentSectionIndex = 0;
    let firstQuestionNo = this.getAnsweredQuestionAtIndex(0);
    this.resetNextQuestionResponses(firstQuestionNo); //reset subsequent question responses
    let firstQuestion = this.getQuestionData(firstQuestionNo);
    if (firstQuestion) {
      this.resetQuestionResponses(firstQuestion); //reset current question responses
      this.currentQn.next(undefined); //reset current question
      setTimeout(() => {
        this.populateCurrentQuestion(); //populate first question
      }, 0);
    }
  }

  async saveQuestionnaire(notification: NotificationResponseModel, assayTroubleShootingModuleType: AssayTroubleshootingModule) {
    this.showLoading();
    let data = {
      "DataType": 5,
      "Data": {
        "NotificationNo": notification.notificationNo,
        "ModuleType": "Atellica CH 930 Analyzer",
        "QuestionnaireType": assayTroubleShootingModuleType.keyId,
        "SerialNo": notification.serialNo,
        "QuestionsInfo": this.questionnaireSections.flatMap(section => section.questions)
      }
    }
    try {
      await this.networkService.send(data);
    }
    catch (e) {
      console.log(e);
    }
    this.isDirty = false;
    this.hideLoading();
  }

  async updateQuestionnaireStatus(request : QuestionnaireStatusModel) {
    let requestData = {
      "dataType": DataType.QuestionnaireStatus,
      "data": {
        "moduleType": 'Atellica CH 930 Analyzer',
        "notificationNo": request.notification.notificationNo,
        "serialNo": request.notification.serialNo,
        "status": {
          "questionnaireType": request.assayTroubleShootingModuleType.keyId,
          "questionnaireStatus": request.status
        }
      }
    }
    let response: any = await this.networkService.send(requestData);
    return response;
  }

  markAsDirty() {
    this.isDirty = true;
  }

  confirmSaveQuestionnaire() {
    this._showConfirmationPopup$.next(true);
  }

  setNavigateToHome(){
    this._setNavigateToHome$.next(true);
  }

  checkNotificationStatus(notification: NotificationResponseModel) {
    const validStatuses = [
      NotificationStatus.Resolved,
      NotificationStatus.UnderMonitoring,
      NotificationStatus.Escalated
    ];
    return validStatuses.includes(notification?.notificationStatus);
  }

  showLoading() {
    this.loadingSubject.next(true);
  }

  hideLoading() {
    this.loadingSubject.next(false);
  }

  showFinishPopup(flag: boolean) {
    this._showFinishPopup$.next(flag);
  }

  hasOneResponseAtLeast(sections: QuestionnaireSection[]): boolean {
    return sections.some(section =>
      section.questions.some(question =>
        question.primaryAction.responses?.length > 0
      )
    );
  }
}
