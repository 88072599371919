<div class="search-serial" >
    <sh-wrapper class="wrapper main-wrapper" direction="column" spacing="m" style="padding: 24px;">
      <sh-text id="SearchSerialNumber_Help_Txt" size="header-2"  color='primary'>Please Search Serial Number</sh-text>
      <sh-wrapper class="wrapper" direction="column" spacing="m" style="margin-top: 24px;">

        <sh-dropdown id="SearchSerialNumber_ModuleType_DropDown" label="Module Type" [value]="selectedModuleType()?.moduleType">
            <sh-menu-item *ngFor="let moduleType of moduleTypes"
              id="SearchSerialNumber_ModuleType_DropDown_MenuItem"
              [label]="selectedModuleType()?.moduleType"
              [active]="selectedModuleType()?.moduleType === moduleType.moduleType"
              (click)="handleModuleTypeSelection(moduleType)"
            ></sh-menu-item>
          </sh-dropdown>
        <app-location-selector style="margin-top: 8px;" id="SearchSerialNumber_LocationSelector" [selectedModuleType]="selectedModuleType()" [deviceId]="this.defaultDeviceId" (setDeviceId)="onSetDeviceId($event)" (initializationComplete)="onLocationSelectorInitializationComplete($event)"
        #locationSelector></app-location-selector>
        
        <sh-wrapper class="wrapper" direction="row" spacing="m" class="buttons" style="align-items: center; margin-top: 24px;">
          <sh-button id="SearchSerialNumber_ResetTestSelection_Btn" label="Reset" color="secondary" (click)="clearFields()"></sh-button>
          <sh-button id="SearchSerialNumber_SubmitTestSelection_Btn" label="Next" (click)="submit()"
          [disabled]="!(this.deviceListService.selectedDeviceId !== '')">
          </sh-button>
        </sh-wrapper>
      </sh-wrapper>
    </sh-wrapper>
</div>
  <div *ngIf="moduleDataLoading"
  style="position: absolute;left:0; right: 0; top: 0; bottom:0; display: flex; justify-content: center; align-items: center; background-color: #F5F5F5; z-index: 4;">
  <sh-spinner id="SearchSerialNumber_TestSelectionLoading_Spinner" label="Loading" size="m"></sh-spinner>
</div>
