import { TestInfo } from "./test-info.model";

export class QcLJFilter {
    serial: Set<string> = new Set();
    test: Set<string> = new Set();
    units: Set<string> = new Set();
    qcLotLevel: Set<string> = new Set();
    reagentLot: Set<string> = new Set();
    calLot: Set<string> = new Set();
    latestDate: Date = new Date();
    get latestDateString(): string{
        return this.latestDate.toISOString().split('T')[0];
    }
    get earliestDateString(): string{
        return this.earliestDate.toISOString().split('T')[0]; // 30 days ago
    }
    earliestDate: Date = new Date(this.latestDate.getTime() - (30 * 24 * 60 * 60 * 1000));
    sevenDays: boolean = true;
    specimenType: Set<string> = new Set<string>();
}

export interface QcLJSelectedFilter {
    serial: string;
    test: TestInfo;
    units: string;
    qcLotLevel: Set<string>;
    reagentLot: string;
    calLot: string;
    latestDate: Date;
    // sevenDays: boolean;
    specimenType: string;
    earliestDate: Date;
    dateRangeString: string;
}