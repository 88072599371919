import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, first, retry, switchMap, timeout } from 'rxjs/operators';
import { firstValueFrom, from, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SseService } from './sse.service';
import { RequestDataRequestModel } from 'src/app/model/request-data-request.model';
import { RequestDataResponseModel } from 'src/app/model/request-data-response.model';
import { SSEEventData } from 'src/app/model/sse-event-data.model'; import { Constants } from 'src/app/constants/constants';
import { ExceptionHandlerService } from './exception-handler.service';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})

export class NetworkService {

  static accessToken: string = '';

  private sSEurl = environment.apiUrl + '/api/RequestData';
  private postUrl = environment.apiUrl + '/api/GetData';
  private sendUrl = environment.apiUrl + '/api/SendData';
  private retryCount = 0;

  constructor(private http: HttpClient, private sseService: SseService,
    private errorService: ExceptionHandlerService, private appConfigService: AppConfigService) { }

  async post<T = any>(data: any, url = this.sSEurl) {

    const baseRequest = async (): Promise<T> => {
      const updatedClientId = this.appConfigService.appConfig.clientId;
      if (data.ClientId !== updatedClientId) {
        data.ClientId = updatedClientId;
      }
      // Use firstValueFrom to convert Observable to Promise
      return await firstValueFrom(
        this.http.post<T>(url, data).pipe(retry(Constants.MAX_RETRY_ATTEMPTS))
      );
    };

    // Retry or error handling logic
    const retryRequest = async (error: any): Promise<T | null> => {
      if (error.status === 401) {
        return await firstValueFrom(this.errorService.handleUnAuthorizedError(from(baseRequest()))) ?? null;
      } else {
        return await this.errorService.HandleSSEException(baseRequest, error, this.retryCount++) ?? null;
      }
    };

    try {
      const isConnected = await this.checkSseConnection();
      if (!isConnected) {
        console.log('Connection failed');
        return await retryRequest({ status: '503', message: 'Connection Failed' });
      }

      console.log('post data', data);
      return await baseRequest().catch(retryRequest);
    } catch (error: any) {
      return await retryRequest(error);
    }
  }

  async postWithoutSSE<T = any>(data: any, url = this.postUrl) {
    return await this.postData<T>(url, data);
  }

  async send<T = any>(data: any, url = this.sendUrl) {
    return await this.postData<T>(url, data);
  }

  async postData<T = any>(url: string, data: any): Promise<T | undefined> {
    try {
      console.log(`request from ${url}:`, data);
      let response = await firstValueFrom(
        this.http.post<T>(url, data).pipe(
          retry(Constants.MAX_RETRY_ATTEMPTS) // Retry up to 3 times if the request fails
        )
      );
      console.log(`response from ${url}:`, response);
      return response;
    } catch (error: any) {
      if (error.status === 401) {
        let response = await firstValueFrom(this.errorService.handleUnAuthorizedError(from(this.http.post<T>(url, data))));
        return response;
      } else {
        this.errorService.HandleException(error);
        console.log(`Failed to post data to ${url} after 3 retries`, error);
        return undefined;
      }
    }
  }

  private async checkSseConnection() {
    try {
      // Wait for the connection status to be true or timeout after 40 seconds
      return await firstValueFrom(
        this.sseService.connectionStatus$
          .pipe(
            first(status => status === true), // Wait for the connection status to be true
            timeout(40000) // If no true status is received within 40 seconds, throw an error
          )
      );
    } catch (error: any) {
      // If a timeout error occurred, return false
      if (error.name === 'TimeoutError') {
        return false;
      }
      // If any other error occurred, rethrow it
      throw error;
    }
  }

  requestDataWithSSE(data: RequestDataRequestModel, url = this.sSEurl) {
    this.retryCount = 0;

    const baseRequest = () => {
      const updatedClientId = this.appConfigService.appConfig.clientId;
      if (data.ClientId !== updatedClientId) {
        data.ClientId = updatedClientId;
      }
      return this.http.post<RequestDataResponseModel>(url, data).pipe(
        retry(Constants.MAX_RETRY_ATTEMPTS)
      );
    };

    return this.sseService.connectionStatus$.pipe(
      first(status => status === true), // Wait for the connection status to be true
      timeout(40000), // If no true status is received within 40 seconds, throw an error
      catchError(err => this.errorService.handleSSEExceptionObservable(baseRequest, { status: '503', message: 'Connection Failed' }, this.retryCount++)),
      switchMap((status) => {
        if (status) {
          const modifiedRequest = () => baseRequest().pipe(
            catchError((error: any): Observable<RequestDataResponseModel | undefined> => {
              if (error.status === 401) {
                return this.errorService.handleUnAuthorizedError(baseRequest());
              } else {
                return from(this.errorService.handleSSEExceptionObservable<RequestDataResponseModel>(baseRequest, error, this.retryCount++))
              }
            })
          )

          return modifiedRequest().pipe(
            filter((response) => Boolean(response?.requestId)),
            switchMap((response) => {
              return this.sseService.sseEvent$.pipe(
                filter((sseResponse: SSEEventData) => sseResponse?.data?.requestId === response?.requestId),
                catchError((error) => of(error))
              );
            })
          );
        } else {
          this.errorService.HandleException(() => new Error("SSE Connection Failed"));
          return throwError(() => new Error("SSE Connection Failed"));
        }
      })
    );
  }

  postDataWithoutSSE<T = any>(data: any, isSend = false): Observable<T> {
    let url = isSend ? this.sendUrl : this.postUrl;

    const request = () => {
      return this.http.post<T>(url, data).pipe(
        retry(Constants.MAX_RETRY_ATTEMPTS) // Retry non-401 errors
      );
    };

    return request().pipe(
      catchError((error: any) => {
        if (error.status === 401) {
          return this.errorService.handleUnAuthorizedError(request());
        } else {
          // Handle errors using the generic HandleSSEException method
          this.errorService.HandleException(error);
          return of(error); // Return an observable
        }
      })
    );
  }

}
