import { Injectable } from '@angular/core';
import { QcLJGraphMetaData } from '../model/QcLJGraphMetaData.model';
import { MiscLJChartDataModel } from '../model/misc-lj-chart-data.model';
import { Router } from '@angular/router';
import { QcReference } from '../model/qc.communication.model';

@Injectable({
  providedIn: 'root'
})
export class QcComparisonService {
  ljGraphMetaData: QcLJGraphMetaData | undefined;
  miscData: MiscLJChartDataModel | undefined;
  qcRef: QcReference | undefined;

  constructor(private router: Router) { }

  navigateToQCComparison(ljGraphMetaData: QcLJGraphMetaData | undefined, miscData: MiscLJChartDataModel | undefined, qcRef: QcReference | undefined) {
    if (!ljGraphMetaData || !miscData || !qcRef) {
      console.log('incomplete qc comparison data');
      return;
    }
    this.ljGraphMetaData = ljGraphMetaData;
    this.miscData = miscData;
    this.qcRef = qcRef;
    this.router.navigate(['troubleshooting', 'QC', 'qc-comparison'], {
      queryParamsHandling: 'merge'
    })
  }
}
