import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageMinMaxService } from 'src/app/services/page-min-max.service';

@Component({
  selector: 'min-max-button',
  templateUrl: './min-max-button.component.html',
  styleUrls: ['./min-max-button.component.scss']
})
export class MinMaxButtonComponent {
  isFullScreenEnabled: boolean | undefined;
  private subscription: Subscription | undefined;
  constructor(public pageMinMaxService: PageMinMaxService) { }
  ngOnInit() {
    this.subscription = this.pageMinMaxService.isFullScreenEnabled$.subscribe(
      (isEnabled: boolean | undefined) => {
        this.isFullScreenEnabled = isEnabled;
      }
    );
  }
  toggleFullScreenState() {
    this.pageMinMaxService.toggleFullScreenState();
  }
}
