<div class="flex-container">
    <sh-text id="HeaderAction_Title_TXT" size="super-header">{{title}}</sh-text>
    <div class="action-btns" >
        <sh-button  class="action-btn" *ngFor="let btn of actionButtons" id="HeaderAction_{{btn.buttonName}}_btn" [icon]='btn.buttonName'  [disabled] = 'btn.disabled'
            color='tertiary' (click)="handleAction(btn.buttonName)" size="M" [label]="btn.label" tooltip="top">
        </sh-button>
        <cb-doc-button id="HeaderAction_CBDocLinks_btn" class="action-btn cb-doc" [assay]="assayName" [deviceId]="deviceId"></cb-doc-button>
        <min-max-button  id="HeaderAction_MinMaxScreen_btn" class="action-btn" ></min-max-button>
    </div>
</div>
