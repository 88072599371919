import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from 'src/app/model/error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {

  private messageSource = new BehaviorSubject<ErrorMessage>({ status:0,label:'',message:''});  // Track message
  public message$ = this.messageSource.asObservable();  // Observable for the message
  private isModalOpen = new BehaviorSubject<boolean>(false);  // Track modal state
  isModalOpen$ = this.isModalOpen.asObservable();  // Observable to check if modal is open

  private readonly DEFAULT_ERROR: ErrorMessage = { label: 'Unknown Error', message: 'An unexpected error occurred. Please log in after a few minutes. If the issue persists, contact support for further assistance.' };

  private errorMessages: ErrorMessage[] = [
    { status: 401, label: 'Unauthorized', message: 'Please log in after a few minutes. If the issue persists, contact support for further assistance.' },
    { status: 400, label: 'Bad Request', message: 'Please contact support for assistance' },
    { status: 404, label: 'Not Found', message: 'Please contact support for assistance' },
    { status: 500, label: 'Internal Server Error', message: 'Please contact support for assistance' },
    { status: 502, label: 'Bad Gateway', message: 'Please contact support for assistance' },
    { status: 503, label: 'Connection Error', message: 'Please log in after a few minutes. If the issue persists, contact support for further assistance.' },
    { status: 504, label: 'Gateway Timeout', message: 'Please log in after a few minutes. If the issue persists, contact support for further assistance.' },
    { status: 552, label: 'Client Unrecognized', message: 'Please log in after a few minutes. If the issue persists, contact support for further assistance.'}
  ];

  getErrorMessage(errorCode: number): ErrorMessage {
    return this.errorMessages.find(error => error.status == errorCode) ?? this.DEFAULT_ERROR;
  }

  open(error: any) {
      console.log('ErrorNotificationService open error:', error);
      const errorCode = error.status; // Assuming the error object has a 'code' property
      const errorMessage = this.getErrorMessage(errorCode);
      this.messageSource.next(errorMessage);  // Update message
      this.isModalOpen.next(true);  // Show modal
  }

  close() {
    this.isModalOpen.next(false);  // Close modal
  }

}
