import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnotationService } from 'src/app/annotation.service';
import { QcLJGraphMetaData } from 'src/app/model/QcLJGraphMetaData.model';
import { AnnotationConfig } from 'src/app/model/annotation.model';
import { MiscLJChartDataModel } from 'src/app/model/misc-lj-chart-data.model';
import { QcReference } from 'src/app/model/qc.communication.model';
import { QcComparisonService } from 'src/app/services/qc-comparison.service';
import { LJChartComponent } from '../l-j-chart/l-j-chart.component';
import moment from 'moment';

@Component({
  selector: 'app-qc-comparison',
  templateUrl: './qc-comparison.component.html',
  styleUrls: ['./qc-comparison.component.scss']
})
export class QcComparisonComponent implements OnInit {
  @ViewChild('ljChart', { static: false }) ljChart!: LJChartComponent;
  Math = Math;
  miscData: MiscLJChartDataModel | undefined;
  graphData: QcLJGraphMetaData | undefined;
  qcRef: QcReference | undefined;
  annotationConfig: AnnotationConfig[] = [];
  showCustomerRanges = false;
  showBioRadRanges = false;
  customerRangeError = false;
  customerMeanError = false;
  customerRangeMean: number | undefined;
  customerRangeLow: number | undefined;
  customerRangeHigh: number | undefined;
  bioradError = false;
  bioradMeanError = false;
  bioradMean: number | undefined;
  bioradLow: number | undefined;
  bioradHigh: number | undefined;
  private readonly customerRangeLowId = 'customerRangeLow';
  private readonly customerRangeHighId = 'customerRangeHigh';
  private readonly customerRangeMeanId = 'customerRangeMean';
  private readonly BioradLowId = 'BioradLow';
  private readonly BioradHighId = 'BioradHigh';
  private readonly BioradMeanId = 'BioradMean';
  reload = false;
  showRangeErrorText: boolean = false;
  rangeErrorType: string = '';
  rangeErrorText: string = '';
  constructor(private qcCompService: QcComparisonService, private annotationService: AnnotationService) {
  }

  ngOnInit(): void {
    this.miscData = this.qcCompService.miscData;
    this.graphData = this.qcCompService.ljGraphMetaData;
    this.qcRef = this.qcCompService.qcRef;
    if (this.graphData && this.qcRef) {
      this.setAnnotations(this.qcRef, this.graphData);
    }
  }

  setAnnotations(qcRef: QcReference, item: QcLJGraphMetaData) {
    let ymin = qcRef.sd === 0 ? qcRef.mean - 1 : Math.min(qcRef.sd3Low - (qcRef.sd * 3));
    let ymax = qcRef.sd === 0 ? qcRef.mean + 1 : Math.max(qcRef.sd3Up + (qcRef.sd * 3));
    let xMaxDate= moment(item.filter.latestDate, 'YYYY-MM-DD', true).toDate();
    xMaxDate.setDate(xMaxDate.getDate() + 1);
    let config: AnnotationConfig[] =
      qcRef.sd === 0 ?
        [
          {
            name: 'sD3Line',
            type: 'line',
            value: qcRef.mean,
            borderColor: '#009B00',
          },
          {
            name: 'mean',
            type: 'box',
            yMin: ymin,
            yMax: ymax,
          },
        ]
        :
        [
          {
            name: 'sD3Low',
            type: 'box',
            yMin: ymin,
            yMax: qcRef.sd3Low
          },
          {
            name: 'sD3Up',
            type: 'box',
            yMin: qcRef.sd3Up,
            yMax: ymax,
          },
          {
            name: 'sD2Low',
            type: 'box',
            yMin: qcRef.sd2Up,
            yMax: qcRef.sd3Up
          },
          {
            name: 'sD2Low',
            type: 'box',
            yMin: qcRef.sd3Low,
            yMax: qcRef.sd2Low
          },
          {
            name: 'mean',
            type: 'box',
            yMin: qcRef.sd2Low,
            yMax: qcRef.sd2Up
          },
          {
            name: 'sD1Low',
            type: 'line',
            value: qcRef.sd1Low,
            borderColor: '#009B00',
            borderWidth: 0.5,
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd1Up,
            borderColor: '#009B00',
            borderWidth: 0.5
          },

          {
            name: 'sD3Line',
            type: 'line',
            value: qcRef.mean,
            borderColor: '#009B00',
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd2Up,
            borderColor: '#009B00',
            borderWidth: 0.5
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd2Low,
            borderColor: '#009B00',
            borderWidth: 0.5
          },
          {
            name: 'sD1_Up',
            type: 'line',
            value: qcRef.sd3Up,
            borderColor: '#FCF500',
            borderWidth: 0.5
          },
          {
            name: 'sD1_Low',
            type: 'line',
            value: qcRef.sd3Low,
            borderColor: '#FCF500'
          },
          {//+4SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Up + qcRef.sd,
            borderColor: '#E7001D',
          },
          { //+5SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Up + qcRef.sd + qcRef.sd,
            borderColor: '#E7001D'
          },
          {//3SD
            name: 'sD3Line',
            type: 'line',
            value: ymax,
            borderColor: '#E7001D',
          },
          {//-4SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Low - qcRef.sd,
            borderColor: '#E7001D',
          },
          { //-5SD
            name: 'sD3Line',
            type: 'line',
            value: qcRef.sd3Low - qcRef.sd - qcRef.sd,
            borderColor: '#E7001D',
          },
          { //-6SD
            name: 'sD3Line',
            type: 'line',
            value: ymin,
            borderColor: '#E7001D',
          }
        ];
    this.addCustomerRangeLines(config);
    this.addBioradRangeLines(config);
    this.annotationConfig = this.annotationService.generateAnnotations(qcRef, config);
  }

  onCustomerRangeToggle() {
    if (this.showCustomerRanges) {
      this.removeCustomerRangeLines();
    }
    this.showCustomerRanges = !this.showCustomerRanges;
    this.isShowErrorRangeText();
  }

  onCustomerRangeMeanValueChanged(event: any) {
    let mean = event.target.value;
    if (mean !== undefined && mean !== null && mean !== "") {
      this.customerRangeMean = parseFloat(mean);
    }
    else {
      this.customerRangeMean = undefined;
    }
    this.validateCustomerRangeError();
  }

  onCustomerRangeLowValueChanged(event: any) {
    let low = event.target.value;
    if (low !== undefined && low !== null && low !== "") {
      this.customerRangeLow = parseFloat(low);
      this.validateCustomerRangeError();
    }
    else {
      this.customerRangeLow = undefined;
    }
    this.validateCustomerRangeError();
  }

  onCustomerRangeHighValueChanged(event: any) {
    let high = event.target.value;
    if (high !== undefined && high !== null && high !== "") {
      this.customerRangeHigh = parseFloat(high);
    }
    else {
      this.customerRangeHigh = undefined;
    }
    this.validateCustomerRangeError();
  }

  validateCustomerRangeError() {
    this.customerRangeError = (this.customerRangeLow ?? Number.MIN_SAFE_INTEGER) > (this.customerRangeHigh ?? Number.MAX_SAFE_INTEGER);
    this.customerMeanError = this.customerRangeMean != undefined ? (this.customerRangeLow ?? Number.MIN_SAFE_INTEGER) > this.customerRangeMean || this.customerRangeMean > (this.customerRangeHigh ?? Number.MAX_SAFE_INTEGER) : false;
  }

  onBioradRangeToggle() {
    if (this.showBioRadRanges) {
      this.removeBioradLines();
    }
    this.showBioRadRanges = !this.showBioRadRanges;
    this.isShowErrorRangeText();
  }

  onBioradMeanValueChanged(event: any) {
    let mean = event.target.value;
    if (mean !== undefined && mean !== null && mean !== "") {
      this.bioradMean = parseFloat(mean);
    }
    else {
      this.bioradMean = undefined;
    }
    this.validateBioradError();
  }

  onBioradLowValueChanged(event: any) {
    let low = event.target.value;
    if (low !== undefined && low !== null && low !== "") {
      this.bioradLow = parseFloat(low);
      this.validateCustomerRangeError();
    }
    else {
      this.bioradLow = undefined;
    }
    this.validateBioradError();
  }

  onBioradHighValueChanged(event: any) {
    let high = event.target.value;
    if (high !== undefined && high !== null && high !== "") {
      this.bioradHigh = parseFloat(high);
    }
    else {
      this.bioradHigh = undefined;
    }
    this.validateBioradError();
  }

  validateBioradError() {
    this.bioradError = (this.bioradLow ?? Number.MIN_SAFE_INTEGER) > (this.bioradHigh ?? Number.MAX_SAFE_INTEGER);
    this.bioradMeanError = this.bioradMean != undefined ? (this.bioradLow ?? Number.MIN_SAFE_INTEGER) > this.bioradMean || this.bioradMean > (this.bioradHigh ?? Number.MAX_SAFE_INTEGER) : false;

  }

  addCustomerRangeLines(config: AnnotationConfig[]) {
    if (this.customerRangeHigh && this.customerRangeLow && !this.customerRangeError) {
      let low: AnnotationConfig = {
        name: this.customerRangeLowId,
        type: 'line',
        value: this.customerRangeLow,
        borderDash: [6, 5],
        borderWidth: 1.5,
        borderColor: '#006ADD',
        drawTime: 'afterDraw',
        label: {
          display: false,
          content: 'Customer defined low range = ' + this.customerRangeLow,
          backgroundColor: 'black',
          color: 'white',
          position: 'center'
        }
      };
      let high: AnnotationConfig = {
        name: this.customerRangeHighId,
        type: 'line',
        value: this.customerRangeHigh,
        borderDash: [8, 5],
        borderColor: '#006ADD',
        borderWidth: 1.5,
        drawTime: 'afterDraw',
        label: {
          display: false,
          content: 'Customer defined high range = ' + this.customerRangeHigh,
          backgroundColor: 'black',
          color: 'white',
          position: 'center'
        }
      };
      config.push(low);
      config.push(high);
      if (this.customerRangeMean) {
        let mean: AnnotationConfig = {
          name: this.customerRangeMeanId,
          type: 'line',
          value: this.customerRangeMean,
          borderWidth: 1.5,
          borderColor: '#006ADD',
          drawTime: 'afterDraw',
          label: {
            display: false,
            content: 'Customer defined mean = ' + this.customerRangeMean,
            backgroundColor: 'black',
            color: 'white',
            position: 'center'
          }
        };
        config.push(mean);
      }
    }
  }

  removeCustomerRangeLines() {
    this.resetCustomerRangeValues();
    this.updateGraph();
  }

  addRangeLines() {
    this.updateRangeErrorText();
    this.updateGraph();
  }

  isShowErrorRangeText()
  {
    if (this.showCustomerRanges == false && this.showBioRadRanges == false) {
      this.showRangeErrorText = false;
      return;
    }
    this.updateRangeErrorText();
  }

  updateRangeErrorText() {
    let isCutomerDefinedOutOfRange: boolean = false;
    let isBioradDefinedOutOfRange: boolean = false;
    
    let low: number = this.graphData !== undefined ? this.graphData?.ymin : 0;
    let high: number = this.graphData !== undefined ? this.graphData?.ymax : 0;
    
    isCutomerDefinedOutOfRange = this.isCustomerDefinedDataOutOfRange(low, high);
    isBioradDefinedOutOfRange = this.isBioradDefinedDataOutOfRange(low, high);
    
    if (isCutomerDefinedOutOfRange == false && isBioradDefinedOutOfRange == false) {
      this.showRangeErrorText = false;
      return;
    }

    if (isCutomerDefinedOutOfRange) {
      this.rangeErrorType = 'Customer defined';
      if (isBioradDefinedOutOfRange)
        this.rangeErrorType += ' and Biorad';
    }
    else {
      if (isBioradDefinedOutOfRange)
        this.rangeErrorType = 'Biorad';
    }

    this.rangeErrorText = `Alert! The ${this.rangeErrorType} Mean and/or the Low/High of module means are outside the ±6SD limits of the local module`;
    this.showRangeErrorText = true;
  }

  isCustomerDefinedDataOutOfRange(low:number, high:number): boolean{
    if ((this.customerRangeMean != undefined && this.customerRangeMean > high)
      || ((this.customerRangeLow != undefined && this.customerRangeLow < low) || (this.customerRangeHigh != undefined && this.customerRangeHigh > high))) {
      return true;
    }
    return false;
  }

  isBioradDefinedDataOutOfRange(low:number, high:number): boolean{
    if ((this.bioradMean != undefined && this.bioradMean > high)
      || ((this.bioradLow != undefined && this.bioradLow < low) || (this.bioradHigh != undefined && this.bioradHigh > high))) {
      return true;
    }
    return false;
  }

  updateGraph() {
    if (this.graphData && this.qcRef) {
      this.setAnnotations(this.qcRef, this.graphData);
      this.ljChart.annotationConfigs = this.annotationConfig;
      this.ljChart.getGraphData();
    }
  }

  reset() {
    this.resetCustomerRangeValues();
    this.resetBioradValues();
    this.updateGraph();
    this.showRangeErrorText = false
  }

  resetCustomerRangeValues() {
    this.customerRangeError = false;
    this.customerMeanError = false;
    this.customerRangeHigh = undefined;
    this.customerRangeLow = undefined;
    this.customerRangeMean = undefined;
  }

  addBioradRangeLines(config: AnnotationConfig[]) {
    if (this.bioradHigh && this.bioradLow && !this.bioradError) {
      let low: AnnotationConfig = {
        name: this.BioradLowId,
        type: 'line',
        value: this.bioradLow,
        borderDash: [6, 5],
        borderWidth: 1.5,
        borderColor: '#CF4B00',
        drawTime: 'afterDraw',
        label: {
          display: false,
          content: 'Biorad low range = ' + this.bioradLow,
          backgroundColor: 'black',
          color: 'white',
          position: 'start'
        }
      };
      let high: AnnotationConfig = {
        name: this.BioradHighId,
        type: 'line',
        value: this.bioradHigh,
        borderDash: [8, 5],
        borderColor: '#CF4B00',
        borderWidth: 1.5,
        drawTime: 'afterDraw',
        label: {
          display: false,
          content: 'Biorad high range = ' + this.bioradHigh,
          backgroundColor: 'black',
          color: 'white',
          position: 'start'
        }
      };
      config.push(low);
      config.push(high);
      if (this.bioradMean) {
        let mean: AnnotationConfig = {
          name: this.BioradMeanId,
          type: 'line',
          value: this.bioradMean,
          borderWidth: 1.5,
          borderColor: '#CF4B00',
          drawTime: 'afterDraw',
          label: {
            display: false,
            content: 'Biorad mean = ' + this.bioradMean,
            backgroundColor: 'black',
            color: 'white',
            position: 'start'
          }
        };
        config.push(mean);
      }
    }
  }

  removeBioradLines() {
    this.resetBioradValues();
    this.updateGraph();
  }

  resetBioradValues() {
    this.bioradError = false;
    this.bioradMeanError = false;
    this.bioradHigh = undefined;
    this.bioradLow = undefined;
    this.bioradMean = undefined;
  }
}
