export enum TransferArms {
  REAGENT_ARM_1 = "ReagentArm1",
  REAGENT_ARM_2 = "ReagentArm2",
  SAMPLE_ARM = "SampleArm1",
  DILUTION_ARM = "DilutionArm",
}

type Mapper = {
  [key in TransferArms]: {
    id: string;
    routeId: string;
    displayText: string;
    fileDisplayName: string;
  }
}

export const TRANSFER_ARMS_MAPPER: Mapper = {
  [TransferArms.REAGENT_ARM_1]: {
    id: 'reagentArm1',
    routeId: 'reagent-arm-1',
    displayText: 'Reagent Arm 1',
    fileDisplayName: 'ReagentArm1',
  },
  [TransferArms.REAGENT_ARM_2]: {
    id: 'reagentArm2',
    routeId: 'reagent-arm-2',
    displayText: 'Reagent Arm 2',
    fileDisplayName: 'ReagentArm2',
  },
  [TransferArms.SAMPLE_ARM]: {
    id: 'sampleArm',
    routeId: 'sample-arm',
    displayText: 'Sample Arm',
    fileDisplayName: 'SampleArm',
  },
  [TransferArms.DILUTION_ARM]: {
    id: 'dilutionArm',
    routeId: 'dilution-arm',
    displayText: 'Dilution Arm',
    fileDisplayName: 'DilutionArm',
  },
};

export function getSelectedTransferArmFromRoute(routeId: string): TransferArms | null {
  const keys = Object.keys(TransferArms);
  for (const key of keys) {
    if (TRANSFER_ARMS_MAPPER[TransferArms[key as keyof typeof TransferArms]].routeId === routeId) {
      return TransferArms[key as keyof typeof TransferArms];
    }
  }
  return null;
}
