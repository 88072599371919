import { MsalService } from '@azure/msal-angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvProviderService } from 'src/app/services/env-provider.service';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/communication/network.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent implements OnInit {

  constructor(private msalService: MsalService, private environmentProvider: EnvProviderService, private router: Router, private loginService: LoginService) {
  }

  ngOnInit(): void {
    if (this.environmentProvider.getEnvironment()?.local) {
      this.router.navigate(["home"]);
    }
  }

  login(): void {
    if (this.environmentProvider.getEnvironment()?.local) {
      this.router.navigate(["home"]);
    }
    else {
      this.msalService.loginPopup(
        {scopes: [environment.scope]}
      ).subscribe({
        next: (result) => {
          this.loginService.name = result.account.name;
          this.loginService.email = result.account.username;
          NetworkService.accessToken = result.accessToken;
          const path: string = 'home';
          this.router.navigate([path]);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }
}
