import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageMinMaxService {

  private _isFullScreenEnabled$: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(false);
  public isFullScreenEnabled$: Observable<boolean | undefined> = this._isFullScreenEnabled$.asObservable();
  private _isViewAllQuestionsEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isViewAllQuestionsEnabled$: Observable<boolean> = this._isViewAllQuestionsEnabled$.asObservable();

  toggleFullScreenState() {
    this._isFullScreenEnabled$.next(!this._isFullScreenEnabled$.value)
  }

  toggleViewAllQuestionsState() {
    this._isViewAllQuestionsEnabled$.next(!this._isViewAllQuestionsEnabled$.value)
  }

  resetToggleFullScreenState() {
    this._isFullScreenEnabled$.next(false);
  }

  resetViewAllQuestionsState() {
    this._isViewAllQuestionsEnabled$.next(false)
  }
}
