import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NetworkService } from 'src/app/services/communication/network.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(environment.local) {
      return next.handle(req);
    }

    const authReq = req.clone({ setHeaders: {
      'Authorization': `Bearer ${NetworkService.accessToken}`,
    }});

    return next.handle(authReq);
  }

}
