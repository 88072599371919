import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-video-tutorial',
  templateUrl: './video-tutorial.component.html',
  styleUrl: './video-tutorial.component.scss'
})
export class VideoTutorialComponent {
  @Input() tutorialVisible = false;
}
