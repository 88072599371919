<div *ngIf="tutorialVisible" id="AccessBar_Version_Help-Video"
     class="AccessBar-help-modal-container">
  <div class="home-tutorial-container" visible="false">
    <mat-card class="home-tutorial-card">
      <div class="image-wrapper">
        <img mat-card-image src="../../../assets/images/video-Tutorial/videoTutorial.svg"
             alt="Tutorial" class="image-container">
      </div>
      <div class="title-container">
        <h4 class="home-tutorial-title">Training Videos</h4>
      </div>
      <div class="link-container">
        <a href="https://healthineers.degreed.com/pathway/4dp66g56p7?orgsso=siemenshealthineers"
           target="_blank" rel="noopener" class="tutorial-link">Assay Troubleshooting</a>
        <a
          href="https://healthineers.degreed.com/pathway/ev9rvonopj/pathway?orgsso=siemenshealthineers"
          target="_blank" rel="noopener" class="tutorial-link">Transfer Arms Troubleshooting</a>
      </div>
    </mat-card>
  </div>
</div>
