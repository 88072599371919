import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { setNavigateFromModule } from 'src/app/modules/troubleshooting/state/actions';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';

@Component({
  selector: 'confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.scss',
})
export class ConfirmationPopupComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() buttonText!: string[];
  @Input() visible!: boolean;
  @Output() closeModel: EventEmitter<boolean> = new EventEmitter<boolean>();

  messageBody: string = '';
  constructor(private questionnaireService: QuestionnaireService, private store : Store) {}

  ngOnInit(): void {
    this.messageBody = 'All responses provided so far will be reset. This action cannot be undone. Are you sure you want to proceed ?';
  }

  confirm() : void {
    this.questionnaireService.markAsDirty();
    this.questionnaireService.resetQuestionnaire();
    this.closeModel.emit(true);
    this.store.dispatch(setNavigateFromModule({resetQuestionsOnNavigate: false}));
  }

  cancel() : void {
    this.visible = false;
    this.closeModel.emit(false);
    this.store.dispatch(setNavigateFromModule({resetQuestionsOnNavigate: false}));
  }
}
