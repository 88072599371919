import { Injectable } from '@angular/core';
import { ModulesDataModel } from '../model/device-list.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceListDataStoreService {

  private _deviceList$: BehaviorSubject<ModulesDataModel | undefined> = new BehaviorSubject<ModulesDataModel | undefined>(undefined);
  public deviceList$: Observable<ModulesDataModel | undefined> = this._deviceList$.asObservable();

  updateData(newData: ModulesDataModel){
    if(newData){
      this._deviceList$.next(newData);
    }
  }
}
