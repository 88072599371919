
<div class=" testSelection" *ngIf="!moduleDataLoading || !initialDataLoading">
  <sh-wrapper direction="column" spacing="m">
    <sh-text id="TestSelector_Help_Txt" size="body-1">Please search on the below field to get information about the
      assay.</sh-text>
    <sh-wrapper direction="column" spacing="m">
      <sh-dropdown id="TestSelector_ModuleType_DropDown" label="Module Type" [value]="selectedModuleType()?.moduleType">
        <sh-menu-item *ngFor="let moduleType of moduleTypes"
          id="TestSelector_ModuleType_DropDown_MenuItem"
          [label]="selectedModuleType()?.moduleType"
          [active]="selectedModuleType()?.moduleType === moduleType.moduleType"
          (click)="handleModuleTypeSelection(moduleType)"
        ></sh-menu-item>
      </sh-dropdown>
      <app-location-selector id="TestSelector_LocationSelector" [selectedModuleType]="selectedModuleType()" [deviceId]="this.defaultDeviceId" (setDeviceId)="onSetDeviceId($event)" (initializationComplete)="onLocationSelectorInitializationComplete($event)"
        #locationSelector></app-location-selector>
      <sh-search id="TestSelector_AssaySearch"
        label="Search an assay"
        (clearsearch)="searchAssay($event)"
        (search)="searchAssay($event)"
        [value]="this.deviceListService.selectedTest.longName"
        whole-string fields='["longName","name"]' multiline>
        <sh-empty-state id="TestSelector_AssaySearch_EmptyState" slot='empty' icon='laboratory-results-search' label='No Assay found.'></sh-empty-state>
      </sh-search>
      <sh-wrapper direction="row" spacing="m" class="buttons">
        <sh-button id="TestSelector_ResetTestSelection_Btn" label="Reset" color="secondary" (click)="clearFields()"></sh-button>
        <sh-button id="TestSelector_SubmitTestSelection_Btn" label="Submit" (click)="submit()"
          [disabled]="!(this.deviceListService.selectedDeviceId !== '' && this.selectedTest.longName !== '' && this.selectedTest.name !== '')">
        </sh-button>
      </sh-wrapper>
    </sh-wrapper>
  </sh-wrapper>
</div>

<div *ngIf="moduleDataLoading || initialDataLoading"
  style="position: absolute; left:0; right: 0; top: 0; bottom:0; display: flex; justify-content: center; align-items: center; background-color: #F5F5F5; z-index: 10;">
  <sh-spinner id="TestSelector_TestSelectionLoading_Spinner" label="Loading" size="m"></sh-spinner>
</div>
