<div class="tabViewWrapper">
    <sh-pane id="FleetComp_Filter_Pane" slot="left" label="Filter" arrow-position="center" behaviour="collapse"
        size="expanded">
        <sh-wrapper>
            <sh-datepicker mandatory label="Date Range" months='2' [max]="this.defaultDateFilters.latestDateString" no-clear class="filter"
                id="FleetComp_Filter_DatePicker" [value]="this.dateRangeString" [min]="this.defaultDateFilters.earliestDateString" range
                (value-changed)="onDateChanged($event)" class=" filter" [disabled]="loadingData"></sh-datepicker>
            <sh-input-text id="FleetComp_Filter_Assay" label="Assay" [value]="this.defaultFleetCompReqData?.AssayCode"
                readonly class="filter"></sh-input-text>
            <sh-dropdown id="FleetComp_Filter_Units" label="Units" [value]="this.defaultFleetCompReqData?.ResultUnits"
                readonly class="filter"></sh-dropdown>
            <sh-dropdown id="FleetComp_Filter_QcLotLevel" label="QC Lot/Level"
                [value]="this.defaultGraphData?.qcLotLevel" readonly class="filter">
            </sh-dropdown>
            <sh-dropdown id="FleetComp_Filter_ReagentLot" label="Reagent Lots" [disabled]="loadingData">
                <sh-menu-item id="FleetComp_Filter_ReagentLot_Item_None" label="None"
                    [active]="this.selectedReagentLot == 'None'" (click)="onReagentLotChanged('None')"></sh-menu-item>
                <sh-menu-item id="FleetComp_Filter_ReagentLot_Item_{{i}}"
                    *ngFor="let item of this.reagentLots; let i = index" [label]="item"
                    [active]="this.selectedReagentLot == item" (click)="onReagentLotChanged(item)"></sh-menu-item>
            </sh-dropdown>
            <sh-dropdown id="FleetComp_Filter_CalibrationLot" label="Calibrator Lots" [value]="this.selectedCalLot"
                [disabled]="loadingData">
                <sh-menu-item id="FleetComp_Filter_CalibrationLot_None" label="None"
                    [active]="this.selectedCalLot == 'None'" (click)="onCalLotChanged('None')">
                </sh-menu-item>
                <sh-menu-item *ngFor="let item of this.calLots; index as index"
                    id="FleetComp_Filter_CalibrationLot_{{index}}" [label]="item" [active]="this.selectedCalLot == item"
                    (click)="onCalLotChanged(item)"></sh-menu-item>
            </sh-dropdown>
            <div class="button-container">
                <sh-button id="QCReview_Filter_SearchBTN" label='View' (click)="getFleetCompareData()"
                    [disabled]="loadingData">
                </sh-button>
                <sh-button id="QCReview_Filter_ResetBTN" color='secondary' label='Reset' (click)="resetFilter()"
                    [disabled]="loadingData"></sh-button>
            </div>
        </sh-wrapper>
    </sh-pane>
    <sh-card id="FleetComp_Graph_Card" class="parent-card">

        <header-actions id="FleetComparison_HeaderAction" title="Fleet Comparison" [actionButtons]="headerActionConfigs"
            (actionHandler)="headerActionHandler($event)"></header-actions>

        <ng-container *ngIf="!loadingData && this.filteredGraphData && this.fleetCompData">
            <div class="graph-title-text">
                <sh-text size="body-1" id="FleetComp_Graph_AssayText">{{this.filteredGraphData?.assay}},</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_AnalyzerText" color="secondary">&nbsp;&nbsp;Analyzer
                    -&nbsp;</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_AnalyzerValue">{{this.filteredGraphData?.deviceId}},
                </sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_QCLotLevelText" color="secondary">&nbsp;&nbsp;QC Lots/Level
                    -&nbsp;</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_QCLotLevelValue">{{this.filteredGraphData?.qcLotLevel}},
                </sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_ReagentLotText" color="secondary">&nbsp;&nbsp;Reagent
                    Lots
                    -&nbsp;</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_ReagentLotValue">
                    {{this.lastFleetRequest?.ReagentLotId !== '' ? this.lastFleetRequest?.ReagentLotId
                    :'None'}},</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_CalLotText" color="secondary">&nbsp;&nbsp;Calibrator Lots
                    -&nbsp;</sh-text>
                <sh-text size="body-1" id="FleetComp_Graph_CalLotValue">{{this.lastFleetRequest?.CalibrationLotId !== ''
                    ? this.lastFleetRequest?.CalibrationLotId :'None'}}
                </sh-text>
            </div>

            <div class="metadata">
                <!-- Fleet Mean  -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_MeanCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_FleetMeanValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.fleetMean}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_FleetMeanInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_FleetMeanInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_FleetMeanTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_FleetMeanTooltipText">Fleet mean is calculated as

                                the Avg of individual module means of the instruments</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_FleetSDText">Fleet
                        Mean</sh-text>
                </sh-card>
                <!-- SD of Module Means -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_SDCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_FleetSDValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.sdOfModuleMeans}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_FleetSDInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_FleetSDInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_FleetSDTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_FleetSDTooltipText">SD of module means is the
                                deviation of the individual module means from the fleet mean</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_FleetSDText">SD of Module
                        Means</sh-text>
                </sh-card>
                <!-- Fleet Median -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_MedianCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_FleetMedianValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.fleetMedian}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_FleetMedianInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_FleetMedianInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_FleetMedianTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_FleetMedianTooltipText">Fleet median is the
                                median of all the individual module median of the instruments</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_FleetMedianText">Fleet
                        Median</sh-text>
                </sh-card>
                <!-- SD Within Module -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_MedianCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_FleetMedianValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.sdWithinModuleMean}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_FleetMedianInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_FleetMedianInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_FleetMedianTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_FleetMedianTooltipText">SD within module means is

                                the average of all the SDs from individual modules</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_FleetMedianText">SD Within
                        Module</sh-text>
                </sh-card>
                <!-- No. of Instruments -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_LocalMedianCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_LocalMedianValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.instrumentCount}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_LocalMedianInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_LocalMedianInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_LocalMedianTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_LocalMedianTooltipText">Number of
                                Instruments used to calculate Fleet range</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_LocalMedianText">No. of
                        Instruments</sh-text>
                </sh-card>
                <!--  No. of QC Results-->
                <sh-card class="meta-data-cards" id="Fleet_Graph_DataPointsCountCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_DataPointsCountValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.qcResultCount}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_DataPointsCountInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_DataPointsCountInfoIcon' placement='top-left'
                            class="popover" id="FleetComp_Graph_DataPointsCountTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_DataPointsCountTooltipText">No. of QC results

                                used to calculate fleet range</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_FleetMedianText">No. of QC
                        Results</sh-text>
                </sh-card>
                <!--Fleet Unit  -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_UnitCard" style="width: 138px;">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_UnitValue">
                            {{this.fleetCompData?.data?.qcFleetDetails?.fleetDataUnit?.name}}</sh-text>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_UnitText">Fleet Unit</sh-text>
                </sh-card>
            </div>
            <div class="metadata">
                <!-- Local Module Mean -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_LocalMeanCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_LocalMeanValue">
                            {{qcRef.mean}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_LocalMeanInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_LocalMeanInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_LocalMeanTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_LocalMeanTooltipText">The average of the QC
                                results over the past 30 days for the selected analyzer</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_LocalMeanText">Local Module
                        Mean</sh-text>
                </sh-card>
                <!-- Local Module SD -->
                <sh-card class="meta-data-cards" id="Fleet_Graph_LocalSDCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_LocalSDValue">
                            {{qcRef.sd}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_LocalSDInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_LocalSDInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_LocalSDTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_LocalSDTooltipText">The standard deviation of the
                                QC results over the past 30 days for the selected analyzer</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_LocalSDText">SD of Local
                        Module</sh-text>
                </sh-card>
                <!--   Local Module Median-->
                <sh-card class="meta-data-cards" id="Fleet_Graph_LocalMedianCard">
                    <div class="flex-container">
                        <sh-text size="body-1" class="card-value" id="FleetComp_Graph_LocalMedianMedianValue">
                            {{localMedian | async}}</sh-text>
                        <sh-icon icon="information" size="s" slot="functions" button color="--ui-6"
                            id="FleetComp_Graph_LocalMedianInfoIcon"></sh-icon>
                        <sh-popover target='FleetComp_Graph_LocalMedianInfoIcon' placement='top-left' class="popover"
                            id="FleetComp_Graph_LocalMedianTooltip">
                            <sh-text size="body-1" id="FleetComp_Graph_LocalMedianTooltipText">The median value of the
                                QC results over the past 30 days for the selected analyzer</sh-text>
                        </sh-popover>
                    </div>
                    <sh-text size="body-1" class="card-text" id="FleetComp_Graph_LocalMedianText">Local Module
                        Median</sh-text>
                </sh-card>

            </div>
            <div id="FLeetComp_RangeOutOfBounds_Error" class="out-of-bounds"
                *ngIf="this.fleetRangeLinesError && this.fleetCompData.data.qcFleetDetails.requestedUnit.type === this.fleetCompData.data.qcFleetDetails.fleetDataUnit.type && this.qcRef.sd !== 0">
                <sh-icon icon="error" size="s"></sh-icon>
                <sh-text size="title-1" style="color:#E7001D;"> Alert! The fleet mean and/or the SD of module means are
                    outside the ±6SD limits of the local module</sh-text>
            </div>
            <app-l-j-chart
                *ngIf="this.fleetCompData && this.fleetCompData.data.qcFleetDetails.requestedUnit.type === this.fleetCompData.data.qcFleetDetails.fleetDataUnit.type"
                class="chart-container" [data]="filteredGraphData" [qcRef]="qcRef" [miscData]="miscData"
                id="FleetComp_Graph_LJChart" [annotationConfigs]="annotationConfig"></app-l-j-chart>
            <div class="unconventional-message"
                *ngIf="this.fleetCompData && this.fleetCompData.data.qcFleetDetails.requestedUnit.type !== this.fleetCompData.data.qcFleetDetails.fleetDataUnit.type">
                <sh-text id="FleetComp_Graph_UnconventionalMessage_TXT" size="super-header">The
                    chosen assay is configured to run in the units that deviate from the conventional or SI units
                    outlined
                    in the Instructions for Use (IFU)</sh-text>
            </div>
        </ng-container>

        <div *ngIf="loadingData" class="loader">
            <sh-spinner id="FleetComp_Graph_Loading_Spinner" label="Please wait while the application queries the fleet data" size="l"></sh-spinner>
        </div>
    </sh-card>
</div>

<fleet-pdf-generator id="FleetComp_pdf" [defaultGraphData]="defaultGraphData" [fleetCompData]="fleetCompData"
    [localMedian]="localMedian" [qcRef]="qcRef" [fleetRequestModel]="lastFleetRequest">
</fleet-pdf-generator>