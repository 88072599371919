
import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FleetCompCommunicationModel, FleetCompRequestModel } from 'src/app/model/fleet-comparison-communication.model';
import { QcLJGraphMetaData } from 'src/app/model/QcLJGraphMetaData.model';
import { DateTimeFormatterService } from 'src/app/services/date-time-formatter.service';
import { QcReference } from 'src/app/model/qc.communication.model';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'fleet-pdf-generator',
  templateUrl: './fleet-pdf.component.html',
  styleUrls: ['./fleet-pdf.component.scss']
})
export class FleetPDFComponent {

  @ViewChild('contentToConvert', { static: true }) contentToConvert!: ElementRef<HTMLDivElement>;

  @Input() defaultGraphData: QcLJGraphMetaData | undefined;
  @Input() qcRef?: QcReference;
  @Input() fleetCompData: FleetCompCommunicationModel | undefined;
  @Input() localMedian: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @Input() fleetRequestModel: FleetCompRequestModel | undefined;

  fleetData: any;
  reportDate: string;
  pdfContent: any;

  constructor(private dateTimeService: DateTimeFormatterService) {
    this.reportDate = this.dateTimeService.formatDate(new Date())
  }

  async ngOnInit() {
    await this.getFleetData();
  }

  async getFleetData() {
    try {
      // Map the qcResults array to format data and calculate qcStatus
      this.fleetData = this.defaultGraphData?.qcResults.map((result) => {
        const resultDate = new Date(result.time.split('Z')[0]);
        const dateTime = this.dateTimeService.formatDate(resultDate);      
        const qcResult = result.value;
        let qcStatus;
        // Determine qcStatus based on thresholds
        if (this.defaultGraphData)
          if (this.defaultGraphData.sD2Low <= qcResult && qcResult <= this.defaultGraphData.sD2Up) {
            qcStatus = 'Pass';
          } else if (this.defaultGraphData.sD3Low <= qcResult && qcResult <= this.defaultGraphData.sD3Up) {
            qcStatus = '2S';
          } else {
            qcStatus = '3S';
          }

        return { dateTime, qcResult, qcStatus };
      });
      // Sort fleetData by dateTime
      this.fleetData.sort((a: { dateTime: string | number | Date; }, b: { dateTime: string | number | Date; }) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());
    } catch (error) {
    }
  }


  async generatePDF() {
    if (!this.defaultGraphData) return;
    const tableBody = this.fleetData.map((item: any) => ([item.dateTime, item.qcResult, item.qcStatus]));

    const a = [
      { param: { text: 'From Date', style: 'trow' }, value: { text: this.fleetRequestModel?.FromDate, style: 'thead' } },
      { param: { text: 'To Date', style: 'trow' }, value: { text: this.fleetRequestModel?.ToDate, style: 'thead' } },
      { param: { text: 'Fleet Mean', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.fleetMean, style: 'thead' } },
      { param: { text: 'SD of Module Means', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.sdOfModuleMeans, style: 'thead' } },
      { param: { text: 'Fleet Median', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.fleetMedian, style: 'thead' } },
      { param: { text: 'SD Within Module', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.sdWithinModuleMean, style: 'thead' } },
      { param: { text: 'No. of Instruments', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.instrumentCount, style: 'thead' } },
      { param: { text: 'No. of QC Results', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.qcResultCount, style: 'thead' } },
      { param: { text: 'Fleet Unit', style: 'trow' }, value: { text: this.fleetCompData?.data?.qcFleetDetails?.fleetDataUnit?.name, style: 'thead' } },
      { param: { text: 'Local Module Mean', style: 'trow' }, value: { text: this.qcRef?.mean, style: 'thead' } },
      { param: { text: 'Local Module Median', style: 'trow' }, value: { text: this.localMedian.getValue(), style: 'thead' } },
      { param: { text: 'Local Module SD', style: 'trow' }, value: { text: this.qcRef?.sd, style: 'thead' } },

    ]

    const overviewTableBody = a.map((item: any) => ([item.param, item.value]));

    let styledTableBody = tableBody.map((row: any[], rowIndex: number) => row.map((cell, colIndex) => ({
      text: cell,
      style: 'trow',
    })));


    // Sample table data
    this.pdfContent = {
      pageMargins: [40, 75, 40, 58],
      header: [
        { text: 'Fleet Comparison Report', style: 'header' },
        { text: this.reportDate, style: 'reportDate' },
      ],

      footer: function (currentPage: { toString: () => any; }, pageCount: any) {
        return { text: `${currentPage.toString()}`, alignment: 'right', margin: [0, 35, 10, 0] };
      },

      content: [
        {
          text: [
            { text: this.defaultGraphData?.assay, bold: true },
            { text: ', Analyzer - ' },
            { text: this.defaultGraphData?.deviceId, bold: true },
            { text: ', QC Lots/Level - ' },
            { text: this.defaultGraphData?.qcLotLevel, bold: true },
            { text: ', Reagent Lots - ' },
            { text: this.fleetRequestModel?.ReagentLotId === "" ? 'None' : this.fleetRequestModel?.ReagentLotId, bold: true },
            { text: ', Calibration Lots - ' },
            { text: this.fleetRequestModel?.CalibrationLotId === "" ? 'None' : this.fleetRequestModel?.CalibrationLotId, bold: true },
          ], style: 'subDetail'

        },
        {
          table: {
            border: [true, true, true, true],            
            widths: [165, 165],
            heights: 25,
            body:
              overviewTableBody
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.011;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.011;
            },
            hLineColor: function (i: any, node: any) {
              return 'grey';
            },
            vLineColor: function (i: any, node: any) {
              return 'grey';
            },
            paddingLeft: function (i: any, node: any) { return 4; },
            paddingRight: function (i: any, node: any) { return 4; },
            paddingTop: function (i: any, node: any) { return 4; },
            paddingBottom: function (i: any, node: any) { return 4; },
          }
        },
        { text: 'Note:', style: 'note', margin: [0, 26, 0, 0], bold: true },
        {
          ul: [ //body2, 12 px
            { text: 'Fleet mean is calculated as the Avg of individual module means of the instruments across the fleet.', style: 'note' },
            { text: 'SD of module means is the deviation of the individual module means from the fleet mean.', style: 'note' },
            { text: 'Fleet median is the median of individual module median of the instruments across the fleet.', style: 'note' },
            { text: 'SD within module means is the average of the SDs from individual modules across the fleet.', style: 'note' },
          ]
        },
        { text: this.defaultGraphData.deviceId + ' - QC Result Details', style: 'title2', margin: [0, 26, 0, 0] }, //title2 12px
        { text: 'Unit :' + this.fleetRequestModel?.ResultUnits, style: 'title2', bold: false, alignment: 'right', margin: [0, 0, 70, 5] },
        {
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [190, 130, 100],
            heights: 25,
            body: [
              // ['Date and Time', 'QC Result Value', 'QC Status'],
              [ //title1, 14px
                { text: 'Date and Time', style: 'thead' },
                { text: 'QC Result Value', style: 'thead' },
                { text: 'QC Status', style: 'thead' }
              ],
              //body1, 14px
              ...styledTableBody
            ]
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.011;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.011;
            },
            hLineColor: function (i: any, node: any) {
              return 'grey';
            },
            vLineColor: function (i: any, node: any) {
              return 'grey';
            },
            paddingLeft: function (i: any, node: any) { return 4; },
            paddingRight: function (i: any, node: any) { return 4; },
            paddingTop: function (i: any, node: any) { return 4; },
            paddingBottom: function (i: any, node: any) { return 4; },
          }
        },
      ],
      styles: {
        header: {
          fontSize: 14, //header2, 16px
          bold: true,
          alignment: 'left',
          margin: [20, 20, 0, 0]
        },
        //rows key, body1 , 14px  values: title1,14px
        thead: {
          fontSize: 12, //body1 , 14px 
          bold: true,
          margin: [10, 5, 0, 0],
        },
        trow: {
          fontSize: 12, //body1
          margin: [10, 5, 0, 0],
        },
        reportDate: {
          fontSize: 12, //body2, 12px
          bold: true,
          alignment: 'right',
          margin: [0, -10, 10, 0]

        },
        subDetail: {
          fontSize: 10,
          margin: [0, 15, 0, 30]
        },
        note: {
          fontSize: 10,
          margin: [0, 5, 0, 0]
        },
        title2: {
          fontSize: 10,
          bold: true,
        }

      }

    }

    const fileName = 'Fleet_' + this.defaultGraphData?.assay + '_' + this.defaultGraphData?.qcLotLevel + '_' + this.dateTimeService.getCurrentDateTimeString() + '.pdf';

    pdfMake.createPdf(this.pdfContent).download(fileName);
  }
}