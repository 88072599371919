import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from 'src/app/model/questionnaire.model';

@Component({
  selector: 'app-radio-select',
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.scss']
})
export class RadioSelectComponent {

  @Input() controlOptions!: Option[];
  @Input() label: string = "";
  @Input() value: string = '';
  @Input() disabled: boolean = false;


  @Output() selectionChange = new EventEmitter<string>();

  activeChanged(value: any) {
    if(this.value === value) return;
    this.value = value;
    this.selectionChange.emit(value);
  }

}
