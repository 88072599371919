import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeFormatterService {

  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month (1-indexed)
    const day = date.getDate().toString().padStart(2, '0'); // Day
    const year = date.getFullYear(); // Full year

    const hour = date.getHours() % 12 || 12; // Hour in 12-hour format
    const minute = date.getMinutes().toString().padStart(2, '0'); // Minutes
    const second = date.getSeconds().toString().padStart(2, '0'); // Seconds
    const period = date.getHours() >= 12 ? 'PM' : 'AM'; // Period (AM/PM)

    const formattedDate = `${month}-${day}-${year}`;
    const formattedTime = `${hour}:${minute}:${second} ${period}`;

    return `${formattedDate} ${formattedTime}`;
  }

  getCurrentDateTimeString(): string {
    const now = new Date();
    return this.getCurrentDateTimeStringinYYYYMMDDHHMMSS(now);
  }

  getCurrentDateTimeInAllFormats(): {formattedDate: string, localDate: string} {
    let now = new Date();
    let formattedDate = this.getCurrentDateTimeStringinYYYYMMDDHHMMSS(now);
    return {
      formattedDate: formattedDate,
      localDate: now.toLocaleString()
    }
  }

  private getCurrentDateTimeStringinYYYYMMDDHHMMSS(now: Date): string {
    // Get date components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(now.getDate()).padStart(2, '0');

    // Get time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Construct the formatted date-time string
    const dateTimeString = `${year}-${month}-${day}_${hours}${minutes}${seconds}`;

    return dateTimeString;
  }

}
