import { Component,  EventEmitter,  Input, Output } from '@angular/core';
import { Option } from 'src/app/model/questionnaire.model';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {

  @Input() controlOptions!: Option[];
  @Input() mandatory:boolean=false;
  @Input() label:string="";
  @Input() range:boolean=true;
  @Input() value: string = '';

  @Output() selectionChange = new EventEmitter<string>();

  dateSelected(event: any) {
    if(this.value === event.target.value) return;
    this.value = event.target.value;
    this.selectionChange.emit(event.target.value);
  }

}
