import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { BreadCrumbItem } from '../../../troubleshooting/components/troubleshooting/troubleshooting.component';

@Component({
  selector: 'nav-breadcrumbs',
  templateUrl: './nav-breadcrumbs.component.html',
  styleUrls: ['./nav-breadcrumbs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavBreadcrumbsComponent {
  @Input() breadCrumbItems: BreadCrumbItem[] | undefined = [];
  @HostBinding('class') class = 'nav-breadcrumbs';
}
