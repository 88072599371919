import { Injectable } from '@angular/core';
import { AppConfig } from '../model/app-configuration.model';
import packageJson from 'package.json'

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  public appConfig!: AppConfig;

  startService() {
    this.appConfig = {
      clientId: crypto.randomUUID(),
      version: packageJson.version,
    };
  }
}
