import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public name:string | undefined = '';
  public email:string = '';
  public termsAndConditionsAgreed : boolean = false;
}
